import React from 'react';
import _ from 'lodash';
import HelpIcon from '@mui/icons-material/Help';
import Barcode from 'react-barcode';
import { Navigate, Button, Logs, Select, Input } from '../components';
import { __, request, formatDate, formatAmount } from '../functions';
import '../assets/styles/mtokna_order.css';

/**
 * Order komponenta.
 */
class MtoknaOrder extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        id: 0,
        manual: false,
        manualSeller: false,
        complaint: false,
        window: false,
        customer: false,
        showPickup: () => {},
        password: '',
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        showLogs: false,
        data: {},
        settings: {},
        loading: false,
        loadingState: false,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { id, onClose, setTitle, showSnackbar, changeMtoknaOrderData, manual, manualSeller, complaint } = this.props;

        // Nacitame data objednavky
        request(`/mtokna/${manual ? (manualSeller ? 'viewManualSellerOrder' : 'viewManualOrder') : 'viewOrder'}/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                // Objednavka neexistuje
                showSnackbar('error', __('Objednávka neexistuje'));
                onClose();
                return;
            }

            if (complaint) {
                // Nastavime title
                setTitle(`${__('Reklamácia č.')} ${data.data.number}`);
            } else {
                // Nastavime title
                setTitle(`${__('Objednávka č.')} ${data.data.number}`);
            }

            // Zmenime data v tabulke
            changeMtoknaOrderData(id, data.data);

            this.setState({ data: { ...data, invoice: _.has(data, 'invoice') ? data.invoice.data : {} } });
        });
    }

    /**
     * Event po zmene nastaveni.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeSetting(name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [name]: value } });
    }

    /**
     * Event po zmene klient nastavenia.
     *
     * @param {string} name
     * @param {string} value
     */
    onChangeClientSetting(name, value) {
        const { settings } = this.state;

        const client = _.has(settings, 'client') ? settings.client : {};

        this.setState({ settings: { ...settings, client: { ...client, [name]: value } } });
    }

    /**
     * Zobrazime logy.
     */
    showLogs() {
        this.setState({ showLogs: true });
    }

    /**
     * Zobrazime objednavky.
     */
    showOrder() {
        this.setState({ showLogs: false });
    }

    /**
     * Rendrujeme hodnotu.
     *
     * @param {string} value
     *
     * @return {string}
     */
    renderValue(value) {
        return !_.isEmpty(value) ? value : '-';
    }

    showPickup() {
        const { id, showPickup, password } = this.props;

        showPickup(id, password);
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { id, user, manualSeller, complaint, window, customer } = this.props;
        const { data, showLogs, settings, loadingState, stateId } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="mtokna_order-content order-content-loading"
            >{this.renderLoading()}</div>;
        }

        // Vytiahneme zoznam stavov
        const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        return (
            <div className="mtokna_order-content">
                <div className="mtokna_order-content__buttons">
                    {(user.mtokna_type !== 'mtokna_seller' && !manualSeller) || window || manualSeller || customer ? <div className="mtokna_order-content__buttons__left">
                        {!showLogs ? <Button
                            onClick={() => this.showLogs()}
                            color="shadow"
                            className=""
                        >{__('Zobraziť pohyby')}</Button> : null}
                        {showLogs ? <Button
                            onClick={() => this.showOrder()}
                            color="shadow"
                            className=""
                        >{__('Zobraziť objednávku')}</Button> : null}
                    </div> : null}
                    {user.mtokna_type === 'mtokna_seller' && !manualSeller && !complaint && !window && !customer ? <div className="mtokna_order-content__buttons__left">
                        <Button
                            onClick={() => this.showPickup()}
                            color="primary"
                            className=""
                        >{__('Tovar prevzatý zákazníkom bez výhrad')}</Button>
                        <Button
                            onClick={() => this.props.onClose()}
                            color="shadow"
                            className=""
                            style={{ marginLeft: '20px' }}
                        >{__('Zrušiť výdaj')}</Button>
                    </div> : null}
                </div>
                {!showLogs ? <div className="mtokna_order-content__panels">
                    {user.mtokna_type !== 'mtokna_seller' || manualSeller || complaint || window || customer ? <div className="mtokna_order-content__panels__panel">
                        <div className="mtokna_order-content__panels__panel__up">
                            {!manualSeller && !complaint && !window ? <div className="mtokna_order-content__panels__panel__up__state">
                                {!loadingState ? <Select
                                    label={__('Stav')}
                                    options={_.has(data, 'states') ? data.states : states}
                                    value={_.has(data, 'state') ? data.state : (!_.isEmpty(stateId) ? stateId : data.data.state.id)}
                                    allowEmpty={false}
                                /> : this.renderLoading(30)}
                            </div> : null}
                            <div className="mtokna_order-content__panels__panel__up__value">
                                <div className="mtokna_order-content__panels__panel__up__value__label">{__('Vytvorené:')}</div>
                                <div className="mtokna_order-content__panels__panel__up__value__value">
                                    {this.renderValue(formatDate(data.created, 'dd.mm.yyyy hh:ii'))}
                                </div>
                            </div>
                            {!manualSeller ? <div className="mtokna_order-content__panels__panel__up__value">
                                <div className="mtokna_order-content__panels__panel__up__value__label">{__('Platba:')}</div>
                                <div className="mtokna_order-content__panels__panel__up__value__value">
                                    {this.renderValue(data.data.payment_type_name)}
                                </div>
                            </div> : null}
                        </div>
                        <div className="mtokna_order-content__panels__panel__amount">
                            <span>{formatAmount(data.data.amount, data.data.currency)}</span>
                            <span>{__('Celková suma')}</span>
                        </div>
                    </div> : null}
                    <div className="mtokna_order-content__panels__panel">
                        {user.mtokna_type !== 'mtokna_seller' || manualSeller || complaint || window || customer ? <div className="mtokna_order-content__panels__panel__client">
                            <div className="mtokna_order-content__panels__panel__client__panel">
                                <div className="mtokna_order-content__panels__panel__client__panel__title">{__('Fakturačné údaje')}</div>
                                <Input
                                    label={__('Meno')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'name')
                                        ? settings.client.name
                                        : data.data.client.name}
                                    onChange={value => this.onChangeClientSetting('name', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Ulica')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'address')
                                        ? settings.client.address
                                        : data.data.client.address}
                                    onChange={value => this.onChangeClientSetting('address', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'city')
                                        ? settings.client.city
                                        : data.data.client.city}
                                    onChange={value => this.onChangeClientSetting('city', value)}
                                    disabled
                                />
                                <Input
                                    label={__('PSČ')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'zip')
                                        ? settings.client.zip
                                        : data.data.client.zip}
                                    onChange={value => this.onChangeClientSetting('zip', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Tel. číslo')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'phone')
                                        ? settings.client.phone
                                        : data.data.client.phone}
                                    onChange={value => this.onChangeClientSetting('phone', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Email')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'email')
                                        ? settings.client.email
                                        : data.data.client.email}
                                    onChange={value => this.onChangeClientSetting('email', value)}
                                    disabled
                                />
                            </div>
                            <div className="mtokna_order-content__panels__panel__client__panel">
                                <div className="mtokna_order-content__panels__panel__client__panel__title">{__('Dodacie údaje')}</div>
                                <Input
                                    label={__('Meno')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_name')
                                        ? settings.client.delivery_name
                                        : data.data.client.delivery_name}
                                    onChange={value => this.onChangeClientSetting('delivery_name', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Ulica')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_address')
                                        ? settings.client.delivery_address
                                        : data.data.client.delivery_address}
                                    onChange={value => this.onChangeClientSetting('delivery_address', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Mesto')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_city')
                                        ? settings.client.delivery_city
                                        : data.data.client.delivery_city}
                                    onChange={value => this.onChangeClientSetting('delivery_city', value)}
                                    disabled
                                />
                                <Input
                                    label={__('PSČ')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_zip')
                                        ? settings.client.delivery_zip
                                        : data.data.client.delivery_zip}
                                    onChange={value => this.onChangeClientSetting('delivery_zip', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Tel. číslo')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_phone')
                                        ? settings.client.delivery_phone
                                        : data.data.client.delivery_phone}
                                    onChange={value => this.onChangeClientSetting('delivery_phone', value)}
                                    disabled
                                />
                                <Input
                                    label={__('Poznámka')}
                                    value={_.has(settings, 'client') && _.has(settings.client, 'delivery_note')
                                        ? settings.client.delivery_note
                                        : data.data.client.delivery_note}
                                    onChange={value => this.onChangeClientSetting('delivery_note', value)}
                                    multiline
                                    disabled
                                />
                            </div>
                        </div> : null}
                        <div className="mtokna_order-content__panels__panel__products">
                            <div className="mtokna_order-content__panels__panel__products__title">{__('Produkty')}</div>
                            {_.map(data.data.products, (product, key) => {
                                let productData = {};

                                if (_.has(data.products, product.id)) {
                                    productData = {
                                        img: data.products[product.id].img_url,
                                        name: `${data.products[product.id].name}${!_.isEmpty(product.variant) ? ` - ${product.variant}` : ''}`,
                                    };
                                } else {
                                    productData = {
                                        img: '',
                                        name: product.name,
                                    };
                                }

                                let name = productData.name;

                                if (user.mtokna_type === 'mtokna_seller') {
                                    name = `${name} - ${product.quantity}x`;
                                }

                                return (
                                    <div className="mtokna_order-content__panels__panel__products__product" key={key}>
                                        <div className="mtokna_order-content__panels__panel__products__product__photo">
                                            {!_.isEmpty(productData.img) ? <img src={productData.img} alt={productData.name} /> : <HelpIcon />}
                                        </div>
                                        <div className="mtokna_order-content__panels__panel__products__product__info">
                                            <div className="mtokna_order-content__panels__panel__products__product__info__name">{name}</div>
                                            <div className={`mtokna_order-content__panels__panel__products__product__info__values ${complaint ? 'mtokna_order-content__panels__panel__products__product__info__values-complaint' : ''}`}>
                                                {complaint ? <div>{__('SKU')}: {this.renderValue(product.number)}</div> : null}
                                                <div>{__('Počet')}: {product.quantity}{`${!_.isEmpty(product.unit) ? ` ${product.unit}` : 'x'}`}</div>
                                                {complaint ? <div>{__('Typ')}: {this.renderValue(_.has(product.meta_data, 'complaint_type') ? product.meta_data.complaint_type : '')}</div> : null}
                                                {complaint ? <div>{__('Poznámka')}: {this.renderValue(_.has(product.meta_data, 'complaint_comment') ? product.meta_data.complaint_comment : '')}</div> : null}
                                                {!complaint ? <div>{__('Jednotková cena')}: {formatAmount(product.total_price, data.data.currency)}</div> : null}
                                                {!complaint ? <div>{__('Celková cena')}: {formatAmount(product.total_price * product.quantity, data.data.currency)}</div> : null}
                                                {!complaint ? <div>{__('EAN')}: {this.renderValue(product.ean)}</div> : null}
                                            </div>
                                            {((!_.isEmpty(product.meta_data) && !manualSeller && !complaint) || window) ? _.map(product.meta_data, (value, name) => {
                                                if (!_.isString(value)) {
                                                    return null;
                                                }

                                                if (window) {
                                                    if (_.isEmpty(value)) {
                                                        return null;
                                                    }

                                                    if (name === 'window_net_width' || name === 'window_blind_width') {
                                                        name = 'Šírka (mm)';
                                                    } else if (name === 'window_net_length' || name === 'window_blind_length') {
                                                        name = 'Výška (mm)';
                                                    } else if (name === 'window_net_height') {
                                                        name = 'Hr. falca (mm)';
                                                    } else if (name === 'window_net_color') {
                                                        name = 'Farba rámu';
                                                    } else if (name === 'window_net_type') {
                                                        name = 'Sieťovina';
                                                    } else if (name === 'window_blind_design') {
                                                        name = 'Design';
                                                    } else if (name === 'window_blind_control') {
                                                        name = 'Ovl. (Ľ,P)';
                                                    } else if (name === 'window_blind_category') {
                                                        name = 'Kateg. lamely';
                                                    } else if (name === 'window_blind_color1') {
                                                        name = 'Farba lamely';
                                                    } else if (name === 'window_blind_color2') {
                                                        name = 'Farba rebrík';
                                                    } else if (name === 'window_blind_color3') {
                                                        name = 'Farba nosník';
                                                    } else {
                                                        return null;
                                                    }
                                                }

                                                return (
                                                    <div
                                                        className="mtokna_order-content__panels__panel__products__product__info__meta"
                                                        key={name}
                                                    >
                                                        <div className="mtokna_order-content__panels__panel__products__product__info__meta__label">
                                                            {name}
                                                        </div>
                                                        <div
                                                            className="mtokna_order-content__panels__panel__products__product__info__meta__value"
                                                            dangerouslySetInnerHTML={{ __html: value.replace("\n", '<br>') }}
                                                        />
                                                    </div>
                                                );
                                            }) : null}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div> : <Logs itemType="order" itemId={id} url="/mtokna/orderLogs" />}
                {this.renderSnackbar()}
            </div>
        );
    }
}

export { MtoknaOrder };
