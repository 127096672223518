// Dashboard
export const FETCH_DASHBOARD = 'fetch_dashboard';
// User
export const SET_USER = 'set_user';
export const CLEAN_USER = 'clean_user';
export const CHANGE_USER_SETTING = 'change_user_setting';
export const FETCH_USER_NOTIFICATIONS = 'fetch_user_notifications';
// Objednavky
export const FETCH_ORDERS = 'fetch_orders';
export const CLEAN_ORDERS = 'clean_orders';
export const SYNC_ORDER = 'sync_order';
export const SYNC_ORDERS = 'sync_orders';
export const EXPORT_ORDER = 'export_order';
export const EXPORT_ORDERS = 'export_orders';
export const CHANGE_ORDER_STATE = 'change_order_state';
export const CHANGE_ORDERS_STATE = 'change_orders_state';
export const CREATE_INVOICE = 'create_invoice';
export const CREATE_INVOICES = 'create_invoices';
export const PAID_ORDER = 'paid_order';
export const PAID_ORDERS = 'paid_orders';
export const DELETE_ORDER = 'delete_order';
export const DELETE_ORDERS = 'delete_orders';
export const ADDON_ORDERS = 'addon_orders';
export const EDIT_ORDER = 'edit_order';
export const SEND_ORDERS = 'send_orders';
export const SELLER_ORDER = 'seller_order';
export const SELLER_ORDERS = 'seller_orders';
export const PICKER_ORDER = 'picker_order';
// Klienti
export const FETCH_CLIENTS = 'fetch_clients';
export const CLEAN_CLIENTS = 'clean_clients';
export const DELETE_CLIENT = 'delete_client';
export const DELETE_CLIENTS = 'delete_clients';
export const CHANGE_CLIENT_NOTE = 'change_client_note';
// Produkty
export const FETCH_PRODUCTS = 'fetch_products';
export const CLEAN_PRODUCTS = 'clean_products';
export const DELETE_PRODUCT = 'delete_product';
export const DELETE_PRODUCTS = 'delete_products';
export const CHANGE_PRODUCT_STOCK = 'change_product_stock';
export const EDIT_PRODUCT = 'edit_product';
export const CHANGE_PRODUCT_MALL = 'change_product_mall';
export const CHANGE_PRODUCT_ALLEGRO = 'change_product_allegro';
// Faktury
export const FETCH_INVOICES = 'fetch_invoices';
export const CLEAN_INVOICES = 'clean_invoices';
export const DELETE_INVOICE = 'delete_invoice';
export const DELETE_INVOICES = 'delete_invoices';
// Platby
export const FETCH_INVOICE_PAYMENTS = 'fetch_invoice_payments';
export const CLEAN_INVOICE_PAYMENTS = 'clean_invoice_payments';
export const DELETE_INVOICE_PAYMENT = 'delete_invoice_payment';
export const DELETE_INVOICE_PAYMENTS = 'delete_invoice_payments';
export const ADDON_INVOICE_PAYMENTS = 'addon_invoice_payments';
// Stitky
export const FETCH_LABELS = 'fetch_labels';
export const CLEAN_LABELS = 'clean_labels';
export const DELETE_LABEL = 'delete_label';
export const DOWNLOAD_LABEL = 'download_label';
export const CREATE_COURIER = 'create_courier';
export const DELETE_COURIER = 'delete_courier';
// Exporty
export const FETCH_EXPORTS = 'fetch_exports';
export const CLEAN_EXPORTS = 'clean_exports';
export const DELETE_EXPORT = 'delete_export';
// Emaily
export const FETCH_TEMPLATES = 'fetch_templates';
export const CLEAN_TEMPLATES = 'clean_templates';
export const DELETE_TEMPLATE = 'delete_template';
export const TEMPLATE_UPLOAD_LOGO = 'template_upload_logo';
// Email
export const FETCH_TEMPLATE = 'fetch_template';
export const CLEAN_TEMPLATE = 'clean_template';
// Export
export const FETCH_EXPORT = 'fetch_export';
export const CLEAN_EXPORT = 'clean_export';
// Bmails
export const FETCH_BMAILS = 'fetch_bmails';
export const CLEAN_BMAILS = 'clean_bmails';
export const CREATE_GATEWAY = 'create_gateway';
export const DELETE_GATEWAY = 'delete_gateway';
export const CREATE_BANK = 'create_bank';
export const DELETE_BANK = 'delete_bank';
// Addony
export const FETCH_ADDONS = 'fetch_addons';
export const CLEAN_ADDONS = 'clean_addons';
export const CREATE_ADDON = 'create_addon';
export const DELETE_ADDON = 'delete_addon';
// Eshop
export const FETCH_ESHOP = 'fetch_eshop';
export const FETCH_ESHOP_DATA = 'fetch_eshop_data';
export const CHANGE_ESHOP_AUTO_FIELD = 'change_eshop_auto_field';
export const CHANGE_ESHOP_SETTINGS = 'change_eshop_settings';
export const CREATE_ESHOP = 'create_eshop';
// Ekonomicky system
export const FETCH_ACCOUNTING = 'fetch_accounting';
export const FETCH_ACCOUNTING_DATA = 'fetch_accounting_data';
export const CHANGE_ACCOUNTING_AUTO_FIELD = 'change_accounting_auto_field';
export const CHANGE_ACCOUNTING_SETTINGS = 'change_accounting_settings';
export const CREATE_ACCOUNTING = 'create_accounting';
export const DELETE_ACCOUNTING = 'delete_accounting';
// Sklad
export const FETCH_STORAGE = 'fetch_storage';
export const FETCH_STORAGE_DATA = 'fetch_storage_data';
export const CHANGE_STORAGE_AUTO_FIELD = 'change_storage_auto_field';
export const CHANGE_STORAGE_SETTINGS = 'change_storage_settings';
export const CREATE_STORAGE = 'create_storage';
export const DELETE_STORAGE = 'delete_storage';
// Zamestnanci
export const FETCH_EMPLOYEES = 'fetch_employees';
export const DELETE_EMPLOYEE = 'delete_employee';
export const CLEAN_EMPLOYEES = 'clean_employees';
// Zamestnanec
export const FETCH_EMPLOYEE = 'fetch_employee';
export const CLEAN_EMPLOYEE = 'clean_employee';
// Notifikacie
export const FETCH_NOTIFICATIONS = 'fetch_notifications';
export const CLEAN_NOTIFICATIONS = 'clean_notifications';
export const DELETE_NOTIFICATION = 'delete_notification';
export const DELETE_NOTIFICATIONS = 'delete_notifications';
export const READ_NOTIFICATION = 'read_notification';
export const READ_NOTIFICATIONS = 'read_notifications';
// Nastavenia
export const FETCH_SETTINGS = 'fetch_settings';
export const CLEAN_SETTINGS = 'clean_settings';
export const SAVE_SETTINGS = 'save_settings';
// Marketplaces
export const FETCH_MARKETPLACES = 'fetch_marketplaces';
export const CLEAN_MARKETPLACES = 'clean_marketplaces';
export const CREATE_MARKETPLACE = 'create_marketplace';
export const DELETE_MARKETPLACE = 'delete_marketplace';
// Marketplaces products
export const FETCH_MARKETPLACE_PRODUCTS = 'fetch_marketplace_products';
export const CLEAN_MARKETPLACE_PRODUCTS = 'clean_marketplace_products';
export const SAVE_MARKETPLACE_PRODUCTS = 'save_marketplace_products';
// Admin
export const FETCH_ADMIN = 'fetch_admin';
export const CLEAN_ADMIN = 'clean_admin';
export const FETCH_ADMIN_PROCESS = 'fetch_admin_process';
export const FETCH_ADMIN_ERROR = 'fetch_admin_error';
// Menu
export const CHANGE_MOBILE_MENU = 'change_mobile_menu';
// Objednavka
export const FETCH_CREATE_ORDER = 'fetch_create_order';
export const CLEAN_ORDER = 'clean_order';
// Track trace
export const FETCH_TRACK_TRACE = 'fetch_track_trace';
export const CLEAN_TRACK_TRACE = 'clean_track_trace';
export const CHANGE_TRACK_TRACE_SETTINGS = 'change_track_trace_settings';
export const TRACK_TRACE_UPLOAD_LOGO = 'track_trace_upload_logo';
// Reklamacie
export const FETCH_COMPLAINTS = 'fetch_complaints';
export const CLEAN_COMPLAINTS = 'clean_complaints';
export const DELETE_COMPLAINT = 'delete_complaint';
export const DELETE_COMPLAINTS = 'delete_complaints';
// Reklamacia
export const FETCH_COMPLAINT = 'fetch_complaint';
export const CLEAN_COMPLAINT = 'clean_complaint';
// Mtokna sellers
export const FETCH_MTOKNA_SELLERS = 'fetch_mtokna_sellers';
export const CLEAN_MTOKNA_SELLERS = 'clean_mtokna_sellers';
export const CHANGE_MTOKNA_SELLER_EMAIL = 'change_mtokna_seller_email';
export const CHANGE_MTOKNA_SELLER_SMTP = 'change_mtokna_seller_smtp';
// Mtokna owners
export const FETCH_MTOKNA_OWNERS = 'fetch_mtokna_owners';
export const CLEAN_MTOKNA_OWNERS = 'clean_mtokna_owners';
export const CHANGE_MTOKNA_OWNER_EMAIL = 'change_mtokna_owner_email';
export const CHANGE_MTOKNA_OWNER_SETTINGS = 'change_mtokna_owner_settings';
// Mtokna points
export const FETCH_MTOKNA_POINTS = 'fetch_mtokna_points';
export const CLEAN_MTOKNA_POINTS = 'clean_mtokna_points';
export const CHANGE_MTOKNA_POINT = 'change_mtokna_point';
export const DELETE_MTOKNA_POINT = 'delete_mtokna_point';
// Mtokna orders
export const FETCH_MTOKNA_ORDERS = 'fetch_mtokna_orders';
export const CLEAN_MTOKNA_ORDERS = 'clean_mtokna_orders';
export const SYNC_MTOKNA_ORDER = 'sync_mtokna_order';
export const EXPORT_MTOKNA_ORDER = 'export_mtokna_order';
export const EXPORT_MTOKNA_ORDERS = 'export_mtokna_orders';
export const CHANGE_MTOKNA_ORDER_STATE = 'change_mtokna_order_state';
export const SELLER_MTOKNA_ORDER = 'seller_mtokna_order';
export const SELLER_MTOKNA_ORDERS = 'seller_mtokna_orders';
export const READY_MTOKNA_ORDER = 'ready_mtokna_order';
export const LOCK_MTOKNA_ORDER = 'lock_mtokna_order';
export const UNLOCK_MTOKNA_ORDER = 'unlock_mtokna_order';
export const PICKUP_MTOKNA_ORDER = 'pickup_mtokna_order';
export const EDIT_MTOKNA_ORDER = 'edit_mtokna_order';
export const SEND_MTOKNA_ORDERS = 'send_mtokna_orders';
// Mtokna manual orders
export const FETCH_MTOKNA_MANUAL_ORDERS = 'fetch_mtokna_manual_orders';
export const CLEAN_MTOKNA_MANUAL_ORDERS = 'clean_mtokna_manual_orders';
export const EXPORT_MTOKNA_MANUAL_ORDER = 'export_mtokna_manual_order';
export const CHANGE_MTOKNA_MANUAL_ORDER_STATE = 'change_mtokna_manual_order_state';
export const EDIT_MTOKNA_MANUAL_ORDER = 'edit_mtokna_manual_order';
export const DELETE_MTOKNA_MANUAL_ORDER = 'delete_mtokna_manual_order';
export const CREATE_MTOKNA_MANUAL_INVOICE = 'create_mtokna_manual_invoice';
export const ADDON_MTOKNA_MANUAL_ORDERS = 'addon_mtokna_manual_orders';
export const SEND_MTOKNA_MANUAL_ORDERS = 'send_mtokna_manual_orders';
export const EXPORT_MTOKNA_MANUAL_ORDERS = 'export_mtokna_manual_orders';
// Emaily
export const FETCH_MTOKNA_TEMPLATES = 'fetch_mtokna_templates';
export const CLEAN_MTOKNA_TEMPLATES = 'clean_mtokna_templates';
// Email
export const FETCH_MTOKNA_TEMPLATE = 'fetch_mtokna_template';
export const CLEAN_MTOKNA_TEMPLATE = 'clean_mtokna_template';
// Owner Smtp
export const FETCH_MTOKNA_OWNER_SMTP = 'fetch_mtokna_owner_smtp';
export const CLEAN_MTOKNA_OWNER_SMTP = 'clean_mtokna_owner_smtp';
// Mtokna eshop
export const FETCH_MTOKNA_ESHOP = 'fetch_mtokna_eshop';
export const CREATE_MTOKNA_ESHOP = 'create_mtokna_eshop';
// Aderyn products
export const FETCH_ADERYN_PRODUCTS = 'fetch_aderyn_products';
export const CLEAN_ADERYN_PRODUCTS = 'clean_aderyn_products';
export const CHANGE_ADERYN_PRODUCT = 'change_aderyn_product';
// Aderyn product logs
export const FETCH_ADERYN_PRODUCT_LOGS = 'fetch_aderyn_product_logs';
export const CLEAN_ADERYN_PRODUCT_LOGS = 'clean_aderyn_product_logs';
// Mtokna files
export const FETCH_MTOKNA_FILES = 'fetch_mtokna_files';
export const CLEAN_MTOKNA_FILES = 'clean_mtokna_files';
export const DELETE_MTOKNA_FILE = 'delete_mtokna_file';
// Cab products
export const FETCH_CAB_PRODUCTS = 'fetch_cab_products';
export const CLEAN_CAB_PRODUCTS = 'clean_cab_products';
export const ADD_CAB_UPGATES_PRODUCT = 'add_cab_upgates_product';
export const REMOVE_CAB_UPGATES_PRODUCT = 'remove_cab_upgates_product';
export const ADD_CAB_ALLEGRO_PRODUCT = 'add_cab_allegro_product';
export const REMOVE_CAB_ALLEGRO_PRODUCT = 'remove_cab_allegro_product';
export const ADD_CAB_MALL_PRODUCT = 'add_cab_mall_product';
export const REMOVE_CAB_MALL_PRODUCT = 'remove_cab_mall_product';
export const CHANGE_CAB_UPGATES_PRODUCT_PRICE = 'change_cab_upgates_product_price';
export const CHANGE_CAB_MARKETPLACE_PRODUCT_PRICE = 'change_cab_marketplace_product_price';
// Mtokna manual seller orders
export const FETCH_MTOKNA_MANUAL_SELLER_ORDERS = 'fetch_mtokna_manual_seller_orders';
export const CLEAN_MTOKNA_MANUAL_SELLER_ORDERS = 'clean_mtokna_manual_seller_orders';
export const EDIT_MTOKNA_MANUAL_SELLER_ORDER = 'edit_mtokna_manual_seller_order';
export const DELETE_MTOKNA_MANUAL_SELLER_ORDER = 'delete_mtokna_manual_seller_order';
export const CREATE_MTOKNA_MANUAL_SELLER_ORDER_INVOICE = 'create_mtokna_manual_seller_order_invoice';
export const EXPORT_MTOKNA_MANUAL_SELLER_ORDER = 'export_mtokna_manual_seller_order';
export const CHANGE_MTOKNA_MANUAL_SELLER_ORDER_STATE = 'change_mtokna_manual_seller_order_state';
export const READY_PICKUP_MTOKNA_MANUAL_SELLER_ORDER = 'ready_pickup_mtokna_manual_seller_order';
export const PICKUP_MTOKNA_MANUAL_SELLER_ORDER = 'pickup_mtokna_manual_seller_order';
export const ACTIVATE_MTOKNA_MANUAL_SELLER_ORDER = 'activate_mtokna_manual_seller_order';
// Mtokna banners
export const FETCH_MTOKNA_BANNERS = 'fetch_mtokna_banners';
export const CLEAN_MTOKNA_BANNERS = 'clean_mtokna_banners';
export const DELETE_MTOKNA_BANNER = 'delete_mtokna_banner';
export const CHANGE_MTOKNA_BANNER = 'change_mtokna_banner';
// Mtokna moderators
export const FETCH_MTOKNA_MODERATORS = 'fetch_mtokna_moderators';
export const CLEAN_MTOKNA_MODERATORS = 'clean_mtokna_moderators';
export const CHANGE_MTOKNA_MODERATOR_EMAIL = 'change_mtokna_moderator_email';
export const DELETE_MTOKNA_MODERATOR = 'delete_mtokna_moderator';
// Mtokna manual customer orders
export const FETCH_MTOKNA_MANUAL_CUSTOMER_ORDERS = 'fetch_mtokna_manual_customer_orders';
export const CLEAN_MTOKNA_MANUAL_CUSTOMER_ORDERS = 'clean_mtokna_manual_customer_orders';
export const EDIT_MTOKNA_MANUAL_CUSTOMER_ORDER = 'edit_mtokna_manual_customer_order';
export const DELETE_MTOKNA_MANUAL_CUSTOMER_ORDER = 'delete_mtokna_manual_customer_order';
// Mtokna complaints
export const FETCH_MTOKNA_COMPLAINTS = 'fetch_mtokna_complaints';
export const CLEAN_MTOKNA_COMPLAINTS = 'clean_mtokna_complaints';
export const EDIT_MTOKNA_COMPLAINT = 'edit_mtokna_complaint';
export const DELETE_MTOKNA_COMPLAINT = 'delete_mtokna_complaint';
export const EXPORT_MTOKNA_COMPLAINT = 'export_mtokna_complaint';
export const CHANGE_MTOKNA_COMPLAINT_STATE = 'change_mtokna_complaint_state';
export const CANCEL_MTOKNA_COMPLAINT = 'cancel_mtokna_complaint';
// Mtokna categories
export const FETCH_MTOKNA_CATEGORIES = 'fetch_mtokna_categories';
export const CLEAN_MTOKNA_CATEGORIES = 'clean_mtokna_categories';
export const CHANGE_MTOKNA_CATEGORY = 'change_mtokna_category';
export const CHANGE_MTOKNA_CATEGORY_STATUS = 'change_mtokna_category_status';
export const DELETE_MTOKNA_CATEGORY = 'delete_mtokna_category';
// Mtokna products
export const FETCH_MTOKNA_PRODUCTS = 'fetch_mtokna_products';
export const CLEAN_MTOKNA_PRODUCTS = 'clean_mtokna_products';
export const CHANGE_MTOKNA_PRODUCT_STATUS = 'change_mtokna_product_status';
export const DELETE_MTOKNA_PRODUCT = 'delete_mtokna_product';
// Mtokna schemes
export const FETCH_MTOKNA_SCHEMES = 'fetch_mtokna_schemes';
export const CLEAN_MTOKNA_SCHEMES = 'clean_mtokna_schemes';
export const CHANGE_MTOKNA_SCHEME = 'change_mtokna_scheme';
export const DELETE_MTOKNA_SCHEME = 'delete_mtokna_scheme';
// Mtokna window settings
export const FETCH_MTOKNA_WINDOW_SETTINGS = 'fetch_mtokna_window_settings';
export const CLEAN_MTOKNA_WINDOW_SETTINGS = 'clean_mtokna_window_settings';
// Mtokna window seller
export const FETCH_MTOKNA_WINDOW_SELLER = 'fetch_mtokna_window_seller';
export const CLEAN_MTOKNA_WINDOW_SELLER = 'clean_mtokna_window_seller';
// Mtokna windows
export const FETCH_MTOKNA_WINDOWS = 'fetch_mtokna_windows';
export const CLEAN_MTOKNA_WINDOWS = 'clean_mtokna_windows';
export const EDIT_MTOKNA_WINDOW = 'edit_mtokna_window';
export const DELETE_MTOKNA_WINDOW = 'delete_mtokna_window';
export const CHANGE_MTOKNA_WINDOW_STATE = 'change_mtokna_window_state';
export const READY_PICKUP_MTOKNA_WINDOW = 'ready_pickup_mtokna_window';
export const PICKUP_MTOKNA_WINDOW = 'pickup_mtokna_window';
export const ACTIVATE_MTOKNA_WINDOW = 'activate_mtokna_window';
// Mtokna documents
export const FETCH_MTOKNA_DOCUMENTS = 'fetch_mtokna_documents';
export const CLEAN_MTOKNA_DOCUMENTS = 'clean_mtokna_documents';
export const DELETE_MTOKNA_DOCUMENT = 'delete_mtokna_document';
// Mtokna videos
export const FETCH_MTOKNA_VIDEOS = 'fetch_mtokna_videos';
export const CLEAN_MTOKNA_VIDEOS = 'clean_mtokna_videos';
export const DELETE_MTOKNA_VIDEO = 'delete_mtokna_video';
// Mtokna dogs
export const FETCH_MTOKNA_DOGS = 'fetch_mtokna_dogs';
export const CLEAN_MTOKNA_DOGS = 'clean_mtokna_dogs';
export const CHANGE_MTOKNA_DOG = 'change_mtokna_dog';
export const DELETE_MTOKNA_DOG = 'delete_mtokna_dog';
// Mtokna dog products
export const FETCH_MTOKNA_DOG_PRODUCTS = 'fetch_mtokna_dog_products';
export const CLEAN_MTOKNA_DOG_PRODUCTS = 'clean_mtokna_dog_products';
export const CHANGE_MTOKNA_DOG_PRODUCT = 'change_mtokna_dog_product';
export const DELETE_MTOKNA_DOG_PRODUCT = 'delete_mtokna_dog_product';
// Rainpro orders
export const FETCH_RAINPRO_ORDERS = 'fetch_rainpro_orders';
export const CLEAN_RAINPRO_ORDERS = 'clean_rainpro_orders';
export const SYNC_RAINPRO_ORDER = 'sync_rainpro_order';
export const EDIT_RAINPRO_ORDER = 'edit_rainpro_order';
export const PICKER_RAINPRO_ORDER = 'picker_rainpro_order';
export const SEND_RAINPRO_ORDERS = 'send_rainpro_orders';
// Rainpro pickers
export const FETCH_RAINPRO_PICKERS = 'fetch_rainpro_pickers';
export const CLEAN_RAINPRO_PICKERS = 'clean_rainpro_pickers';
export const CHANGE_RAINPRO_PICKER_EMAIL = 'change_rainpro_picker_email';
export const CHANGE_RAINPRO_PICKER_SETTINGS = 'change_rainpro_picker_settings';
// Mtokna excel schemes
export const FETCH_MTOKNA_EXCEL_SCHEMES = 'fetch_mtokna_excel_schemes';
export const CLEAN_MTOKNA_EXCEL_SCHEMES = 'clean_mtokna_excel_schemes';
export const CHANGE_MTOKNA_EXCEL_SCHEME = 'change_mtokna_excel_scheme';
export const DELETE_MTOKNA_EXCEL_SCHEME = 'delete_mtokna_excel_scheme';
// Cab orders
export const FETCH_CAB_ORDERS = 'fetch_cab_orders';
export const CLEAN_CAB_ORDERS = 'clean_cab_orders';
export const SYNC_CAB_ORDER = 'sync_cab_order';
export const EDIT_CAB_ORDER = 'edit_cab_order';
export const CHANGE_CAB_ORDER_STATE = 'change_cab_order_state';
export const EXPORT_CAB_ORDER = 'export_cab_order';
export const CREATE_CAB_INVOICE = 'create_cab_invoice';
export const CREATE_CAB_INVOICES = 'create_cab_invoices';
export const PAID_CAB_ORDER = 'paid_cab_order';
export const PAID_CAB_ORDERS = 'paid_cab_orders';
export const SEND_CAB_ORDERS = 'send_cab_orders';
