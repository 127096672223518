import _ from 'lodash';
import {
    FETCH_CAB_ORDERS,
    CLEAN_CAB_ORDERS,
    SYNC_CAB_ORDER,
    EDIT_CAB_ORDER,
    CHANGE_CAB_ORDER_STATE,
    EXPORT_CAB_ORDER,
    CREATE_CAB_INVOICE,
    CREATE_CAB_INVOICES,
    PAID_CAB_ORDER,
    PAID_CAB_ORDERS,
    SEND_CAB_ORDERS,
} from './types';
import {request, __, toNumber} from '../functions';

export const fetchCabOrders = (screen, type, options = {}) => {
    const defaults = {
        page: 1,
        per_page: 0,
        filtered: {},
        callback: null,
    };

    options = { ...defaults, ...options };

    let params = { page: options.page, per_page: options.per_page };

    // Nasetujeme filter
    _.forEach(options.filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    let uri = `/cab/allOrders/${type}`;

    return dispatch => request(uri, params).then(response => {
        const { data, sync } = response.data;
        const { setUser } = screen.props;

        if (options.callback !== null) {
            // Zavolame callback
            options.callback();
        }

        // Nasetujeme user data
        setUser(sync.user);

        dispatch({ type: FETCH_CAB_ORDERS, payload: data });
    });
};

export const cleanCabOrders = () => ({ type: CLEAN_CAB_ORDERS });

export const syncCabOrder = (screen, id) => {
    return dispatch => request(`/cab/syncOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo synchronizovat
            screen.showSnackbar('error', __('Objednávku sa nepodarilo synchronizovať'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola zosynchronizovaná s eshopom'));

        dispatch({ type: SYNC_CAB_ORDER, payload: data });
    });
};

export const changeCabOrderState = (screen, id, stateId, not_change_in_eshop = false) => {
    let params = {};

    if (not_change_in_eshop) {
        params = { not_change_in_eshop: true };
    }

    return dispatch => request(`/cab/changeOrderState/${id}/${stateId}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Stav sa nepodarilo zmenit
            screen.showSnackbar('error', __('Stav objednávky sa nepodarilo zmeniť'));
            return;
        }

        screen.showSnackbar('success', __('Stav objednávky bol zmenený'));

        dispatch({ type: CHANGE_CAB_ORDER_STATE, payload: data });
    });
};

export const pdfCabOrder = (screen, id, pdfExportId) => {
    return dispatch => request(`/cab/exportOrder/${id}/${pdfExportId}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            screen.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf objednávky'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola exportovaná'));

        // Stiahneme subor
        window.location = data.link;

        dispatch({ type: EXPORT_CAB_ORDER, payload: { [id]: id } });
    });
};

export const createCabInvoice = (screen, id) => {
    id = toNumber(id);

    return dispatch => request(`/cab/createInvoice/${id}`).then(response => {
        const { status, data } = response.data;
        let hasError = false;

        if (status === 'success') {
            hasError = _.has(data, id) && _.isString(data[id]);
        }

        if (status === 'error' || hasError) {
            // Fakturu sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Faktúru sa nepodarilo vytvoriť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!hasError) {
            screen.showSnackbar('success', __('Faktúra bola vytvorená'));
        }

        dispatch({ type: CREATE_CAB_INVOICE, payload: data });
    });
};

export const createCabInvoices = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/cab/multiCreateInvoice/${ids}`, params).then(response => {
        const { status, data } = response.data;
        let hasError = false;

        if (status === 'success') {
            // Pozrieme ci nejaka faktura skoncila chybou
            _.each(data, item => {
                if (_.isString(item)) {
                    hasError = true;
                }
            });
        }

        if (status === 'error' || hasError) {
            // Objednavky sa nepodarilo vytvorit
            screen.showSnackbar('error', __('Niektoré faktúry sa nepodarilo vystaviť'));

            if (_.isEmpty(data)) {
                return;
            }
        }

        if (!hasError) {
            screen.showSnackbar('success', __('Faktúry boli vytvorené'));
        }

        dispatch({ type: CREATE_CAB_INVOICES, payload: data });
    });
};

export const paidCabOrder = (screen, id) => {
    return dispatch => request(`/cab/paidOrder/${id}`).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavku sa nepodarilo uhradit
            screen.showSnackbar('error', __('Objednávku sa nepodarilo uhradiť'));
            return;
        }

        screen.showSnackbar('success', __('Objednávka bola uhradená'));

        dispatch({ type: PAID_CAB_ORDER, payload: data });
    });
};

export const paidCabOrders = (screen, ids, filtered = {}) => {
    let params = {};

    // Nasetujeme filter
    _.forEach(filtered, (value, key) => {
        params = { ...params, ...{ [`filter_${key}`]: value } };
    });

    return dispatch => request(`/cab/multiPaid/${ids}`, params).then(response => {
        const { status, data } = response.data;

        if (status === 'error') {
            // Objednavky sa nepodarilo uhradit
            screen.showSnackbar('error', __('Objednávky sa nepodarilo uhradiť'));
            return;
        }

        screen.showSnackbar('success', __('Objednávky boli uhradené'));

        dispatch({ type: PAID_CAB_ORDERS, payload: data });
    });
};

export const changeCabOrderData = (id, data) => ({ type: EDIT_CAB_ORDER, payload: { id, data } });
export const sendCabOrders = data => ({ type: SEND_CAB_ORDERS, payload: data });
