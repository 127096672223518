import { getAssets } from './functions/getAssets';
import { __ } from './functions/__';

/**
 * Zoznam obrazkov.
 */
export const IMAGES = getAssets(require.context('./assets/img/', false, /\.(png|jpe?g|svg|gif)$/));

/**
 * Zoznam mien.
 *
 * @type {Object}
 */
export const CURRENCIES = {
    EUR: '€',
    CZK: 'Kč',
    HUF: 'Ft',
    USD: '$',
    GBP: '£',
    PLN: 'PLN',
    RON: 'RON',
};

/**
 * Typy faktury.
 *
 * @type {Object}
 */
export const INVOICE_TYPES = {
    regular: __('Ostrá faktúra'),
    proforma: __('Zálohová faktúra'),
    cancel: __('Dobropis'),
};

/**
 * Typy exportov.
 *
 * @type {{
 *     excel: string,
 *     pdf: string,
 * }}
 */
export const EXPORT_TYPES = {
    excel: __('Excel'),
    pdf: __('Pdf'),
};

/**
 * Zoznam placeholdrov.
 *
 * @type {Object}
 */
export const PLACEHOLDERS = {
    '{order.id}': __('Id objednávky'),
    '{order.number}': __('Číslo objednávky'),
    '{order.currency}': __('Mena objednávky'),
    '{order.date}': __('Dátum vytvorenia objednávky'),
    '{order.amount}': __('Celková suma objednávky'),
    '{order.discount}': __('Zľava objednávky'),
    '{order.payment_type}': __('Typ úhrady objednávky'),
    '{order.delivery_type}': __('Typ dodania objednávky'),
    '{order.client.id}': __('Id zákazníka'),
    '{order.client.name}': __('Meno zákazníka'),
    '{order.client.ico}': __('IČO zákazníka'),
    '{order.client.dic}': __('DIČ zákazníka'),
    '{order.client.ic_dph}': __('IČ DPH zákazníka'),
    '{order.client.email}': __('Email zákazníka'),
    '{order.client.phone}': __('Tel. číslo zákazníka'),
    '{order.client.address}': __('Adresa zákazníka (fakturácia)'),
    '{order.client.city}': __('Mesto zákazníka (fakturácia)'),
    '{order.client.zip}': __('PSČ zákazníka (fakturácia)'),
    '{order.client.country}': __('Krajina zákazníka (fakturácia)'),
    '{order.client.delivery_address}': __('Adresa zákazníka (dodanie)'),
    '{order.client.delivery_city}': __('Mesto zákazníka (dodanie)'),
    '{order.client.delivery_zip}': __('PSČ zákazníka (dodanie)'),
    '{order.client.delivery_country}': __('Krajina zákazníka (dodanie)'),
    '{order.client.delivery_phone}': __('Tel. číslo zákazníka (dodanie)'),
    '{order.client.delivery_note}': __('Poznámka (dodanie)'),
    '{order.product.name}': __('Názov produktu'),
    '{order.product.quantity}': __('Počet produktu'),
    '{order.product.unit}': __('Jednotka produktu'),
    '{order.product.unit_price}': __('Jednotková cena produktu'),
    '{order.product.total_price}': __('Jednotková cena produktu s DPH'),
    '{order.product.tax}': __('DPH produktu'),
    '{order.product.ean}': __('Unikátne označenie produktu'),
    '{order.product.variant}': __('Varianta produktu'),
};

/**
 * Doplnujuce placeholdre.
 *
 * @type {Object}
 */
export const ADDITIONAL_PLACEHOLDERS = {
    '{order.delivery_name}': __('Názov dodania'),
    '{order.payment_name}': __('Názov platby'),
    '{order.products_total}': __('Celková suma produktov'),
    '{order.delivery_type_amount}': __('Celková suma za dopravu'),
    '{order.payment_type_amount}': __('Celková suma za platbu'),
    '{order.public_url}': __('Track & Trace verejná stránka objednávky'),
    '{invoice.number}': __('Číslo faktúry'),
    '{package.number}': __('Číslo zásielky'),
};

/**
 * Email placeholdre.
 *
 * @type {Object}
 */
export const EMAIL_PLACEHOLDERS = {
    '{invoice.pdf_link}': __('Verejný odkaz na stiahnutie faktúry'),
    '{owner.name}': __('Názov eshopu'),
    '{owner.email}': __('Email eshopu'),
};

/**
 * Eventy sablon.
 *
 * @type {Object}
 */
export const TEMPLATES_EVENTS = {
    'create_invoice': {
        name: __('Vystavenie faktúry'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('v prílohe vám posielame faktúru č. {invoice.number} k objednávke {order.number}.')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', invoice: true },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'create_proforma': {
        name: __('Vystavenie zálohovej faktúry'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('v prílohe vám posielame zálohovú faktúru č. {invoice.number} k objednávke {order.number}.')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', invoice: true },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'create_cancel': {
        name: __('Vystavenie dobropisu'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('v prílohe vám posielame dobropis č. {invoice.number} k objednávke {order.number}.')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', invoice: true },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'paid': {
        name: __('Úhrada faktúry'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('ďakujeme za úhradu faktúry č. {invoice.number}.')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '' },
    },
    'send': {
        name: __('Odoslanie zásielky'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('objednávka č. {order.number} bola odoslaná prepravcovi.')}</p><p>${__('Číslo zásielky: {package.number}')}</p><p>${__('Objednávku môžete sledovať tu: {order.public_url}')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', invoice: false },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'delivered': {
        name: __('Doručenie zásielky'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('objednávka č. {order.number} bola doručená.')}</p><p>${__('Číslo zásielky: {package.number}')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', invoice: false },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'change_state': {
        name: __('Zmena stavu objednávky'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('objednávka č. {order.number} bola zrušená.')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', invoice: false, state: null, delivery_type: '' },
        labels: { invoice: __('Pripojiť PDF faktúry ako prílohu') },
    },
    'accept': {
        name: __('Prijatie objednávky'),
        text: `<p>${__('Dobrý deň,')}</p><p>${__('objednávka č. {order.number} bola prijatá.')}</p><p>${__('S pozdravom,')}</p><p>${__('Eshop.sk')}</p>`,
        settings: { delay: '', payment_type: '', delivery_type: '' },
    },
    'custom': {
        name: __('Vlastné'),
        text: '',
        settings: {},
        labels: {},
    },
};

/**
 * Nazvy eshopov.
 *
 * @type {Object}
 */
export const ESHOPS = {
    Webareal: __('Webareal'),
    WooCommerce: __('WooCommerce'),
    Shopify: __('Shopify'),
    CsCart: __('CS-Cart'),
    EshopRychlo: __('Eshop rychlo'),
    BiznisWeb: __('BiznisWeb'),
    Wix: __('Wix'),
    Api: __('API'),
    Ecwid: __('Ecwid'),
    Prestashop: __('Prestashop'),
    Magento: __('Magento'),
    Upgates: __('Upgates'),
    FastCentrik: __('FASTCentrik'),
    Shoprenter: __('Shoprenter'),
    Blank: __('Prázdny eshop'),
    Eli: __('Eliadmin'),
    Unas: __('Unas'),
    CreativeSites: __('CreativeSites'),
    Shoptet: __('Shoptet'),
};

/**
 * Farby fakturacnych systemov.
 *
 * @type {Object}
 */
export const ESHOP_COLORS = {
    Webareal: { background: '#55bcfb', text: 'white' },
    WooCommerce: { background: '#995c8d', text: 'white' },
    Shopify: { background: 'white', text: 'black' },
    CsCart: { background: 'white', text: 'black' },
    EshopRychlo: { background: 'white', text: 'black' },
    BiznisWeb: { background: 'white', text: 'black' },
    Wix: { background: '#1677ff', text: 'white' },
    Api: { background: '#cfa14c', text: 'white' },
    Ecwid: { background: '#0086cc', text: 'white' },
    Prestashop: { background: 'white', text: 'black' },
    Magento: { background: 'white', text: 'black' },
    Upgates: { background: 'white', text: 'black' },
    FastCentrik: { background: 'white', text: 'black' },
    Shoprenter: { background: 'white', text: 'black' },
    Blank: { background: '#cfa14c', text: 'white' },
    Eli: { background: 'white', text: 'black' },
    Unas: { background: 'white', text: 'black' },
    CreativeSites: { background: 'white', text: 'black' },
    Shoptet: { background: 'white', text: 'black' },
};

/**
 * Eshopy pozicie.
 *
 * @type {Object}
 */
export const ESHOP_POSITIONS = {
    Webareal: { left: -100, top: 200, bottom: 0, right: 0 },
    WooCommerce: { left: 'auto', top: 300, bottom: 0, right: -100 },
    Shopify: { left: 0, top: 500, bottom: 0, right: 0 },
    CsCart: { left: 'auto', top: 600, bottom: 0, right: 0 },
    EshopRychlo: { left: 100, top: 300, bottom: 0, right: 0 },
    BiznisWeb: { left: 'auto', top: 400, bottom: 0, right: 50 },
    Wix: { left: 170, top: 150, bottom: 0, right: 0 },
    Api: { left: 'auto', top: 160, bottom: 0, right: 200 },
    Ecwid: { left: 200, top: 600, bottom: 0, right: 0 },
    Prestashop: { left: 'auto', top: 600, bottom: 0, right: 170 },
    Magento: { left: -100, top: 400, bottom: 0, right: 0 },
    Upgates: { left: 'auto', top: 650, bottom: 0, right: 140 },
    FastCentrik: { left: 0, top: 0, bottom: 0, right: 0 },
    Shoprenter: { left: 0, top: 0, bottom: 0, right: 0 },
    Blank: { left: 0, top: 0, bottom: 0, right: 0 },
    Eli: { left: 0, top: 0, bottom: 0, right: 0 },
    Unas: { left: 0, top: 0, bottom: 0, right: 0 },
    CreativeSites: { left: 0, top: 0, bottom: 0, right: 0 },
    Shoptet: { left: 0, top: 0, bottom: 0, right: 0 },
};

/**
 * Zoznam kurierov.
 *
 * @type {Object}
 */
export const COURIERS = {
    Gls: __('GLS'),
    SlovenskaPosta: __('Slovenská pošta'),
    Zasielkovna: __('Zásielkovňa'),
    Kurier123: __('123Kuriér'),
    Remax: __('Remax'),
    Dpd: __('DPD'),
    Geis: __('GEIS Cargo'),
    Toptrans: __('Toptrans'),
    Fhb: __('FHB Fulfillment'),
    Ppl: __('PPL'),
    Dhl: __('DHL'),
    Sps: __('SPS'),
    ExpressOne: __('EXPRESS ONE'),
    PaletExpress: __('PaletExpress'),
    Sds: __('SDS'),
    Depo: __('DEPO'),
    DpdCz: __('DPD CZ'),
    Mpl: __('Magyar Posta'),
    Qdl: __('QDL'),
    Allegro: __('Allegro'),
    Fedex: __('Fedex'),
};

/**
 * Farby kurierov.
 *
 * @type {Object}
 */
export const COURIER_COLORS = {
    Gls: { background: 'white', text: 'black' },
    SlovenskaPosta: { background: '#fabb00', text: 'white' },
    Zasielkovna: { background: '#ba1b02', text: 'white' },
    Kurier123: { background: 'white', text: 'black' },
    Remax: { background: 'white', text: 'black' },
    Dpd: { background: 'white', text: 'black' },
    Geis: { background: '#ffe800', text: 'white' },
    Toptrans: { background: 'white', text: 'black' },
    Fhb: { background: 'white', text: 'black' },
    Ppl: { background: 'white', text: 'black' },
    Dhl: { background: '#ffcc00', text: 'white' },
    Sps: { background: '#46515c', text: 'white' },
    ExpressOne: { background: 'white', text: 'black' },
    PaletExpress: { background: 'white', text: 'black' },
    Sds: { background: 'black', text: 'white' },
    Depo: { background: 'white', text: 'black' },
    DpdCz: { background: 'white', text: 'black' },
    Mpl: { background: '#33ab49', text: 'white' },
    Qdl: { background: '#68b237', text: 'white' },
    Allegro: { background: 'white', text: 'black' },
    Fedex: { background: '#4e2c8c', text: 'white' },
};

/**
 * Pozicie kurierov.
 *
 * @type {Object}
 */
export const COURIER_POSITIONS = {
    Gls: { left: 0, top: 280, right: 0, bottom: 0 },
    SlovenskaPosta: { left: 'auto', top: 300, right: -20, bottom: 0 },
    Zasielkovna: { left: -50, top: 150, right: 0, bottom: 0 },
    Kurier123: { left: 'auto', top: 150, right: -50, bottom: 0 },
    Remax: { left: 0, top: 0, right: 0, bottom: 0 },
    Dpd: { left: 80, top: 380, right: 0, bottom: 0 },
    Geis: { left: 'auto', top: 340, right: 80, bottom: 0 },
    Toptrans: { left: -20, top: 580, right: 0, bottom: 0 },
    Fhb: { left: 'auto', top: 580, right: -100, bottom: 0 },
    Ppl: { left: 50, top: 660, right: 0, bottom: 0 },
    Dhl: { left: 'auto', top: 500, right: 20, bottom: 0 },
    Sps: { left: 120, top: 200, right: 0, bottom: 0 },
    ExpressOne: { left: 190, top: 530, right: 0, bottom: 0 },
    PaletExpress: { left: 'auto', top: 530, right: 180, bottom: 0 },
    Sds: { left: -100, top: 550, right: 0, bottom: 0 },
    Depo: { left: 'auto', top: 450, right: -100, bottom: 0 },
    DpdCz: { left: 80, top: 380, right: 0, bottom: 0 },
    Mpl: { left: 0, top: 0, right: 0, bottom: 0 },
    Qdl: { left: 'auto', top: 150, right: -50, bottom: 0 },
    Allegro: { left: 0, top: 0, right: 0, bottom: 0 },
    Fedex: { left: 0, top: 0, right: 0, bottom: 0 },
};

/**
 * Nazvy skladov.
 *
 * @type {Object}
 */
export const STORAGES = {
    Oberon: __('Oberon'),
    Ikelp: __('iKelp'),
    Mrp: __('MRP'),
    Idoklad: __('iDoklad'),
    Pohoda: __('Pohoda'),
    Onix: __('ONIX'),
    Abra: __('ABRA Flexi'),
    SuperFaktura: __('SuperFaktúra'),
    MoneyS3: __('Money S3'),
    PohodaServer: __('Pohoda mServer'),
};

/**
 * Farby skladov.
 *
 * @type {Object}
 */
export const STORAGE_COLORS = {
    Oberon: { background: '#552b83', text: 'white' },
    Ikelp: { background: 'white', text: 'black' },
    Mrp: { background: 'white', text: 'black' },
    Idoklad: { background: 'white', text: 'black' },
    Pohoda: { background: 'white', text: 'black' },
    Onix: { background: 'white', text: 'black' },
    Abra: { background: '#f6b12e', text: 'white' },
    SuperFaktura: { background: '#28577c', text: 'white' },
    MoneyS3: { background: 'white', text: 'black' },
    PohodaServer: { background: 'white', text: 'black' },
};

/**
 * Zoznam bank.
 *
 * @type {Object}
 */
export const BANKS = {
    Tatrabanka: __('Tatrabanka'),
    Slsp: __('Slovenská sporiteľňa'),
    Moneta: __('Moneta'),
    Fio: __('Fio'),
    Citfin: __('Citfin'),
    Csp: __('Česká sporiteľňa'),
    Vub: __('VÚB'),
};

/**
 * Farby bank.
 *
 * @type {Object}
 */
export const BANK_COLORS = {
    Tatrabanka: { background: 'black', text: 'white' },
    Slsp: { background: '#bce5fb', text: 'white' },
    Moneta: { background: 'white', text: 'black' },
    Fio: { background: 'white', text: 'black' },
    Citfin: { background: 'white', text: 'black' },
    Csp: { background: '#bce5fb', text: 'white' },
    Vub: { background: 'white', text: 'black' },
};

/**
 * Farby bank.
 *
 * @type {Object}
 */
export const BANK_POSITIONS = {
    Tatrabanka: { left: 50, top: 250, right: 0, bottom: 0 },
    Slsp: { left: 'auto', top: 250, right: 100, bottom: 0 },
    Moneta: { left: -80, top: 350, right: 0, bottom: 0 },
    Fio: { left: 'auto', top: 360, right: -80, bottom: 0 },
    Citfin: { left: -20, top: 450, right: 0, bottom: 0 },
    Csp: { left: 'auto', top: 440, right: -20, bottom: 0 },
    Vub: { left: -20, top: 640, right: 'auto', bottom: 0 },
};

/**
 * Zoznam platobnych bran.
 *
 * @type {Object}
 */
export const GATEWAYS = {
    Stripe: __('Stripe'),
    Besteron: __('Besteron'),
    Gopay: __('Gopay'),
    Mollie: __('Mollie'),
    Webpay: __('GP webpay'),
};

/**
 * Farby platobnych bran.
 *
 * @type {Object}
 */
export const GATEWAYS_COLORS = {
    Stripe: { background: '#635bff', text: 'white' },
    Besteron: { background: '#e21644', text: 'white' },
    Gopay: { background: 'white', text: 'black' },
    Mollie: { background: '#0096ff', text: 'white' },
    Webpay: { background: 'white', text: 'black' },
};

/**
 * Pozicie platobnych bran.
 *
 * @type {Object}
 */
export const GATEWAYS_POSITIONS = {
    Stripe: { left: 40, top: 550, right: 0, bottom: 0 },
    Besteron: { left: 'auto', top: 550, right: 40, bottom: 0 },
    Gopay: { left: 'auto', top: 600, right: 100, bottom: 0 },
    Mollie: { left: 100, top: 600, right: 0, bottom: 0 },
    Webpay: { left: 120, top: 680, right: 0, bottom: 0 },
};

/**
 * Zoznam trhovisk.
 *
 * @type {Object}
 */
export const MARKETPLACES = {
    Heureka: __('Heureka'),
    Mall: __('Mall'),
    Amazon: __('Amazon'),
    Ebay: __('Ebay'),
    Etsy: __('Etsy'),
    Alza: __('Alza'),
    Kaufland: __('Kaufland'),
    Zbozi: __('Zboží'),
    Ekokrajina: __('Ekokrajina'),
    Allegro: __('Allegro'),
};

/**
 * Farby platobnych bran.
 *
 * @type {Object}
 */
export const MARKETPLACES_COLORS = {
    Heureka: { background: 'white', text: 'black' },
    Mall: { background: '#cc0000', text: 'white' },
    Amazon: { background: 'white', text: 'black' },
    Ebay: { background: 'white', text: 'black' },
    Etsy: { background: 'white', text: 'black' },
    Alza: { background: 'white', text: 'black' },
    Kaufland: { background: 'white', text: 'black' },
    Zbozi: { background: 'white', text: 'black' },
    Ekokrajina: { background: 'white', text: 'black' },
    Allegro: { background: 'white', text: 'black' },
};

/**
 * Pozicie platobnych bran.
 *
 * @type {Object}
 */
export const MARKETPLACES_POSITIONS = {
    Heureka: { left: -30, top: 220, right: 0, bottom: 0 },
    Mall: { left: 'auto', top: 220, right: -20, bottom: 0 },
    Amazon: { left: -30, top: 395, right: 0, bottom: 0 },
    Ebay: { left: 'auto', top: 170, right: 120, bottom: 0 },
    Etsy: { left: 'auto', top: 500, right: -50, bottom: 0 },
    Alza: { left: 'auto', top: 550, right: -30, bottom: 0 },
    Kaufland: { left: -90, top: 470, right: 0, bottom: 0 },
    Zbozi: { left: 'auto', top: 280, right: 40, bottom: 0 },
    Ekokrajina: { left: 0, top: 0, right: 0, bottom: 0 },
    Allegro: { left: 'auto', top: 230, right: 45, bottom: 0 },
};

/**
 * Zdroje objednavok.
 *
 * @type {Object}
 */
export const ORDER_SOURCES = {
    heureka: { background: '#ef7000', name: __('Heureka') },
    mall: { background: '#cc0000', name: __('Mall') },
    kaufland: { background: '#e3000f', name: __('Kaufland') },
    allegro: { background: '#ff5a09', name: __('Allegro') },
    etsy: { background: '#f4580a', name: __('Etsy') },
    manual: { background: '#5c70ff', name: __('Manuálne') },
};

/**
 * Zoznam addonov.
 *
 * @type {Object}
 */
export const ADDONS = {
    Kodas: __('Kodas'),
    Ikelp: __('iKelp'),
    Spajame: __('Spajame'),
    Mrp: __('Mrp XML export'),
    Pohoda: __('Pohoda XML export platieb'),
    Money: __('Money S3'),
    Stripe: __('Stripe'),
};

/**
 * Farby addonov.
 *
 * @type {Object}
 */
export const ADDON_COLORS = {
    Kodas: { background: 'white', text: 'black' },
    Ikelp: { background: 'white', text: 'black' },
    Spajame: { background: 'white', text: 'black' },
    Mrp: { background: 'white', text: 'black' },
    Pohoda: { background: 'white', text: 'black' },
    Money: { background: 'white', text: 'black' },
    Stripe: { background: '#635bff', text: 'white' },
};

/**
 * Zoznam krajin.
 *
 * @type {Object}
 */
export const COUNTRIES = {
    sk: __('Slovensko'),
    cz: __('Česká republika'),
    hu: __('Maďarsko'),
    pl: __('Poľsko'),
    ro: __('Rumunsko'),
};

/**
 * Zoznam krajin.
 *
 * @type {Object}
 */
export const COMPLAINT_TYPES = {
    repair: __('Oprava'),
    change: __('Výmena'),
    discount: __('Zľava'),
    cancel: __('Odstúpenie od zmluvy'),
};

/**
 * Zoznam krajin.
 *
 * @type {Object}
 */
export const COMPLAINT_STATUSES = {
    waiting: __('Čaká na spracovanie'),
    processed: __('Vybavené'),
};

/**
 * Nazvy fakturacnych systemov.
 *
 * @type {Object}
 */
export const ACCOUNTINGS = {
    SuperFaktura: __('SuperFaktúra'),
    Idoklad: __('iDoklad'),
    Omega: __('Omega'),
    Kros: __('Omega'),
    Oberon: __('Oberon'),
    Pohoda: __('Pohoda'),
    MkSoft: __('MKsoft'),
    Ikros: __('iKROS'),
    Mrp: __('MRP'),
    FakturyOnline: __('Faktúry online'),
    Fakturoid: __('Fakturoid'),
    Money: __('Money S3'),
    Profit365: __('Profit365'),
    MoneyS4: __('Money S4'),
    Billdu: __('Billdu'),
    Vyfakturuj: __('Vyfakturuj'),
    Onix: __('ONIX'),
    Abra: __('ABRA Flexi'),
    EshopRychlo: __('Eshop Rychlo'),
    PohodaServer: __('Pohoda mServer'),
    Webareal: __('Webareal'),
    Inteo: __('Omega Inteo'),
};

/**
 * Farby fakturacnych systemov.
 *
 * @type {Object}
 */
export const ACCOUNTING_COLORS = {
    SuperFaktura: { background: 'white', text: 'black' },
    Idoklad: { background: 'white', text: 'black' },
    Omega: { background: 'white', text: 'black' },
    Kros: { background: 'white', text: 'black' },
    Oberon: { background: '#552b83', text: 'white' },
    Pohoda: { background: 'white', text: 'black' },
    MkSoft: { background: 'white', text: 'black' },
    Ikros: { background: 'white', text: 'black' },
    Mrp: { background: 'white', text: 'black' },
    FakturyOnline: { background: 'white', text: 'black' },
    Fakturoid: { background: 'white', text: 'black' },
    Money: { background: 'white', text: 'black' },
    Profit365: { background: 'white', text: 'black' },
    MoneyS4: { background: 'white', text: 'black' },
    Billdu: { background: '#002af2', text: 'white' },
    Vyfakturuj: { background: 'white', text: 'black' },
    Onix: { background: 'white', text: 'black' },
    Abra: { background: '#f6b12e', text: 'white' },
    EshopRychlo: { background: 'white', text: 'black' },
    PohodaServer: { background: 'white', text: 'black' },
    Webareal: { background: '#55bcfb', text: 'white' },
    Inteo: { background: 'white', text: 'black' },
};

/**
 * Pozicie fakturacnych systemov.
 *
 * @type {Object}
 */
export const ACCOUNTING_POSITIONS = {
    SuperFaktura: { left: 30, top: 180, right: 0, bottom: 0 },
    Idoklad: { left: 'auto', top: 180, right: 30, bottom: 0 },
    Omega: { left: -80, top: 260, right: 0, bottom: 0 },
    Kros: { left: 0, top: 0, right: 0, bottom: 0 },
    Oberon: { left: 'auto', top: 240, right: -80, bottom: 0 },
    Pohoda: { left: 10, top: 340, right: 0, bottom: 0 },
    MkSoft: { left: 0, top: 0, right: 0, bottom: 0 },
    Ikros: { left: 0, top: 0, right: 0, bottom: 0 },
    Mrp: { left: 'auto', top: 380, right: -20, bottom: 0 },
    FakturyOnline: { left: 40, top: 450, right: 0, bottom: 0 },
    Fakturoid: { left: 'auto', top: 450, right: 40, bottom: 0 },
    Money: { left: 120, top: 520, right: 0, bottom: 0 },
    Profit365: { left: 'auto', top: 520, right: 120, bottom: 0 },
    MoneyS4: { left: 0, top: 0, right: 0, bottom: 0 },
    Billdu: { left: -80, top: 600, right: 0, bottom: 0 },
    Vyfakturuj: { left: 'auto', top: 620, right: -50, bottom: 0 },
    Onix: { left: 180, top: 660, right: 0, bottom: 0 },
    Abra: { left: 'auto', top: 660, right: 70, bottom: 0 },
    EshopRychlo: { left: 0, top: 0, right: 0, bottom: 0 },
    PohodaServer: { left: 0, top: 0, right: 0, bottom: 0 },
    Webareal: { left: 0, top: 0, right: 0, bottom: 0 },
    Inteo: { left: 0, top: 0, right: 0, bottom: 0 },
};

/**
 * Zoznam pravomoci.
 *
 * @type {Object}
 */
export const PERMISSIONS = {
    'orders': { name: __('Objednávky'), value: true },
    'orders-view': { name: __('Objednávky - zobrazenie detailu'), value: true },
    'orders-changestate': { name: __('Objednávky - zmena stavu'), value: true },
    'orders-sync': { name: __('Objednávky - synchronizácia'), value: true },
    'orders-pdf': { name: __('Objednávky - stiahnutie pdf'), value: true },
    'orders-paid': { name: __('Objednávky - uhradenie'), value: true },
    'orders-send': { name: __('Objednávky - odoslanie'), value: true },
    'orders-delete': { name: __('Objednávky - zmazanie'), value: true },
    'clients': { name: __('Zákaznici'), value: true },
    'clients-comment': { name: __('Zákaznici - nastavenie internej poznámky'), value: true },
    'clients-delete': { name: __('Zákaznici - zmazanie'), value: true },
    'products': { name: __('Produkty'), value: true },
    'products-view': { name: __('Produkty - zobrazenie detailu'), value: true },
    'products-stock': { name: __('Produkty - zmena stavu na sklade'), value: true },
    'products-delete': { name: __('Produkty - zmazanie'), value: true },
    'invoices': { name: __('Faktúry'), value: true },
    'invoices-create': { name: __('Faktúry - vystavenie'), value: true },
    'invoices-pdf': { name: __('Faktúry - stiahnutie pdf'), value: true },
    'invoices-delete': { name: __('Faktúry - zmazanie'), value: true },
    'payments': { name: __('Platby'), value: true },
    'payments-delete': { name: __('Platby - zmazanie'), value: true },
    'labels': { name: __('Štítky'), value: true },
    'labels-pdf': { name: __('Štítky - stiahnutie pdf'), value: true },
    'labels-delete': { name: __('Štítky - zmazanie'), value: true },
    'bmails': { name: __('Bankové pohyby'), value: false },
    'exports': { name: __('Exporty'), value: true },
    'exports-edit': { name: __('Exporty - úprava'), value: true },
    'exports-delete': { name: __('Exporty - zmazanie'), value: true },
    'templates': { name: __('Emaily'), value: true },
    'templates-edit': { name: __('Emaily - úprava'), value: true },
    'templates-delete': { name: __('Emaily - zmazanie'), value: true },
    'templates-smtp': { name: __('Emaily - nastavenie smtp'), value: true },
    'addons': { name: __('Doplnky'), value: true },
    'notifications': { name: __('Notifikácie'), value: true },
    'events': { name: __('Udalosti'), value: true },
    'subscription': { name: __('Členstvo'), value: true },
    'marketplaces': { name: __('Trhoviská'), value: true },
    'track_trace': { name: __('Track & Trace'), value: true },
    'complaints': { name: __('Reklamácie'), value: true },
    'complaints-edit': { name: __('Reklamácie - úprava'), value: true },
    'complaints-delete': { name: __('Reklamácie - zmazanie'), value: true },
    'settings': { name: __('Nastavenia'), value: true },
    'settings-eshop': { name: __('Nastavenia - eshop'), value: true },
    'settings-accounting': { name: __('Nastavenia - ekonomický systém'), value: true },
    'settings-couriers': { name: __('Nastavenia - prepravcovia'), value: true },
    'settings-storage': { name: __('Nastavenia - sklad'), value: true },
    'settings-bank': { name: __('Nastavenia - banka'), value: true },
};

/**
 * Obaly.
 *
 * @type {Object}
 */
export const PACKS = {
    package: __('Balík'),
    palette_euro: __('Europaleta'),
    palette_standard: __('Štandard paleta'),
    palette_half: __('Polopaleta'),
    letter: __('List'),
};

/**
 * Cenna.
 *
 * @type {Object}
 */
export const PRICE = 23.99;

/**
 * Cenna.
 *
 * @type {Object}
 */
export const PRICE_CZ = 599;

/**
 * Cenna.
 *
 * @type {Object}
 */
export const PRICE_PL = 107;

/**
 * Cenna.
 *
 * @type {Object}
 */
export const PRICE_HU = 9220;

/**
 * Minimalna dlzka hesla.
 *
 * @type {number}
 */
export const PASSWORD_MIN_LENGTH = 8;

/**
 * Customizacia.
 *
 * @type {Object}
 */
export const CUSTOMIZATION = {
    'objednavky.ezdrav.sk': { key: 'ezdrav', domain: 'Ezdrav.sk' },
    'objednavky.royalpets.sk': { key: 'royalpets', domain: 'RoyalPets.sk' },
    'system.owicreative.sk': { key: 'owicreative', domain: 'owicreative.sk' },
    //'localhost:3000': { key: 'mtokna', domain: 'mtokna.sk' },
    'toppredajca.dobrezeleziarstvo.sk': { key: 'mtokna', domain: 'mtokna.sk' },
    'www.toppredajca.dobrezeleziarstvo.sk': { key: 'mtokna', domain: 'mtokna.sk' },
    'portal.rainpro.sk': { key: 'rainpro', domain: 'rainpro.sk' },
    'www.portal.rainpro.sk': { key: 'rainpro', domain: 'rainpro.sk' },
};

/**
 * Tutorial steps.
 *
 * @type {Object}
 */
export const TUTORIAL_STEPS = {
    1: { name: __('Aktivácia eshopu'), redirect: '/eshop' },
    2: { name: __('Nastavenia eshopu'), redirect: '/eshop' },
    3: { name: __('Aktivácia ekonomického systému'), redirect: '/accounting' },
    4: { name: __('Aktivácia prepravcov'), redirect: '/couriers' },
};

/**
 * Demo token.
 *
 * @type {string}
 */
export const DEMO_TOKEN = 'd4c7d48e4b2753ea30a4886dc5ea5bca7fc876fd';

/**
 * Cookie token name.
 *
 * @type {string}
 */
export const COOKIE_TOKEN_NAME = 'goodeshop-token';

/**
 * Cookie token name.
 *
 * @type {string}
 */
export const COOKIE_AFFILIATE_NAME = 'goodeshop-affiliate';

/**
 * Cookie read name.
 *
 * @type {string}
 */
export const COOKIE_READ_NAME = 'goodeshop-read';

/**
 * Cookie webareal name.
 *
 * @type {string}
 */
export const COOKIE_WEBAREAL_NAME = 'goodeshop-webareal';

/**
 * Cookie source name.
 *
 * @type {string}
 */
export const COOKIE_SOURCE_NAME = 'goodeshop-source';

/**
 * Cookie offer name.
 *
 * @type {string}
 */
export const COOKIE_OFFER_NAME = 'goodeshop-offer4';

/**
 * Nastavenia cookie.
 *
 * @type {Object}
 */
export const COOKIE_SETTINGS = process.env.NODE_ENV && process.env.NODE_ENV === 'production'
    ? { path: '/', domain: window.location.host.replace('www.app.', '').replace('app.', '') }
    : {};

/**
 * Market.
 *
 * @type {string}
 */
export const MARKET = window.location.host.indexOf('.cz') !== -1
    ? 'cz'
    : (window.location.host.indexOf('.pl') !== -1
        ? 'pl'
        : (window.location.host.indexOf('.hu') !== -1 ? 'hu' : 'sk'));

/**
 * Development url.
 *
 * @type {string}
 */
export const DEVELOPMENT_URL = 'https://api.goodeshop.sk';

/**
 * Production url.
 *
 * @type {string}
 */
export const PRODUCTION_URL = 'https://api.goodeshop.sk';
