import React from 'react';
import _ from 'lodash';
import { Chip, Tooltip, IconButton, TextField, Autocomplete } from '@mui/material';
import onScan from 'onscan.js';
import EditIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ControlledIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import ReplayIcon from '@mui/icons-material/Replay';
import { Navigate, Input, Select, Button, Message, Checkbox } from '../components';
import { __, request, round, toNumber, isEmptyString, isValidZip, formatDate } from '../functions';
import { PACKS, COURIERS, IMAGES, COURIER_COLORS } from '../config';
import '../assets/styles/packages.css';

/**
 * Packages komponenta.
 */
class Packages extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        ids: '',
        filtered: {},
        callback: () => {},
        mtokna: false,
        mtoknaManual: false,
        rainpro: false,
        cab: false,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        data: {},
        settings: {},
        date: new Date(),
        position: 1,
        loading: false,
        responses: {},
        errors: {},
        showClients: [],
        showProducts: [],
        controlled: [],
        products: {},
        multiCourier: 0,
    };

    /**
     * Id objednavky ktora sa scanuje.
     *
     * @type {number}
     */
    scanOrderId = 0;

    /**
     * Audio elem.
     *
     * @type {Document|null}
     */
    audio = null;

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        const { ids, filtered, mtokna, mtoknaManual, rainpro, cab } = this.props;

        let params = {};

        // Nasetujeme filter
        _.forEach(filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        let url = '/orders/packages';

        if (mtokna) {
            url = '/mtokna/packages';

            if (mtoknaManual) {
                url = '/mtokna/manualPackages';
            }
        } else if (rainpro) {
            url = '/rainpro/packages';
        } else if (cab) {
            url = '/cab/packages';
        }

        request(`${url}/${ids}`, params).then(response => {
            const { data } = response.data;

            // Vytiahneme nastavenia
            const settings = _.reduce(
                data.items,
                (result, order, id) => ({ ...result, [id]: {
                    ...order.settings,
                    delivery_pickup_point_id: order.settings.client_delivery_pickup_point_id,
                    cod: round(order.settings.cod).toString().replace('.', ','),
                    boxes: _.map(order.settings.boxes, box => ({
                        ...box,
                        weight: round(box.weight).toString().replace('.', ','),
                    })),
                } }),
                {}
            );

            onScan.attachTo(document, {
                minLength: 5,
                onScan: (ean, q) => this.onScan(ean),
            });

            window.onScan = onScan;

            // Nasetujeme audio
            this.audio = document.getElementById('audio');

            this.setState({ data, settings });
        });
    }

    /**
     * Komponenta bola odpojena.
     */
    componentWillUnmount() {
        onScan.detachFrom(document);
    }

    /**
     * Event po oskenovani EAN kodu.
     *
     * @param {string} ean
     */
    onScan(ean) {
        const { showSnackbar } = this.props;
        let { products, controlled } = this.state;

        if (this.audio !== null) {
            this.audio.currentTime = 0;
            this.audio.play();
        }

        if (_.has(products, ean)) {
            // Nasli sme produkt
            products = { ...products, [ean]: { ...products[ean], count: products[ean].count - 1 } };

            // Vytiahneme celkovy pocet neoskenovanych produktov
            const total = _.reduce(products, (result, product) => result + product.count, 0);

            if (total === 0) {
                // Nie su ziadne produkty na oskenovanie, objednavka je skontrolovana
                controlled = [ ...controlled, ...[this.scanOrderId] ];
            }

            this.setState({ products, controlled });
            return;
        }

        showSnackbar('error', __('Neplatný EAN kód'));
    }

    /**
     * Event po zmene datumu.
     *
     * @param {string} date
     */
    onChangeDate(date) {
        this.setState({ date });
    }

    /**
     * Event po zmene pozicie stitkov.
     *
     * @param {string} position
     */
    onChangePosition(position) {
        this.setState({ position });
    }

    /**
     * Event po zmene multi kuriera.
     *
     * @param {number} multiCourier
     */
    onChangeMultiCourier(multiCourier) {
        let { settings } = this.state;

        // Prepiseme
        _.forEach(settings, (setting, id) => {
            settings = { ...settings, [id]: { ...setting, courier_id: multiCourier } };
        });

        this.setState({ multiCourier, settings });
    }

    /**
     * Event po zmene nastavenia.
     *
     * @param {number} id
     * @param {string} type
     * @param {string} value
     * @param {object} additional
     */
    onChangeSetting(id, type, value, additional = {}) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [id]: { ...settings[id], [type]: value, ...additional } } });
    }

    /**
     * Event po zmene sluzby.
     *
     * @param {number} id
     * @param {string} name
     * @param {string} value
     */
    onChangeService(id, name, value) {
        const { settings } = this.state;

        this.setState({ settings: { ...settings, [id]: { ...settings[id], services: { ...settings[id].services, [name]: value } } } });
    }

    /**
     * Event po zmene baliku v nastaveniach.
     *
     * @param {number} key
     * @param {number} id
     * @param {string} type
     * @param {string} value
     */
    onChangeSettingBox(key, id, type, value) {
        const { settings } = this.state;

        const boxes = _.map(settings[id].boxes, (box, k) => {
            if (k === key) {
                // Editujeme
                box = { ...box, [type]: value };
            }

            return box;
        });

        this.onChangeSetting(id, 'boxes', boxes);
    }

    /**
     * Pridame novy balik.
     *
     * @param {number} id
     */
    addSettingBox(id) {
        const { settings } = this.state;

        const boxes = [ ...settings[id].boxes, { pack: _.keys(PACKS)[0], weight: 0, reference: '', width: '', height: '', length: '' } ];

        this.onChangeSetting(id, 'boxes', boxes);
    }

    /**
     * Zmazeme balik.
     *
     * @param {number} key
     * @param {number} id
     */
    deleteSettingBox(key, id) {
        const { settings } = this.state;

        const boxes = _.remove(settings[id].boxes, (item, k) => k !== key);

        this.onChangeSetting(id, 'boxes', boxes);
    }

    /**
     * Zobrazime client data.
     *
     * @param {number} id
     */
    showClient(id) {
        let { showClients, showProducts } = this.state;

        // Zmazeme pripadne zobrazenie produktov
        showProducts = _.remove(showProducts, item_id => item_id !== id);

        this.setState({ showProducts, showClients: [ ...showClients, id ] });
    }

    /**
     * Schovame client data.
     *
     * @param {number} id
     */
    hideClient(id) {
        let { showClients } = this.state;

        showClients = _.remove(showClients, item_id => item_id !== id);

        this.setState({ showClients });
    }

    /**
     * Zobrazime produkty.
     *
     * @param {number} id
     * @param {Array} products
     */
    showProducts(id, products) {
        let { showClients } = this.state;

        // Nasetujeme id aktivnej objednavky
        this.scanOrderId = id;

        // Zmazeme pripadne zobrazenie klienta
        showClients = _.remove(showClients, item_id => item_id !== id);

        // Nasetujeme zoznam produktov
        const productsList = _.reduce(products, (result, product) => {
            if (!_.isEmpty(product.ean)) {
                // Je zadany ean
                result = { ...result, [product.ean]: { name: product.name, count: toNumber(product.quantity) } };
            }

            return result;
        }, {});

        this.setState({ showClients, showProducts: [ id ], products: productsList });
    }

    /**
     * Zmazeme objedavku.
     *
     * @param {number} id
     */
    delete(id) {
        const { onClose } = this.props;
        const { data } = this.state;

        // Odstranime
        const items = _.omit(data.items, [id]);
        const settings = _.omit(this.state.settings, [id]);

        if (_.isEmpty(items)) {
            // Uz nemame polozky, zavrieme
            onClose();
            return;
        }

        this.setState({ data: { ...data, items }, settings });
    }

    /**
     * Odoslanie.
     */
    async send() {
        const { showSnackbar, callback, onClose, sendOrders, mtokna, mtoknaManual, rainpro, cab } = this.props;
        const { settings, date, position, responses } = this.state;

        let errors = [];

        _.each(settings, ({ client_name, client_address, client_city, client_zip, client_country, delivery_pickup_point_id, client_delivery_pickup_point_id }, orderId) => {
            let orderErrors = [];

            if (isEmptyString(client_name)) {
                // Nie je vyplneny nazov prijemcu
                orderErrors = [ ...orderErrors, ...['name'] ];
            }

            if (isEmptyString(client_address)) {
                // Nie je vyplnena adresa prijemcu
                orderErrors = [ ...orderErrors, ...['address'] ];
            }

            if (isEmptyString(client_city)) {
                // Nie je vyplnene mesto prijemcu
                orderErrors = [ ...orderErrors, ...['city'] ];
            }

            if (isEmptyString(client_country)) {
                // Nie je vyplnena krajina prijemcu
                orderErrors = [ ...orderErrors, ...['country'] ];
            }

            if (!isValidZip(client_zip)) {
                // Nie je vyplnene PSC prijemcu
                orderErrors = [ ...orderErrors, ...['zip'] ];
            }

            if (delivery_pickup_point_id !== 0 && client_delivery_pickup_point_id === 0) {
                // Nie je vyplnene odberne miesto
                orderErrors = [ ...orderErrors, ...['pickup_point'] ];
            }

            if (!_.isEmpty(orderErrors)) {
                // Objednavka ma errory
                errors = { ...errors, [orderId]: orderErrors };
            }
        });

        this.setState({ loading: true });

        if (!_.isEmpty(errors)) {
            // Mame errory
            showSnackbar('error', __('Niektoré zásielky obsahujú neplatné údaje.'));
            this.setState({
                loading: false,
                errors,
                showProducts: [],
                showClients: _.keys(errors),
            });
            return;
        }

        this.setState({ errors: {}, showClients: [] });

        // Vytiahneme idcka
        const ids = _.join(_.keys(settings), ',');

        let url = '/orders/multiSend';

        if (mtokna) {
            url = '/mtokna/send';

            if (mtoknaManual) {
                url = '/mtokna/manualSend';
            }
        } else if (rainpro) {
            url = '/rainpro/send';
        } else if (cab) {
            url = '/cab/multiSend';
        }

        // Odosleme
        request(`${url}/${ids}/${formatDate(date, 'yyyy-mm-dd')}/${position}`, { settings }, 'POST').then(response => {
            const { status, data } = response.data;

            if (_.has(data, 'packages')) {
                // Na rozpise zasielok nasetujeme vysledky
                this.setState({
                    settings: _.reduce(_.keys(settings), (result, id) => ({ ...result, [id]: { ...settings[id], resend: 0 } }), {}),
                    responses: { ...responses, ...data.packages },
                    loading: false,
                });
            } else {
                this.setState({ loading: false });
            }

            if (status === 'success' && !_.isEmpty(data.packages)) {
                let errors = 0;

                _.each(data.packages, item => {
                    if (_.has(item, 'error') && item.error !== '') {
                        // Error
                        errors++;
                    }
                });

                // Oznacime ako odoslane
                sendOrders(data);

                if (errors > 0) {
                    // Mame nejake errory
                    let errorMessage = __('{COUNT} objednávok nebolo odoslaných, prosím skontrolujte zoznam. Ostatné boli odoslané.');

                    if (errors === 1) {
                        errorMessage = __('{COUNT} objednávka nebola odoslaná, prosím skontrolujte zoznam. Ostatné boli odoslané.');
                    } else if (errors < 5) {
                        errorMessage = __('{COUNT} objednávky neboli odoslané, prosím skontrolujte zoznam. Ostatné boli odoslané.');
                    }

                    showSnackbar('error', errorMessage.replace('{COUNT}', errors.toString()));

                    if (!_.isEmpty(data.label_url)) {
                        // Stiahneme subor
                        window.location = data.label_url;
                    }
                    return;
                }

                // Zavolame callbacky
                showSnackbar('success', __('Objednávky boli odoslané'));
                callback();
                onClose();

                if (!_.isEmpty(data.label_url)) {
                    // Stiahneme subor
                    window.location = data.label_url;
                }
                return;
            }

            showSnackbar('error', __('Objednávky sa nepodarilo odoslať'));
        });
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user, rainpro, cab } = this.props;
        const { data, date, settings, loading, responses, showClients, showProducts, errors, controlled, products, position, multiCourier } = this.state;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return <div
                className="packages-content packages-content-loading"
            >{this.renderLoading()}<audio id="audio"><source src="/scan.mp3" /></audio></div>;
        }

        // Vytiahneme zoznam kurierov
        let couriers = {};
        let courierNames = {};

        if (!cab) {
            couriers = _.reduce(
                data.user_couriers,
                (result, { id, courier_name, settings }) => ({ ...result, [toNumber(id)]: !_.isEmpty(settings.name)
                        ? settings.name
                        : COURIERS[courier_name] }),
                {}
            );

            // Vytiahneme zoznam kurier nazvov
            courierNames = _.reduce(
                data.user_couriers,
                (result, { id, courier_name }) => ({ ...result, [toNumber(id)]: courier_name }),
                {}
            );
        }

        // Zobrazujeme zmenu pozicie stitkov?
        const showChangePosition = _.includes(_.values(courierNames), 'Kurier123') || _.includes(_.values(courierNames), 'Gls') || _.includes(_.values(courierNames), 'Qdl');

        return (
            <div className="packages-content">
                <div className="packages-content__header">
                    <div className="packages-content__header__left">
                        <Input
                            type="date"
                            label={__('Zvoz')}
                            value={date}
                            onChange={date => this.onChangeDate(date)}
                        />
                        {showChangePosition ? <Select
                            label={__('Pozícia štítkov')}
                            value={position}
                            options={{ 1: '1', 2: '2', 3: '3', 4: '4' }}
                            onChange={value => this.onChangePosition(value)}
                            allowEmpty={false}
                        /> : null}
                        {_.includes([96, 98], user.user_eshop_id) ? <Select
                            label={__('Prepravca')}
                            value={multiCourier}
                            options={couriers}
                            onChange={value => this.onChangeMultiCourier(value)}
                        /> : null}
                    </div>
                </div>
                <div className="packages-content__orders">
                    {_.map(data.items, (item, id) => {
                        if (cab) {
                            couriers = _.reduce(
                                data.user_couriers[item.user_eshop_id],
                                (result, { id, courier_name, settings }) => ({ ...result, [toNumber(id)]: !_.isEmpty(settings.name)
                                        ? settings.name
                                        : COURIERS[courier_name] }),
                                {}
                            );

                            // Vytiahneme zoznam kurier nazvov
                            courierNames = _.reduce(
                                data.user_couriers[item.user_eshop_id],
                                (result, { id, courier_name }) => ({ ...result, [toNumber(id)]: courier_name }),
                                {}
                            );
                        }

                        const response = _.has(responses, id) ? responses[id] : null;
                        const courierId = toNumber(settings[id].courier_id);
                        const courierName = _.has(courierNames, courierId) ? courierNames[courierId] : '';

                        // Zobrazujeme klienta?
                        const showClient = _.includes(showClients, id);

                        // Zobrazujeme odberne miesto?
                        const showPickupPoint = showClient
                            && settings[id].delivery_pickup_point_id !== 0
                            && (
                                (
                                    courierName === 'Zasielkovna'
                                    && item.data.client.delivery_pickup_point_type === 'zasielkovna'
                                )
                                || (
                                    courierName === 'Gls'
                                    && item.data.client.delivery_pickup_point_type === 'gls'
                                )
                                || (
                                    courierName === 'Dpd'
                                    && item.data.client.delivery_pickup_point_type === 'dpd'
                                ) || (
                                    courierName === 'SlovenskaPosta'
                                    && (
                                        item.data.client.delivery_pickup_point_type === 'sp'
                                        || (
                                            _.has(settings[id], 'delivery_pickup_point_type')
                                            && settings[id].delivery_pickup_point_type === 'sp'
                                        )
                                    )
                                )
                            );

                        let allowChangeToPickupPoint = !showPickupPoint && courierName === 'Zasielkovna';

                        if (_.includes([332], user.user_eshop_id) && !showPickupPoint && courierName === 'SlovenskaPosta') {
                            allowChangeToPickupPoint = true;
                        }

                        // Ma errory?
                        const hasErrors = _.has(errors, id);

                        // Je skontrolovana?
                        const isControlled = _.includes(controlled, id);

                        // Zobrazujeme produkty?
                        const showProduct = _.includes(showProducts, id) && !isControlled;

                        // Je odoslana?
                        const isSend = (item.package.number !== '' || (response !== null && !_.isEmpty(response.number) && response.number !== 'waiting_import'))
                            && toNumber(settings[id].resend) === 0;

                        // Vytiahneme error messages
                        const errorMessages = hasErrors ? _.reduce(errors[id], (result, field) => {
                            let message = '';

                            switch (field) {
                                case 'name':
                                    message = __('Nie je vyplnený názov príjemcu');
                                    break;

                                case 'address':
                                    message = __('Nie je vyplnená adresa príjemcu');
                                    break;

                                case 'city':
                                    message = __('Nie je vyplnené mesto príjemcu');
                                    break;

                                case 'zip':
                                    message = __('Nie je vyplnené PSČ príjemcu');
                                    break;

                                case 'country':
                                    message = __('Nie je vyplnená krajina príjemcu');
                                    break;

                                case 'pickup_point':
                                    message = __('Nie je vyplnené odberné miesto príjemcu');
                                    break;

                                default:
                                    message = field;
                                    break;
                            }

                            return [ ...result, message ];
                        }, []) : [];

                        let pickupPoint = null;
                        let pickupPoints = data.pickup_points;
                        let firstPickupPoint = null;

                        if (showPickupPoint) {
                            // Najdeme nazov odberneho miesta
                            if (
                                !allowChangeToPickupPoint
                                && item.data.client.delivery_pickup_point_type !== 'zasielkovna'
                                && _.has(data, `pickup_points_${item.data.client.delivery_pickup_point_type}`)
                            ) {
                                pickupPoints = data[`pickup_points_${item.data.client.delivery_pickup_point_type}`];
                            }

                            if (
                                _.includes([332], user.user_eshop_id)
                                && (
                                    item.data.client.delivery_pickup_point_type === 'sp'
                                    || (
                                        _.has(settings[id], 'delivery_pickup_point_type')
                                        && settings[id].delivery_pickup_point_type === 'sp'
                                    )
                                )
                            ) {
                                pickupPoints = data.pickup_points_sp;
                            }

                            _.each(pickupPoints, point => {
                                if (point.id === settings[id].client_delivery_pickup_point_id) {
                                    pickupPoint = point;
                                }
                            });
                        }

                        _.each(pickupPoints, point => {
                            if (firstPickupPoint === null) {
                                firstPickupPoint = point.id;
                            }
                        });

                        const isAllegro = courierName === 'Allegro';

                        return (
                            <div className="packages-content__orders__order" key={id}>
                                <div className="packages-content__orders__order__header">
                                    <div className="packages-content__orders__order__header__left">
                                        <div className="packages-content__orders__order__header__left__title">
                                            {`${__('Objednávka č.')} ${item.data.number}`}
                                        </div>
                                        <div className="packages-content__orders__order__header__left__client">
                                            {`${settings[id].client_name}, ${settings[id].client_address} ${settings[id].client_city} ${settings[id].client_zip}`}
                                        </div>
                                        {isAllegro ? <div className="packages-content__orders__order__header__left__products">
                                            <div>{__('Zoznam produktov')}:</div>
                                            {_.map(item.data.products, (product, key) => {
                                                return (
                                                    <div key={key}>{product.quantity}x {product.name}</div>
                                                );
                                            })}
                                        </div> : null}
                                    </div>
                                    <div className="packages-content__orders__order__header__right">
                                        {!isSend ? <Chip
                                            onClick={!showClient ? () => this.showClient(id) : () => this.hideClient(id)}
                                            label={!showClient ? __('Upraviť príjemcu') : __('Schovať príjemcu')}
                                            clickable
                                            icon={<EditIcon />}
                                        /> : null}
                                        {!isSend && !this.isMobile() ? <Tooltip title={__('Kontrola produktov cez skener')}>
                                            <Chip
                                                onClick={!isControlled
                                                    ? () => this.showProducts(id, item.data.products)
                                                    : () => {}}
                                                label={!isControlled ? __('Skontrolovať') : __('Skontrolované')}
                                                clickable={!isControlled}
                                                color={isControlled ? 'secondary' : 'default'}
                                                icon={isControlled ? <ControlledIcon /> : null}
                                            />
                                        </Tooltip> : null}
                                        {!isSend ? <Chip
                                            onClick={() => this.delete(id)}
                                            label={__('Zmazať')}
                                            clickable
                                            icon={<DeleteIcon />}
                                        /> : null}
                                        {isSend ? <Chip
                                            onClick={() => this.onChangeSetting(id, 'resend', '1')}
                                            label={__('Znovu odoslať')}
                                            clickable
                                            color="secondary"
                                            icon={<ReplayIcon />}
                                        /> : null}
                                    </div>
                                </div>
                                {response !== null && !_.isEmpty(response.error)
                                    ? <div className="packages-content__orders__order__message error">{__('Chyba:')} {_.truncate(response.error, { length: 256 })}</div>
                                    : null}
                                {!_.isEmpty(errorMessages)
                                    ? <div className="packages-content__orders__order__message error">{__('Chyba:')} {_.join(errorMessages, ', ')}</div>
                                    : null}
                                {response !== null && !_.isEmpty(response.number)
                                    ? <div className="packages-content__orders__order__message">{response.number === 'waiting_import' ? __('Odoslané, ale čaká u prepravcu na spracovanie. Štítky nájdete v zozname štítkov po spracovaní zásielky prepravcom.') : (
                                        response.number === 'waiting_payment'
                                            ? __('Čaká na úhradu')
                                            : __('Odoslané')
                                    )}</div> : null}
                                {!isSend && !showClient && !showProduct ? <div className="packages-content__orders__order__main">
                                    <div
                                        className="packages-content__orders__order__main__photo"
                                        style={courierName !== '' ? { backgroundColor: COURIER_COLORS[courierName].background } : {}}
                                    >
                                        {courierName !== ''
                                            ? <img src={IMAGES[`${courierName.toLowerCase()}-mini.png`]} alt={courierName} />
                                            : <HelpIcon />}
                                    </div>
                                    <Select
                                        label={__('Prepravca')}
                                        options={couriers}
                                        value={courierId}
                                        onChange={value => this.onChangeSetting(id, 'courier_id', value)}
                                        placeholder={__('Bez dopravcu')}
                                    />
                                    <Input
                                        label={__('Dobierka')}
                                        value={settings[id].cod}
                                        onChange={value => this.onChangeSetting(id, 'cod', value)}
                                        disabled={rainpro}
                                    />
                                    <Input
                                        type="number"
                                        label={__('Počet balíkov')}
                                        value={settings[id].boxes.length > 1 ? settings[id].boxes.length : settings[id].count}
                                        onChange={value => this.onChangeSetting(id, 'count', value)}
                                        disabled={settings[id].boxes.length > 1}
                                    />
                                </div> : null}
                                {!isSend && !showClient && !showProduct ? <div className="packages-content__orders__order__boxes">
                                    {_.map(settings[id].boxes, (box, key) => {
                                        return (
                                            <div className={`packages-content__orders__order__boxes__box ${isAllegro ? 'box-allegro' : ''}`} key={key}>
                                                <div className="packages-content__orders__order__boxes__box__position">{key + 1}.</div>
                                                <Select
                                                    label={__('Obal')}
                                                    options={PACKS}
                                                    value={box.pack}
                                                    onChange={value => this.onChangeSettingBox(key, id, 'pack', value)}
                                                    allowEmpty={false}
                                                />
                                                {isAllegro ? <Input
                                                    label={__('Šírka')}
                                                    value={box.width}
                                                    onChange={value => this.onChangeSettingBox(key, id, 'width', value)}
                                                /> : null}
                                                {isAllegro ? <Input
                                                    label={__('Výška')}
                                                    value={box.height}
                                                    onChange={value => this.onChangeSettingBox(key, id, 'height', value)}
                                                /> : null}
                                                {isAllegro ? <Input
                                                    label={__('Dĺžka')}
                                                    value={box.length}
                                                    onChange={value => this.onChangeSettingBox(key, id, 'length', value)}
                                                /> : null}
                                                <Input
                                                    label={__('Hmotnosť')}
                                                    value={box.weight}
                                                    onChange={value => this.onChangeSettingBox(key, id, 'weight', value)}
                                                />
                                                {!isAllegro ? <Input
                                                    label={__('Referencia')}
                                                    value={box.reference}
                                                    onChange={value => this.onChangeSettingBox(key, id, 'reference', value)}
                                                /> : null}
                                                {key === 0 ? <Tooltip title={__('Pridať obal')}>
                                                    <IconButton
                                                        onClick={() => this.addSettingBox(id)}
                                                        className="packages-content__orders__order__boxes__box__button add"
                                                    >
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip> : <Tooltip title={__('Zmazať obal')}>
                                                    <IconButton
                                                        onClick={() => this.deleteSettingBox(key, id)}
                                                        className="packages-content__orders__order__boxes__box__button"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>}
                                            </div>
                                        );
                                    })}
                                </div> : null}
                                {!isSend && showClient ? <div className="packages-content__orders__order__client">
                                    <div className="packages-content__orders__order__client__panel">
                                        <Input
                                            label={__('Názov príjemcu')}
                                            value={settings[id].client_name}
                                            onChange={value => this.onChangeSetting(id, 'client_name', value)}
                                            error={hasErrors && _.includes(errors[id], 'name')}
                                        />
                                        <Input
                                            label={__('Email príjemcu')}
                                            value={settings[id].client_email}
                                            onChange={value => this.onChangeSetting(id, 'client_email', value)}
                                            error={hasErrors && _.includes(errors[id], 'email')}
                                        />
                                        <Input
                                            label={__('Tel. číslo príjemcu')}
                                            value={settings[id].client_phone}
                                            onChange={value => this.onChangeSetting(id, 'client_phone', value)}
                                            error={hasErrors && _.includes(errors[id], 'phone')}
                                        />
                                        <Input
                                            label={__('Kontaktná osoba príjemcu')}
                                            value={settings[id].client_contact_name}
                                            onChange={value => this.onChangeSetting(id, 'client_contact_name', value)}
                                        />
                                    </div>
                                    <div className="packages-content__orders__order__client__panel">
                                        {!showPickupPoint ? <Input
                                            label={__('Adresa príjemcu')}
                                            value={settings[id].client_address}
                                            onChange={value => this.onChangeSetting(id, 'client_address', value)}
                                            error={hasErrors && _.includes(errors[id], 'address')}
                                        /> : null}
                                        {!showPickupPoint ? <Input
                                            label={__('Mesto príjemcu')}
                                            value={settings[id].client_city}
                                            onChange={value => this.onChangeSetting(id, 'client_city', value)}
                                            error={hasErrors && _.includes(errors[id], 'city')}
                                        /> : null}
                                        {!showPickupPoint ? <Input
                                            label={__('PSČ príjemcu')}
                                            value={settings[id].client_zip}
                                            onChange={value => this.onChangeSetting(id, 'client_zip', value)}
                                            error={hasErrors && _.includes(errors[id], 'zip')}
                                        /> : null}
                                        {!showPickupPoint ? <Select
                                            label={__('Krajina príjemcu')}
                                            value={settings[id].client_country.toString().toLowerCase()}
                                            options={data.countries}
                                            onChange={value => this.onChangeSetting(id, 'client_country', value)}
                                            error={hasErrors && _.includes(errors[id], 'country')}
                                            allowEmpty={false}
                                        /> : null}
                                        {allowChangeToPickupPoint ? <Checkbox
                                            label={__('Zmeniť na odberné miesto')}
                                            onChange={checked => {
                                                let additional = {};

                                                if (_.includes([332], user.user_eshop_id) && courierName === 'SlovenskaPosta') {
                                                    additional = { delivery_pickup_point_type: 'sp' };
                                                }

                                                this.onChangeSetting(id, 'delivery_pickup_point_id', firstPickupPoint, additional);
                                            }}
                                        /> : null}
                                        {showPickupPoint ? <Input
                                            label={__('Odberné miesto')}
                                            content={<Autocomplete
                                                options={pickupPoints}
                                                getOptionLabel={option => option.name}
                                                onChange={(event, value) => this.onChangeSetting(id, 'client_delivery_pickup_point_id', value !== null ? value.id : 0)}
                                                renderInput={(params) => <TextField
                                                    { ...params }
                                                    placeholder={__('Začnite písať názov...')}
                                                    variant="outlined"
                                                    error={hasErrors && _.includes(errors[id], 'pickup_point')}
                                                />}
                                                noOptionsText={__('Nenašlo sa žiadne odberné miesto')}
                                                clearText={__('Zrušiť')}
                                                defaultValue={pickupPoint}
                                            />}
                                        /> : null}
                                        <Input
                                            label={__('Poznámka príjemcu')}
                                            value={settings[id].client_note}
                                            onChange={value => this.onChangeSetting(id, 'client_note', value)}
                                        />
                                        {courierName === 'SlovenskaPosta' && !showPickupPoint ? <Checkbox
                                            label={__('Podaj u kuriéra')}
                                            value={_.has(settings[id].services, 'PUZ') && settings[id].services.PUZ}
                                            onChange={checked => this.onChangeService(id, 'PUZ', checked)}
                                        /> : null}
                                        {courierName === 'SlovenskaPosta' ? <Checkbox
                                            label={__('Krehké')}
                                            value={_.has(settings[id].services, 'F') && settings[id].services.F}
                                            onChange={checked => this.onChangeService(id, 'F', checked)}
                                        /> : null}
                                    </div>
                                </div> : null}
                                {!isSend && showProduct ? <div className="packages-content__orders__order__products">
                                    <Message
                                        className="packages-content__orders__order__products__message"
                                        type="info"
                                    >{__('Môžete skenovať EAN kódy produktov.')}</Message>
                                    {_.map(products, (product, ean) => {
                                        return (
                                            <div
                                                className="packages-content__orders__order__products__product"
                                                key={ean}
                                            >
                                                <div className="packages-content__orders__order__products__product__photo">
                                                    {!_.isEmpty(product.img_url)
                                                        ? <img src={product.img_url} alt={data.name} />
                                                        : <HelpIcon />}
                                                </div>
                                                <div className="packages-content__orders__order__products__product__info">
                                                    <div className="packages-content__orders__order__products__product__info__name">{product.name}</div>
                                                    <div className="packages-content__orders__order__products__product__info__ean">{ean}</div>
                                                </div>
                                                <div className="packages-content__orders__order__products__product__count">{
                                                    product.count === 0
                                                        ? <ControlledIcon color="secondary" />
                                                        : `${product.count}${__('ks')}`
                                                }</div>
                                            </div>
                                        );
                                    })}
                                </div> : null}
                            </div>
                        );
                    })}
                </div>
                <Button
                    onClick={() => this.send()}
                    color="primary"
                    className="packages-content__button"
                    loading={loading}
                >{__('Odoslať')}</Button>
                <audio id="audio">
                    <source src="/scan.mp3" />
                </audio>
            </div>
        );
    }
}

export { Packages };
