import React from 'react';
import _ from 'lodash';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    TablePagination,
} from '@mui/material';
import { __, formatDate, request, toNumber } from '../functions';
import { Navigate } from './Navigate';
import '../assets/styles/logs.css';

/**
 * Logs komponenta.
 */
class Logs extends Navigate {
    /**
     * Default props.
     *
     * @type {Object}
     */
    static defaultProps = {
        itemType: '',
        itemId: '',
        url: '/user-logs/index',
        cab: false,
    };

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        logs: null,
    };

    /**
     * Komponenta bola pripojena.
     */
    componentDidMount() {
        this.fetchList();
    }

    /**
     * Nacitame zoznam logov.
     *
     * @param {number} page
     */
    fetchList(page = 1) {
        let { url, itemType, itemId, cab } = this.props;
        
        if (cab) {
            url = '/cab/orderLogs';
        }

        request(`${url}/${itemType}/${itemId}`, { page, per_page: 10 }).then(response => {
            const { status, data } = response.data;

            if (status === 'success') {
                // Nacitame data
                this.setState({ logs: data });
            }
        });
    }

    /**
     * Event po zmene stranky.
     *
     * @param {number} page
     */
    onChangePage(page) {
        // Vycistime zoznam
        this.setState({ logs: null });

        // Nacitame zoznam
        this.fetchList(page);
    }

    /**
     * Vratime log spravu.
     *
     * @param {string} eventType
     * @param {Object} eventData
     *
     * @return {string|null}
     */
    getText(eventType, eventData) {
        const { logs } = this.state;
        const { itemType } = this.props;

        switch (itemType) {
            case 'order':
                // Objednavka
                switch (eventType) {
                    case 'create':
                        // Objednavka vytvorena
                        return __('Objednávka bola vytvorená');

                    case 'update':
                        // Objednavka vytvorena
                        return __('Objednávka bola upravená');

                    case 'create_invoice':
                        // Faktura vytvorena
                        return __('K objednávke bola vystavená faktúra');

                    case 'change_state':
                        // Zmena stavu
                        // Vytiahneme zoznam stavov
                        const states = _.reduce(logs.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});
                        const state = _.has(states, eventData.state_id) ? states[eventData.state_id] : eventData.state_id;

                        return __('Zmenený stav objednávky na {STATE}').replace('{STATE}', state);

                    case 'sync':
                        // Synchronizacia
                        return __('Objednávka bola synchronizovaná s eshopom');

                    case 'paid':
                        // Uhradena
                        return __('Objednávka bola uhradená');

                    case 'send':
                        // Odoslane
                        return __('Objednávka bola odoslaná');

                    case 'delivered':
                        // Dorucene
                        return __('Objednávka bola doručená');

                    default:
                        return null;
                }

            case 'product':
                // Produkt
                switch (eventType) {
                    case 'stock':
                        // Zmeneny stav na sklade
                        return eventData.order_number !== ''
                            ? __('Objednávka č. {NUMBER} - {DIFF} ks na {VALUE}')
                                .replace('{NUMBER}', eventData.order_number)
                                .replace('{DIFF}', Math.abs(toNumber(eventData.diff)).toString())
                                .replace('{VALUE}', eventData.value)
                            : __('Zmena stavu na sklade o {DIFF} na {VALUE}')
                                .replace('{DIFF}', eventData.diff)
                                .replace('{VALUE}', eventData.value);

                    default:
                        return null;
                }

            default:
                return null;
        }
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { logs } = this.state;

        if (_.isEmpty(logs)) {
            // Nemame data
            return <div className="logs">{this.renderLoading()}</div>;
        }

        return (
            <div className="logs">
                {!_.isEmpty(logs.items) ? <TableContainer className="logs__table">
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{__('Čas')}</TableCell>
                                <TableCell>{__('Užívateľ')}</TableCell>
                                <TableCell>{__('Udalosť')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.map(logs.items, item => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell>{formatDate(item.created, 'dd.mm.yyyy hh:ii')}</TableCell>
                                        <TableCell>{!_.isEmpty(item.user) ? item.user : __('Systém')}</TableCell>
                                        <TableCell>{this.getText(item.event_type, item.data)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    count={logs.total}
                                    rowsPerPage={logs.per_page}
                                    page={logs.page - 1}
                                    getItemAriaLabel={type => type === 'next' ? __('Ďalšia stránka') : __('Predošlá stránka')}
                                    labelDisplayedRows={({ from, to, count }) => `${from} - ${to} z ${count}`}
                                    onPageChange={(event, page) => this.onChangePage(page + 1)}
                                    colSpan={4}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer> : <div className="logs__empty">{__('Zatiaľ nie sú žiadne udalosti')}</div>}
            </div>
        );
    }
}

export { Logs };
