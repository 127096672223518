import _ from 'lodash';
import { toNumber } from '../functions';
import {
    FETCH_CAB_ORDERS,
    CLEAN_CAB_ORDERS,
    SYNC_CAB_ORDER,
    EDIT_CAB_ORDER,
    CHANGE_CAB_ORDER_STATE,
    EXPORT_CAB_ORDER,
    CREATE_CAB_INVOICE,
    CREATE_CAB_INVOICES,
    PAID_CAB_ORDER,
    PAID_CAB_ORDERS,
    SEND_CAB_ORDERS,
} from '../actions/types';

const CAB_ORDERS_DEFAULT_STATE = {};

/**
 * Reducer pre objednavky.
 */
const CabOrdersReducer = (state = CAB_ORDERS_DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case FETCH_CAB_ORDERS:
            return payload;

        case CLEAN_CAB_ORDERS:
            return CAB_ORDERS_DEFAULT_STATE;

        case CREATE_CAB_INVOICE:
        case CREATE_CAB_INVOICES:
            // Vytvorenie faktury
            // Vytvorenie faktur
            return { ...state, items: _.map(state.items, item => {
                    if (!_.isEmpty(payload[toNumber(item.id)])) {
                        // Nasli sme objednavku, pridame a nastavime objednavku ako procesovanu
                        const data = payload[toNumber(item.id)];

                        item = _.isString(data)
                            ? { ...item, error_invoice: data }
                            : { ...item,
                                invoice: data,
                                invoice_status_pdf: 'newly',
                                invoice_status: data.paid > 0 ? 'paid' : 'unpaid',
                            };
                    }

                    return item;
                }) };

        case PAID_CAB_ORDER:
        case PAID_CAB_ORDERS:
            // Uhradenie objednavky
            // Uhradenie objednavok
            return { ...state, items: _.map(state.items, item => {
                    if (_.has(payload, item.id)) {
                        // Nasli sme objednavku nastavime status faktury ako uhradeny
                        item = !_.isEmpty(payload[item.id])
                            ? { ...item, data: { ...item.data, state: payload[item.id], paid: true } }
                            : { ...item, invoice_status: 'paid' };
                    }

                    return item;
                }) };

        case EDIT_CAB_ORDER:
            // Editacia objednavky
            return { ...state, items: _.map(state.items, item => {
                if (toNumber(item.id) === toNumber(payload.id)) {
                    // Nasli sme objednavku
                    item = { ...item, data: { ...item.data, ...payload.data } };
                }

                return item;
            }) };

        case SEND_CAB_ORDERS:
            // Odoslanie objednavok
            const { packages, datas } = payload;

            return { ...state, items: _.map(state.items, item => {
                    if (!_.isEmpty(packages[toNumber(item.id)])) {
                        // Nasli sme objednavku nastavime zasielku
                        item = { ...item, package: packages[toNumber(item.id)] };
                    }

                    if (!_.isEmpty(datas[toNumber(item.id)])) {
                        // Nasli sme objednavku nastavime data
                        item = { ...item, data: datas[toNumber(item.id)] };
                    }

                    return item;
                }) };

        case SYNC_CAB_ORDER:
        case CHANGE_CAB_ORDER_STATE:
            return { ...state, items: _.map(state.items, item => {
                if (!_.isEmpty(payload[toNumber(item.id)])) {
                    // Nasli sme objednavku zmenime data
                    item = { ...item, data: payload[toNumber(item.id)] };
                }

                return item;
            }) };

        case EXPORT_CAB_ORDER:
            // Export objednavky
            // Export objednavok
            return { ...state, items: _.map(state.items, item => {
                    if (_.has(payload, item.id)) {
                        // Nasli sme objednavku nastavime priznak ako exportovany
                        item = { ...item, exported: 1 };
                    }

                    return item;
                }) };

        default:
            return state;
    }
};

export default CabOrdersReducer;
