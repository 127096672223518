import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {ListScreen, Select, Order, Checkbox, Packages} from '../components';
import {
    fetchCabOrders,
    cleanCabOrders,
    setUser,
    changeUserSetting,
    syncCabOrder,
    changeCabOrderData,
    changeCabOrderState,
    pdfCabOrder,
    createCabInvoice,
    createCabInvoices,
    paidCabOrder,
    paidCabOrders,
    sendCabOrders,
} from '../actions';
import {__, formatDate, formatAmount, getOrderButtons, toNumber, request} from '../functions';
import {ORDER_SOURCES} from '../config';
import '../assets/styles/cab_orders.css';
import InvoicesIcon from "@mui/icons-material/Description";
import PdfIcon from "@mui/icons-material/PictureAsPdf";
import PaidIcon from "@mui/icons-material/MonetizationOn";
import CouriersIcon from "@mui/icons-material/LocalShipping";

/**
 * Objednavky.
 */
class CabOrdersScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávky');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        loadingSync: false,
        downloadedPdf: [],
        lightbox: {
            state: false,
            paid: false,
            multipdf: false,
        },
    }};

    /**
     * Bol volany klik na objednavku.
     *
     * @type {boolean}
     */
    callViewClick = false;

    defaultState = {};

    /**
     * Nacitame zoznam.
     *
     * @param {number} page
     * @param {number} per_page
     * @param {Object} filtered
     * @param {function|null} callback
     */
    async fetchList(page = 1, per_page = 0, filtered = {}, callback = null) {
        this.page = page;

        const { fetch, params } = this.props;

        // Nacitame zoznam
        await fetch(this, params.type, { page, per_page, filtered, callback });

        const { items } = this.props;

        if (!_.isEmpty(items.filtered)) {
            // Nastavime filter
            this.setState({ filtered: items.filtered });
        }

        this.afterFetchList();
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        return [];
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'cab_orders';
    }

    getColumns() {
        return [
            <div>{`${__('Číslo')} / ${__('Suma')} / ${__('Dátum')}`}</div>,
            <div>{`${__('Zákaznik')} / ${__('Dodacia adresa')} / ${__('Email')}`}</div>,
            <div>{`${__('Dodanie')} / ${__('Platba')} / ${__('Poznámka')}`}</div>,
            <div></div>,
        ];
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { user, items } = this.props;
        const { downloadedPdf } = this.state;

        const states = _.reduce(items.states[item.user_eshop_id], (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme tlacitka pre objednavku
        let buttons = getOrderButtons(item, {
            settings: items.settings,
            downloadedPdf,
            hasInvoiceCreate: this.hasAccountingAction('create'),
            hasInvoicePdf: this.hasAccountingAction('pdf'),
            hasInvoicing: this.hasAccountingAction('invoicing'),
            hasPermissionView: this.hasPermission('orders-view'),
            hasPermissionPdfOrder: this.hasPermission('orders-pdf'),
            hasPermissionSync: this.hasPermission('orders-sync'),
            hasPermissionPdf: this.hasPermission('invoices-pdf'),
            hasPermissionCreateInvoice: this.hasPermission('invoices-create'),
            hasPermissionPaid: this.hasPermission('orders-paid'),
            hasPermissionSend: this.hasPermission('orders-send'),
            userCouriers: items.user_couriers[item.user_eshop_id],
            couriers: items.couriers,
            courierSettings: items.courier_settings[item.user_eshop_id],
            courierSettingsIds: _.reduce(
                items.user_couriers[item.user_eshop_id],
                (result, { id }) => ([ ...result, ...[id.toString()] ]),
                []
            ),
        });

        // Nastavime callbacky
        if (typeof buttons.view !== 'undefined') {
            buttons.view.callback = (callbackLoading, callback) => this.showDrawer('order', {
                id: item.id,
                user_eshop_id: item.user_eshop_id,
                buttons,
                callbackLoading,
                callback,
            });
        }

        if (typeof buttons.pdfOrder !== 'undefined') {
            buttons.pdfOrder.callback = (callbackLoading, callback) => this.pdfOrder(item.id, item.user_eshop_id, callbackLoading, callback);
        }

        if (typeof buttons.sync !== 'undefined') {
            buttons.sync.callback = (callbackLoading, callback) => this.syncOrder(item.id, callbackLoading, callback);
        }

        if (typeof buttons.createInvoice !== 'undefined') {
            buttons.createInvoice.callback = (callbackLoading, callback) => this.createInvoice(item.id, callbackLoading, callback);
        }

        if (typeof buttons.pdf !== 'undefined') {
            buttons.pdf.callback = (callbackLoading, callback) => this.pdf(buttons.pdf.options.invoiceId, item.id, callbackLoading, callback);
        }

        if (typeof buttons.paid !== 'undefined') {
            buttons.paid.callback = !buttons.paid.options.paid
                ? (callbackLoading, callback) => this.confirmPaid(item.id, callbackLoading, callback)
                : () => {};
        }

        if (typeof buttons.send !== 'undefined') {
            buttons.send.callback = buttons.send.options.packagePayment || buttons.send.options.packageImport
                ? () => {}
                : (buttons.send.options.packageSend
                        ? () => this.onClickPackageNumber(item.package.number)
                        : () => this.showDrawer('packages', { ids: item.id })
                );
        }

        let source = '';

        if (item.data.source !== '' && _.has(ORDER_SOURCES, item.data.source)) {
            // Je vyplneny zdroj
            const sourceConfig = ORDER_SOURCES[item.data.source];

            source = <div className="source" style={{ backgroundColor: sourceConfig.background }}>
                {sourceConfig.name}
            </div>;
        }

        let color = item.data.state.color;

        return [
            <div>
                <div style={{ backgroundColor: color }} />
                <div>
                    <div>{_.truncate(item.data.number.toString(), { length: 32 })}</div>
                    {source}
                </div>
                <div>{formatAmount(item.data.amount, item.data.currency)}</div>
                <div>{items.user_eshops[item.user_eshop_id]} - {formatDate(item.data.date)}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.client.name, { length: 32 })}</div>
                <div>{_.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 })}</div>
                <div>{item.data.client.email}</div>
            </div>,
            <div>
                <div>{_.truncate(item.data.delivery_type_name, { length: 32 })}</div>
                <div>{_.truncate(item.data.payment_type_name, { length: 32 })}</div>
                <div>{item.data.client.delivery_note !== '' ? _.truncate(item.data.client.delivery_note, { length: 32 }) : '-'}</div>
            </div>,
            <div>
                {this.hasPermission('orders-changestate') && !this.isMobile() ? <div>
                    <Select
                        options={states}
                        value={item.data.state.id}
                        onChange={value => this.confirmState(item.id, value, '')}
                        allowEmpty={false}
                        variant="standard"
                    />
                </div> : null}
                <div>
                    {this.renderTableButtons(item.id, buttons, this.isMobile() ? ['sync', 'paid', 'delete', 'pdfOrder'] : ['delete'], this.isMobile())}
                </div>
            </div>,
        ];
    }

    /**
     * Zobrazime lightbox na zmenu stavu.
     *
     * @param {number} orderId
     * @param {string} stateId
     * @param {string} viewText
     */
    confirmState(orderId, stateId, viewText) {
        this.showLightbox('state', { orderId, stateId, viewText, not_change_in_eshop: false });
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        return {
            number: {
                label: __('Číslo'),
                get: item => _.truncate(item.data.number.toString(), { length: 32 }),
            },
            amount: {
                label: __('Suma'),
                get: item => formatAmount(item.data.amount, item.data.currency),
            },
            created: {
                label: __('Dátum'),
                get: item => formatDate(item.data.date),
            },
            client_name: {
                label: __('Zákaznik'),
                get: item => _.truncate(item.data.client.name, { length: 32 }),
            },
            client_delivery_name: {
                label: __('Zákaznik (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_name, { length: 32 }),
            },
            client_contact_name: {
                label: __('Kontaktná osoba'),
                get: item => _.truncate(item.data.client.contact_name, { length: 32 }),
            },
            client_delivery_contact_name: {
                label: __('Kontaktná osoba (dodanie)'),
                get: item => _.truncate(item.data.client.delivery_contact_name, { length: 32 }),
            },
            client_company: {
                label: __('Firemné údaje'),
                get: item => !_.isEmpty(item.data.client.ico)
                    ? _.truncate(`${__('IČO')}: ${item.data.client.ico}, ${__('DIČ')}: ${item.data.client.dic}, ${__('IČ DPH')}: ${item.data.client.ic_dph}`, { length: 32 })
                    : '',
            },
            client_address: {
                label: __('Fakturačná adresa'),
                get: item => _.truncate(`${item.data.client.address}, ${item.data.client.city}`, { length: 32 }),
            },
            client_delivery_address: {
                label: __('Dodacia adresa'),
                get: item => _.truncate(`${item.data.client.delivery_address}, ${item.data.client.delivery_city}`, { length: 32 }),
            },
            client_phone: {
                label: __('Tel. číslo'),
                get: item => item.data.client.phone,
            },
            client_delivery_phone: {
                label: __('Tel. číslo (dodanie)'),
                get: item => item.data.client.delivery_phone,
            },
            client_email: {
                label: __('E-mail'),
                get: item => _.truncate(item.data.client.email, { length: 32 }),
            },
            client_note: {
                label: __('Poznámka'),
                get: item => _.truncate(item.data.client.delivery_note, { length: 32 }),
            },
            delivery_name: {
                label: __('Dodanie'),
                get: item => _.truncate(item.data.delivery_type_name, { length: 32 }),
            },
            payment_name: {
                label: __('Platba'),
                get: item => _.truncate(item.data.payment_type_name, { length: 32 }),
            },
            internal_comment: {
                label: __('Interná poznámka'),
                get: item => _.truncate(item.data.internal_comment, { length: 32 }),
            },
            package_number: {
                label: __('Číslo zásielky'),
                get: item => item.package.number.toString().indexOf('-') === -1 ? item.package.number : '',
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        const { items } = this.props;

        return [
            this.hasAccountingAction('create') ? {
                callback: (ids, callback) => this.multiInvoices(ids, callback),
                icon: <InvoicesIcon />,
                text: __('Vystaviť faktúry'),
                validation: item => !this.hasInvoice(item),
                permission: 'invoices-create',
            } : null,
            this.hasAccountingAction('pdf') ? {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multipdf', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <PdfIcon />,
                text: __('Stiahnuť PDF'),
                validation: item => this.hasInvoice(item),
                limit: 50,
                permission: 'invoices-pdf',
            } : null,
            {
                callback: (ids, callback) => this.multiPaid(ids, callback),
                icon: <PaidIcon />,
                text: __('Uhradiť'),
                validation: item => item.invoice_status !== 'paid',
                permission: 'orders-paid',
            },
            {
                confirm: (ids, callback) => this.showDrawer('packages', { ids, filtered: items.filtered, callback }),
                icon: <CouriersIcon />,
                text: __('Odoslať prepravcovi'),
                limit: 300,
                permission: 'orders-send',
            },
        ];
    }

    /**
     * Vratime nastavenia buniek.
     *
     * @return {Object}
     */
    getCellsSettings() {
        return {};
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'invoice_id':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Faktúra'),
                                value: '',
                                options: {
                                    'null': __('Bez faktúry'),
                                    'not_null': __('S faktúrou'),
                                },
                            }}};

                case 'has_package':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Zásielka'),
                                value: '',
                                options: {
                                    0: __('Neodoslané'),
                                    1: __('Odoslané'),
                                },
                                strict: false,
                            }}};

                case 'exported':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('Exportované'),
                                value: '',
                                options: {
                                    0: __('Nie'),
                                    1: __('Áno'),
                                },
                                strict: false,
                            }}};

                case 'invoices-status_pdf':
                    return { ...result, ...{ [key]: {
                                type: 'select',
                                name: __('PDF'),
                                value: '',
                                options: {
                                    'newly': __('Nové'),
                                    'download': __('Prečítané'),
                                },
                            }}};

                case 'date-from':
                    return { ...result, ...{ [key]: {
                                type: 'date',
                                name: __('Dátum od'),
                                value: null,
                            }}};

                case 'date-to':
                    return { ...result, ...{ [key]: {
                                type: 'date',
                                name: __('Dátum do'),
                                value: null,
                            }}};

                case 'search':
                    return { ...result, ...{ [key]: {
                                type: 'input',
                                name: __('Hľadať'),
                                value: '',
                            }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne objednávky');
    }

    /**
     * Ma fakturu?
     *
     * @param {Object} item
     * @return {boolean}
     */
    hasInvoice(item) {
        const { items } = this.props;

        return !_.isEmpty(item.invoice)
            || (
                !_.isEmpty(items.settings.meta_data_pdf_link)
                && _.has(item.data.meta_data, items.settings.meta_data_pdf_link)
                && !_.isEmpty(item.data.meta_data[items.settings.meta_data_pdf_link])
            );
    }

    /**
     * Event po kliku na cislo zasielky.
     *
     * @param {string} number
     */
    onClickPackageNumber(number) {
        // Nastavime cislo do clipboardu
        navigator.clipboard.writeText(number);

        this.showSnackbar('success', __('Skopirované do schránky'));
    }

    /**
     * Pridame stiahnute pdf.
     *
     * @param {Array} ids
     *
     * @return {Array}
     */
    getDownloadedPdf(ids) {
        let { downloadedPdf } = this.state;

        _.each(ids, id => {
            downloadedPdf = [ ...downloadedPdf, toNumber(id) ];
        });

        return downloadedPdf;
    }

    /**
     * Synchronizacia objednavky.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async syncOrder(id, callbackLoading, callback) {
        const { syncCabOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Synchronizacia objednavky
        await syncCabOrder(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Event po zmene stavu objednavky.
     */
    async changeState() {
        const { lightbox } = this.state;
        const { changeCabOrderState } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('state');

        // Zobrazime loading
        this.loadingTableButton(this.getLoadingTableButtonId(lightbox.state.orderId, lightbox.state.viewText));

        // Zmenime stav
        await changeCabOrderState(this, lightbox.state.orderId, lightbox.state.stateId, lightbox.state.not_change_in_eshop);

        // Zrusime loading table button
        this.clearTableButton();
    }

    /**
     * Zobrazime lightbox na uhradu.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmPaid(id, callbackLoading, callback) {
        this.showLightbox('paid', { id, callbackLoading, callback });
    }

    /**
     * Stiahnutie pdf.
     *
     * @param {number} id
     * @param {number} orderId
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdf(id, orderId, callbackLoading, callback) {
        // Zavolame loading
        callbackLoading();

        await request(`/cab/invoicePdf/${id}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť pdf'));
                return;
            }

            this.setState({ downloadedPdf: this.getDownloadedPdf([orderId]) });

            if (data.link.indexOf('inline') !== -1) {
                // Chceme inline
                window.open(data.link, '_blank');
                return;
            }

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        callback();
    }

    /**
     * Uhradenie objednavky.
     */
    async paid() {
        const { paidCabOrder } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.paid.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('paid');

        // Uhradime
        await paidCabOrder(this, lightbox.paid.id);

        // Zavolame callback
        lightbox.paid.callback();
    }

    /**
     * Stiahnutie order pdf.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async pdfOrder(id, user_eshop_id, callbackLoading, callback) {
        const { pdfCabOrder } = this.props;

        // Zavolame loading
        callbackLoading();

        // Zavolame callback
        await pdfCabOrder(this, id, this.getPdfOrderExportId(user_eshop_id));

        // Zavolame callback
        callback();
    }

    /**
     * Vytvorenie faktury.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    async createInvoice(id, callbackLoading, callback) {
        const { createCabInvoice } = this.props;

        // Zavolame loading
        callbackLoading();

        // Vytvorime fakturu
        await createCabInvoice(this, id);

        // Zavolame callback
        callback();
    }

    /**
     * Vytvorenie faktur k objednavkam cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} callback
     */
    async multiInvoices(ids, callback) {
        const { createCabInvoices, items } = this.props;

        // Vytvorime faktury
        await createCabInvoices(this, ids, items.filtered);

        // Zavolame callback
        callback();
    }

    /**
     * Stiahnutie pdf objednavok cez multiselect.
     */
    async multiPdf() {
        const { lightbox } = this.state;
        const { items, user } = this.props;

        // Zavrieme lightbox
        this.closeLightbox('multipdf');

        // Zobrazime loading
        lightbox.multipdf.callbackLoading();

        let params = { merge_multi_pdf: user.settings.merge_multi_pdf };

        // Nasetujeme filter
        _.forEach(items.filtered, (value, key) => {
            params = { ...params, ...{ [`filter_${key}`]: value } };
        });

        await request(`/cab/multiPdf/${lightbox.multipdf.ids}`, params).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.showSnackbar('error', __('Nepodarilo sa stiahnuť zoznam pdf'));
                return;
            }

            // Nastavime pdfka ako precitane
            this.setState({ downloadedPdf: this.getDownloadedPdf(lightbox.multipdf.ids.split(',')) });

            // Stiahneme subor
            window.location = data.link;
        });

        // Zavolame callback
        lightbox.multipdf.callback();
    }

    /**
     * Uhradenie objednavok cez multiselect.
     *
     * @param {string|Array} ids
     * @param {function} callback
     */
    async multiPaid(ids, callback) {
        const { paidCabOrders, items } = this.props;

        // Uhradime objednavky
        await paidCabOrders(this, ids, items.filtered);

        // Zavolame callback
        callback();
    }

    /**
     * Vratime id default exportu pdfka.
     *
     * @return {number}
     */
    getPdfOrderExportId(user_eshop_id) {
        const { items } = this.props;

        let export_id = 0;

        _.each(items.exports[user_eshop_id], item => {
            if (item.type === 'pdf' && toNumber(item.def) === 1) {
                // Default pdf
                export_id = item.id;
            }
        });

        return export_id;
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { lightbox } = this.state;
        const { items, user, changeCabOrderData, changeCabOrderState, sendCabOrders } = this.props;

        return (
            <div>
                {this.renderLightbox(
                    'paid',
                    __('Naozaj chcete uhradiť túto objednávku?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.paid()
                )}
                {this.renderLightbox(
                    'state',
                    __('Naozaj chcete zmeniť stav tejto objednávke?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.changeState()
                )}
                {this.renderLightbox(
                    'multipdf',
                    __('Stiahnutie faktúr do pdf'),
                    !_.isEmpty(lightbox.multipdf) ? <div>
                        <Checkbox
                            label={__('Zlúčiť do jedného pdf súboru')}
                            value={user.settings.merge_multi_pdf}
                            onChange={checked => changeUserSetting('merge_multi_pdf', checked)}
                        />
                    </div> : null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiPdf()
                )}
                {this.renderDrawer('packages', {
                    title: __('Odoslanie objednávok'),
                    content: props => <Packages
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        sendOrders={sendCabOrders}
                        cab={true}
                        {...props}
                    />,
                })}
                {this.renderDrawer('order', {
                    title: __('Objednávka'),
                    content: props => <Order
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeOrderData={changeCabOrderData}
                        changeOrderState={changeCabOrderState}
                        settings={items.settings}
                        userCouriers={items.user_couriers[props.user_eshop_id]}
                        couriers={items.couriers}
                        courierSettings={items.courier_settings[props.user_eshop_id]}
                        cab={true}
                        {...props}
                    />,
                })}
                {this.renderOffer()}
            </div>
        );
    }
}

const stateToProps = ({ cab_orders, user }) => ({ items: cab_orders, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchCabOrders,
    clean: cleanCabOrders,
    setUser,
    changeUserSetting,
    syncCabOrder,
    changeCabOrderData,
    changeCabOrderState,
    pdfCabOrder,
    createCabInvoice,
    createCabInvoices,
    paidCabOrder,
    paidCabOrders,
    sendCabOrders,
})(CabOrdersScreen));
