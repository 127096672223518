import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import MallIcon from '@mui/icons-material/AddCircle';
import {ListScreen, Input, Product, Select} from '../components';
import {
    fetchProducts,
    cleanProducts,
    setUser,
    changeUserSetting,
    changeProductStock,
    deleteProduct,
    deleteProducts,
    changeProductData,
    editProduct,
    changeProductMall,
    changeProductAllegro,
} from '../actions';
import {__, formatDate, formatAmount, getProductButtons, toNumber, request} from '../functions';
import '../assets/styles/products.css';
import UploadIcon from "@mui/icons-material/Publish";

/**
 * Produkty.
 */
class ProductsScreen extends ListScreen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Produkty');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = { ...ListScreen.state, ...{
        lightbox: {
            stock: false,
            delete: false,
            multidelete: false,
            allegro: false,
            importExcel: false,
        },
    }};

    /**
     * Memory cache.
     *
     * @type {Object}
     */
    memoryCache = {};

    /**
     * Je validne pravo?
     *
     * @return {boolean}
     */
    isValidPermission() {
        return this.hasPermission('products');
    }

    /**
     * Vratime zoznam tagov.
     *
     * @return {Array}
     */
    getTags() {
        const { user, items } = this.props;

        // Prebieha aktualne import?
        const processingImport = _.some(user.events, item => item.event === 'products' && _.includes(['waiting', 'processing'], item.status));

        const userEshopId = toNumber(user.user_eshop_id);

        if (!processingImport) {
            return [
                _.includes([21,552], userEshopId) ? {
                    name: __('Import Excel zo súboru'),
                    onClick: () => this.showLightbox('importExcel', { loading: false }),
                    color: 'secondary',
                } : null,
            ];
        }

        return [
            {
                name: __('Prebieha import produktov z eshopu.'),
                color: 'orange',
            }
        ];
    }

    onChangeImportExcel(type, value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, importExcel: { ...lightbox.importExcel, [type]: value } } });
    }

    uploadExcelFile(file) {
        this.onChangeImportExcel('loading', true);

        request('/prime/uploadExcel', file, 'FILE').then(response => {
            const { status } = response.data;

            this.onChangeImportExcel('loading', false);

            if (status === 'error') {
                // Nepodarilo sa nahrat prilohu
                this.showSnackbar('error', __('Nepodarilo sa nahrať súbor'));
                return;
            }

            // Nastavime prilohu
            window.location = '/products';
        });
    }

    /**
     * Vratime nazov tabulky.
     *
     * @return {string}
     */
    getTableName() {
        return 'products';
    }

    /**
     * Vratime bunky.
     *
     * @param {Object} item
     *
     * @return {Array}
     */
    getCells(item) {
        const { items } = this.props;

        if (!_.has(this.memoryCache, 'hasPermissionView')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionView = this.hasPermission('products-view');
        }

        if (!_.has(this.memoryCache, 'hasPermissionStock')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionStock = this.hasPermission('products-stock');
        }

        if (!_.has(this.memoryCache, 'hasPermissionDelete')) {
            // Nemame v memory cache
            this.memoryCache.hasPermissionDelete = this.hasPermission('products-delete');
        }

        // Vytiahneme tlacitka pre produkt
        let buttons = getProductButtons(item, {
            hasPermissionView: this.memoryCache.hasPermissionView,
            hasPermissionStock: this.memoryCache.hasPermissionStock,
            hasPermissionDelete: this.memoryCache.hasPermissionDelete,
        });

        if (_.has(buttons, 'stock')) {
            buttons.stock.callback = (callbackLoading, callback) => this.confirmStock(item.id, callbackLoading, callback, _.toString(toNumber(item.data.stock)));
        }

        if (items.mall_active) {
            // TODO::
            const activeMall = _.has(item.data.settings, 'mall');

            buttons = { mall: {
                name: activeMall ? __('Zrušiť na mall') : __('Pridať na mall'),
                icon: <MallIcon color={activeMall ? 'secondary' : 'inherit'} />,
                callback: (callbackLoading, callback) => this.mall(item.id, callbackLoading, callback, !activeMall),
                options: {},
            }, ...buttons };
        }

        if (items.allegro_active) {
            // TODO::
            const activeAllegro = _.has(item.data.settings, 'allegro');

            buttons = { allegro: {
                name: activeAllegro ? __('Zrušiť na allegro') : __('Pridať na allegro'),
                icon: <MallIcon color={activeAllegro ? 'secondary' : 'inherit'} />,
                callback: (callbackLoading, callback) => this.confirmAllegro(item.id, callbackLoading, callback, !activeAllegro),
                options: {},
            }, ...buttons };
        }

        if (_.has(buttons, 'delete')) {
            buttons.delete.callback = (callbackLoading, callback) => this.confirmDelete(item.id, callbackLoading, callback);
        }

        // Nastavime callbacky
        if (_.has(buttons, 'view')) {
            buttons.view.callback = () => this.showDrawer('product', {
                id: item.id,
                buttons,
            });
        }

        // Vytiahneme nastavenia buniek
        const cellsSettings = this.getCellsSettings();

        // Vytiahneme options buniek
        const cellsOptions = this.getCellsOptions();

        return [
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[0][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[1][2]].get(item))}
            </div>,
            <div>
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][0]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][1]].get(item))}
                {this.getCellValue(cellsOptions[cellsSettings.settings[2][2]].get(item))}
            </div>,
            <div>{this.renderTableButtons(item.id, buttons)}</div>,
        ];
    }

    /**
     * Vratime options buniek.
     *
     * @return {Object}
     */
    getCellsOptions() {
        const { user } = this.props;

        return {
            name: {
                label: __('Názov'),
                get: item => {
                    const name = _.truncate(item.data.name, { length: 48 });

                    if (!item.data.available) {
                        return <span className="error">{name}</span>;
                    }

                    return name;
                },
            },
            amount: {
                label: __('Celková cena'),
                get: item => formatAmount(item.data.total_price, user.settings.currency),
            },
            number_ean: {
                label: __('Číslo produktu | EAN'),
                get: item => `${item.data.number !== '' ? item.data.number : '-'} / ${item.data.ean !== '' ? item.data.ean : '-'}`,
            },
            stock: {
                label: __('Stav na sklade'),
                get: item => formatAmount(item.data.stock, 'ks', 0),
            },
            weight: {
                label: __('Váha'),
                get: item => formatAmount(item.data.weight, 'kg', 2),
            },
            modified: {
                label: __('Posledná zmena'),
                get: item => formatDate(item.modified, 'dd.mm.yyyy hh:ii'),
            },
            variant: {
                label: __('Variant'),
                get: item => item.data.variant,
            },
            manufacturer: {
                label: __('Výrobca'),
                get: item => item.data.manufacturer,
            },
            tax: {
                label: __('DPH'),
                get: item => formatAmount(item.data.tax, '%', 0),
            },
        };
    }

    /**
     * Vratime multiselect.
     *
     * @return {Array}
     */
    getMultiselect() {
        return [
            {
                confirm: (ids, callback, callbackLoading) => this.showLightbox('multidelete', {
                    ids,
                    callback,
                    callbackLoading,
                }),
                icon: <DeleteIcon />,
                text: __('Zmazať'),
                permission: 'products-delete',
            }
        ];
    }

    /**
     * Vratime filter.
     *
     * @return {Object}
     */
    getFilter() {
        const { items } = this.props;

        return _.reduce(items.filter, (result, options, key) => {
            switch (key) {
                case 'search':
                    return { ...result, ...{ [key]: {
                        type: 'input',
                        name: __('Hľadať'),
                        value: '',
                    }}};

                default:
                    return result;
            }
        }, {});
    }

    /**
     * Vratime empty text.
     *
     * @return {string}
     */
    getEmptyText() {
        return __('Zatiaľ nemáte žiadne produkty');
    }

    /**
     * Zobrazime lightbox na zmazanie.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     */
    confirmDelete(id, callbackLoading, callback) {
        this.showLightbox('delete', { id, callbackLoading, callback });
    }

    /**
     * Zobrazime lightbox na sklade.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {number} stock
     */
    confirmStock(id, callbackLoading, callback, stock) {
        this.showLightbox('stock', { id, callbackLoading, callback, stock });
    }

    /**
     * Zobrazime lightbox na sklade.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {bool} active
     */
    confirmAllegro(id, callbackLoading, callback, active) {
        this.showLightbox('allegro', { id, callbackLoading, callback, active, category_id: '' });
    }

    /**
     * Event po zmene allegro kategorie.
     *
     * @param {string} category_id
     */
    onChangeAllegroCategoryId(category_id) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, allegro: { ...lightbox.allegro, category_id } } })
    }

    /**
     * Event po zmene stavu na sklade.
     *
     * @param {string} stock
     */
    onChangeStock(stock) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, stock: { ...lightbox.stock, stock } } })
    }

    /**
     * Zobrazit/zrusit na mall.
     *
     * @param {number} id
     * @param {function} callbackLoading
     * @param {function} callback
     * @param {boolean} show
     */
    async mall(id, callbackLoading, callback, show) {
        const { changeProductMall } = this.props;

        // Zavolame loading
        callbackLoading();

        // Zmenime produkt na mall
        await changeProductMall(this, id, show);

        // Zavolame callback
        callback();
    }

    /**
     * Zobrazit/zrusit na allegro.
     */
    async allegro() {
        const { changeProductAllegro } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.allegro.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('allegro');

        // Zmenime produkt na allegro
        await changeProductAllegro(this, lightbox.allegro.id, lightbox.allegro.active, lightbox.allegro.category_id);

        // Zavolame callback
        lightbox.allegro.callback();
    }

    /**
     * Ulozime stav na sklade.
     */
    async saveStock() {
        const { changeProductStock } = this.props;
        const { lightbox } = this.state;

        // Zavolame loading
        lightbox.stock.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('stock');

        // Zmenime stav na sklade
        await changeProductStock(this, lightbox.stock.id, lightbox.stock.stock);

        // Zavolame callback
        lightbox.stock.callback();
    }

    /**
     * Zmazanie produktu.
     */
    async delete() {
        const { deleteProduct, items } = this.props;
        const { lightbox } = this.state;

        if (this.isDemo()) {
            this.showSnackbar('error', __('Produkt nie je možné zmazať na DEMO účte'))
            this.closeLightbox('delete');
            return;
        }


        // Zavolame loading
        lightbox.delete.callbackLoading();

        // Zavrieme lightbox
        this.closeLightbox('delete');

        // Zmazeme produktu
        await deleteProduct(this, lightbox.delete.id);

        // Zavolame callback
        lightbox.delete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Zmazanie produktov cez multiselect.
     */
    async multiDelete() {
        const { deleteProducts, items } = this.props;
        const { lightbox } = this.state;

        if (this.isDemo()) {
            this.showSnackbar('error', __('Produkty nie je možné zmazať na DEMO účte'))
            this.closeLightbox('multidelete');
            return;
        }

        // Zavrieme lightbox
        this.closeLightbox('multidelete');

        // Zobrazime loading
        lightbox.multidelete.callbackLoading();

        // Zmazeme produktov
        await deleteProducts(this, lightbox.multidelete.ids, items.filtered);

        // Zavolame callback
        lightbox.multidelete.callback();

        // Znovu nacitame zoznam od prvej stranky
        this.fetchList(1, 0, items.filtered);
    }

    /**
     * Rendrujeme screen.
     *
     * @return {JSX.Element|null}
     */
    renderScreen() {
        const { changeProductData, editProduct, user } = this.props;
        const { lightbox } = this.state;

        return (
            <div>
                {this.renderLightbox(
                    'stock',
                    __('Stav na sklade'),
                    !_.isEmpty(lightbox.stock) ? <div>
                        <Input
                            label={__('Počet')}
                            value={lightbox.stock.stock}
                            onChange={value => this.onChangeStock(value)}
                            type="number"
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveStock()
                )}
                {this.renderLightbox(
                    'allegro',
                    __('Allegro'),
                    !_.isEmpty(lightbox.allegro) ? <div>
                        <Input
                            label={__('ID kategórie')}
                            value={lightbox.allegro.category_id}
                            onChange={value => this.onChangeAllegroCategoryId(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.allegro()
                )}
                {this.renderLightbox(
                    'delete',
                    __('Naozaj chcete zmazať tento produkt?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.delete()
                )}
                {this.renderLightbox(
                    'multidelete',
                    __('Naozaj chcete zmazať označené produkty?'),
                    null,
                    __('Áno'),
                    __('Nie'),
                    () => this.multiDelete()
                )}
                {this.renderLightbox(
                    'importExcel',
                    __('Import Excel zo súboru'),
                    !_.isEmpty(lightbox.importExcel) ? (lightbox.importExcel.loading ? this.renderLoading(20) : <div>
                        <div className="prime_products__upload">
                            <UploadIcon />
                            <div className="prime_products__upload__text">{__('Nahrajte súbor vo formáte xlsx.')}</div>
                            <input type="file" onChange={event => this.uploadExcelFile(event.target.files[0])} />
                        </div>
                    </div>) : null,
                    '',
                    __('Zrušiť'),
                    () => {}
                )}
                {this.renderDrawer('product', {
                    title: __('Produkt'),
                    content: props => <Product
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeProductData={changeProductData}
                        editProduct={editProduct}
                        user={user}
                        {...props}
                    />,
                })}
            </div>
        );
    }
}

const stateToProps = ({ products, user }) => ({ items: products, user });

export default withCookies(connect(stateToProps, {
    fetch: fetchProducts,
    clean: cleanProducts,
    setUser,
    changeUserSetting,
    changeProductStock,
    deleteProduct,
    deleteProducts,
    changeProductData,
    editProduct,
    changeProductMall,
    changeProductAllegro,
})(ProductsScreen));
