import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import {Screen, Button, Input, Select, Checkbox, MtoknaOrder} from '../components';
import { setUser } from '../actions';
import {__, request, formatAmount, toNumber, formatDate, round} from '../functions';
import '../assets/styles/mtokna_create_order.css';
import { changeMtoknaComplaintData, changeMtoknaOrderData } from '../actions';
import {Autocomplete, IconButton, Table, TableBody, TableCell, TableRow, TextField, Tooltip, ButtonBase} from "@mui/material";
import {CURRENCIES} from "../config";
import DeleteIcon from "@mui/icons-material/Delete";
import DiscountIcon from "@mui/icons-material/Discount";

/**
 * Objednavka.
 */
class MtoknaCreateOrderScreen extends Screen {
    /**
     * Title.
     *
     * @type {string}
     */
    title = __('Objednávka');

    /**
     * Default state.
     *
     * @type {Object}
     */
    state = {
        loading: false,
        sameDelivery: true,
        showCompany: false,
        id: '',
        data: {},
        pickup_points: [],
        pickup_point: null,
        searched_clients: [],
        searched_clients_email: [],
        searched_products: [],
        searched_products_number: [],
        settings: {
            state_id: 'pending',
            number: '',
            currency: '',
            client: {
                name: '',
                ico: '',
                dic: '',
                ic_dph: '',
                email: '',
                phone: '',
                address: '',
                city: '',
                zip: '',
                country: '',
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_country: '',
                delivery_phone: '',
                delivery_note: '',
                searched: {},
            },
            products: [],
            delivery_type_id: '',
            delivery_type_name: '',
            delivery_type_amount: '',
            delivery_type_pickup_point: '',
            payment_type_id: '',
            payment_type_name: '',
            payment_type_amount: '',
            meta_data: {},
            meta_data_modified: {},
            meta_data_deleted: {},
            complaint: {
                invoice_number: '',
                text: '',
            },
        },
        lightbox: {
            orders: false,
            discount: false,
        },
        loadingWindowSeller: false,
    };

    search_clients_timeout = null;
    search_clients_email_timeout = null;
    search_products_timeout = null;
    search_products_number_timeout = null;
    changed = false;
    tries = 0;

    /**
     * Komponenta bola pripojena.
     *
     * @return boolean
     */
    async componentDidMount() {
        if (super.componentDidMount()) {
            const { params, setUser } = this.props;

            const id = params.id !== 'new' ? params.id : '';

            request(`/mtokna/createOrder/${params.user_eshop_id}/${id === '' ? '0' : id}/${this.getSubType()}`).then(response => {
                const { status, data, sync } = response.data;

                if (status === 'error') {
                    window.location = '/manual';
                    return;
                }

                setUser(sync.user);

                let { settings, pickup_points, pickup_point, sameDelivery, showCompany } = this.state;

                let searched_clients = [];
                let searched_clients_email = [];
                let searched_products = [];
                let searched_products_number = [];

                settings = { ...settings, ...{
                    number: data.number,
                    currency: sync.user.settings.currency,
                    client: { ...settings.client, ...{
                        country: sync.user.settings.market,
                        delivery_country: sync.user.settings.market,
                    } },
                } };

                if (toNumber(params.user_eshop_id) === 38) {
                    settings = { ...settings, ...{
                        delivery_type_id: '1',
                        delivery_type_name: 'Kuriér',
                        delivery_type_amount: '0',
                        payment_type_id: 'bacs',
                        payment_type_name: 'Prevodom na náš účet',
                        payment_type_amount: '0',
                    } };
                }

                if (sync.user.mtokna_type === 'mtokna_seller' && !data.has_eshop_access) {
                    settings = { ...settings, ...{
                        client: {
                            name: !_.isEmpty(data.client_data) ? data.client_data.name : sync.user.email,
                            ico: !_.isEmpty(data.client_data) ? data.client_data.ico : '',
                            dic: !_.isEmpty(data.client_data) ? data.client_data.dic : '',
                            ic_dph: !_.isEmpty(data.client_data) ? data.client_data.ic_dph : '',
                            email: sync.user.email,
                            phone: !_.isEmpty(data.client_data) ? data.client_data.phone : '',
                            address: !_.isEmpty(data.client_data) ? data.client_data.address : '',
                            city: !_.isEmpty(data.client_data) ? data.client_data.city : '',
                            zip: !_.isEmpty(data.client_data) ? data.client_data.zip : '',
                            country: !_.isEmpty(data.client_data) ? data.client_data.country : 'sk',
                            delivery_address: !_.isEmpty(data.client_data) ? data.client_data.delivery_address : '',
                            delivery_city: !_.isEmpty(data.client_data) ? data.client_data.delivery_city : '',
                            delivery_zip: !_.isEmpty(data.client_data) ? data.client_data.delivery_zip : '',
                            delivery_country: !_.isEmpty(data.client_data) ? data.client_data.delivery_country : 'sk',
                            delivery_phone: !_.isEmpty(data.client_data) ? data.client_data.delivery_phone : '',
                            delivery_note: !_.isEmpty(data.client_data) ? data.client_data.delivery_note : '',
                        },
                        delivery_type_id: data.eshop_data.delivers[0].id,
                        delivery_type_name: data.eshop_data.delivers[0].name,
                        delivery_type_amount: '0',
                        payment_type_id: 'bacs',
                        payment_type_name: data.eshop_data.payments[0].name,
                        payment_type_amount: '0',
                    } };
                } else if (sync.user.mtokna_type === 'mtokna_seller' && data.has_eshop_access) {
                    settings = { ...settings, ...{
                        state_id: data.eshop_data.states[0].id,
                        delivery_type_id: data.eshop_data.delivers[0].id,
                        delivery_type_name: data.eshop_data.delivers[0].name,
                        delivery_type_amount: '0',
                        payment_type_id: data.eshop_data.payments[0].id,
                        payment_type_name: data.eshop_data.payments[0].name,
                        payment_type_amount: '0',
                    } };

                    if (toNumber(params.user_eshop_id) === 38 && sync.user.email === 'zeleziarstvo@mt-okna.sk') {
                        settings = { ...settings, ...{
                            delivery_type_id: '2',
                            delivery_type_name: 'Osobný odber na predajni: ŽELEZIARSTVO MT, Rudohorská 33, 974 11 Banská Bystrica',
                            delivery_type_amount: '0',
                            payment_type_id: 'cod',
                            payment_type_name: 'Platba pri prevzatí tovaru - poplatok 1€',
                            payment_type_amount: '0',
                        } };
                    }
                }

                if (
                    (this.getSubType() === 'window-blind'
                    || this.getSubType() === 'window-net'
                    || (sync.user.mtokna_type === 'mtokna_seller' && data.has_eshop_access))
                    && sync.user.email !== 'zeleziarstvo@mt-okna.sk'
                ) {
                    settings = { ...settings, state_id: data.eshop_data.states[1].id };
                }

                if (_.has(data, 'edit_complaint') && !_.isEmpty(data.edit_complaint)) {
                    settings = { ...settings, complaint: {
                        invoice_number: data.edit_complaint.invoice_number,
                        text: data.edit_complaint.text,
                    } };
                }

                if (_.has(data, 'edit_order') && !_.isEmpty(data.edit_order)) {
                    sameDelivery = false;
                    showCompany = !_.isEmpty(data.edit_order.client.ico);

                    let products = [];

                    _.each(data.edit_order.products, product => {
                        let window_discount = '';

                        if (
                            (this.getSubType() === 'window-blind' || this.getSubType() === 'window-net')
                            && product.name === 'Zľava (registrovaný zákazník)'
                        ) {
                            window_discount = round(Math.abs(toNumber(product.total_price)) / (toNumber(data.edit_order.amount) + Math.abs(toNumber(product.total_price))) * 100, 0);
                        }

                        products = [ ...products, {
                            id: product.id,
                            name: product.name,
                            number: product.number,
                            quantity: product.quantity,
                            unit_price: product.unit_price,
                            tax: product.tax,
                            total_price: product.total_price,
                            total_price_sum: toNumber(product.total_price) * toNumber(product.quantity),
                            centre: _.has(product.meta_data, 'centre') ? product.meta_data.centre : '',
                            seller_order_id: _.has(product.meta_data, 'seller_order_id') ? product.meta_data.seller_order_id : '',
                            owner_order_id: _.has(product.meta_data, 'owner_order_id') ? product.meta_data.owner_order_id : '',
                            complaint_order_id: _.has(product.meta_data, 'complaint_order_id') ? product.meta_data.complaint_order_id : '',
                            window_order_id: _.has(product.meta_data, 'window_order_id') ? product.meta_data.window_order_id : '',
                            seller_manual_order_id: _.has(product.meta_data, 'seller_manual_order_id') ? product.meta_data.seller_manual_order_id : '',
                            discount: _.has(product.meta_data, 'discount') ? product.meta_data.discount : '',
                            original_total_price: _.has(product.meta_data, 'original_total_price') ? product.meta_data.original_total_price : product.total_price,
                            supplier: _.has(product.meta_data, 'supplier') && product.meta_data.supplier !== '' ? product.meta_data.supplier : this.getDefaultSupplierId(data),
                            supplier_name: _.has(product.meta_data, 'supplier_name') ? product.meta_data.supplier_name : '',
                            complaint_type: _.has(product.meta_data, 'complaint_type') ? product.meta_data.complaint_type : '',
                            complaint_comment: _.has(product.meta_data, 'complaint_comment') ? product.meta_data.complaint_comment : '',
                            window_blind_design: _.has(product.meta_data, 'window_blind_design') ? product.meta_data.window_blind_design : '',
                            window_blind_width: _.has(product.meta_data, 'window_blind_width') ? product.meta_data.window_blind_width : '',
                            window_blind_length: _.has(product.meta_data, 'window_blind_length') ? product.meta_data.window_blind_length : '',
                            window_blind_control: _.has(product.meta_data, 'window_blind_control') ? product.meta_data.window_blind_control : '',
                            window_blind_category: _.has(product.meta_data, 'window_blind_category') ? product.meta_data.window_blind_category : '',
                            window_blind_color1: _.has(product.meta_data, 'window_blind_color1') ? product.meta_data.window_blind_color1 : '',
                            window_blind_color2: _.has(product.meta_data, 'window_blind_color2') ? product.meta_data.window_blind_color2 : '',
                            window_blind_color3: _.has(product.meta_data, 'window_blind_color3') ? product.meta_data.window_blind_color3 : '',
                            window_net_width: _.has(product.meta_data, 'window_net_width') ? product.meta_data.window_net_width : '',
                            window_net_length: _.has(product.meta_data, 'window_net_length') ? product.meta_data.window_net_length : '',
                            window_net_height: _.has(product.meta_data, 'window_net_height') ? product.meta_data.window_net_height : '',
                            window_net_color: _.has(product.meta_data, 'window_net_color') ? product.meta_data.window_net_color : '',
                            window_net_type: _.has(product.meta_data, 'window_net_type') ? product.meta_data.window_net_type : '',
                            window_discount,
                            autocomplete: toNumber(product.id) > 0,
                            searched: product,
                        } ];

                        searched_products = [ ...searched_products, product ];
                        searched_products_number = [ ...searched_products_number, product ];
                    });

                    let searched_client = data.edit_order.client;

                    searched_clients = [data.edit_order.client];
                    searched_clients_email = [data.edit_order.client];

                    settings = { ...settings, ...{
                        state_id: data.edit_order.state.id,
                        number: data.edit_order.number,
                        currency: data.edit_order.currency,
                        client: {
                            name: data.edit_order.client.name,
                            ico: data.edit_order.client.ico,
                            dic: data.edit_order.client.dic,
                            ic_dph: data.edit_order.client.ic_dph,
                            email: data.edit_order.client.email,
                            phone: data.edit_order.client.phone,
                            address: data.edit_order.client.address,
                            city: data.edit_order.client.city,
                            zip: data.edit_order.client.zip,
                            country: data.edit_order.client.country,
                            delivery_address: data.edit_order.client.delivery_address,
                            delivery_city: data.edit_order.client.delivery_city,
                            delivery_zip: data.edit_order.client.delivery_zip,
                            delivery_country: data.edit_order.client.delivery_country,
                            delivery_phone: data.edit_order.client.delivery_phone,
                            delivery_note: data.edit_order.client.delivery_note,
                            searched: searched_client,
                        },
                        products,
                        delivery_type_id: data.edit_order.delivery_type.id,
                        delivery_type_name: data.edit_order.delivery_type_name,
                        delivery_type_amount: data.edit_order.delivery_type.amount,
                        delivery_type_pickup_point: data.edit_order.client.delivery_pickup_point_id,
                        payment_type_id: data.edit_order.payment_type.id,
                        payment_type_name: data.edit_order.payment_type_name,
                        payment_type_amount: data.edit_order.payment_type.amount,
                        meta_data: data.edit_order.meta_data,
                    } };

                    if (
                        this.isCustomPoints(settings.delivery_type_name)
                        && _.has(settings.meta_data, 'inv_custom_picked_shop_id')
                    ) {
                        settings = {
                            ...settings,
                            delivery_type_pickup_point: settings.meta_data['inv_custom_picked_shop_id']
                        };
                    }

                    const pickup_points_data = this.getPickupPoints(data, settings, settings.delivery_type_name);

                    pickup_points = pickup_points_data.pickup_points;
                    pickup_point = pickup_points_data.pickup_point;
                }

                this.setState({
                    id,
                    data,
                    settings,
                    pickup_points,
                    pickup_point,
                    searched_clients,
                    searched_clients_email,
                    searched_products,
                    searched_products_number,
                    sameDelivery,
                    showCompany,
                });
            });
        }

        return true;
    }

    /**
     * Komponenta bola zmenena.
     *
     * @param {Object} prevProps
     * @param {Object} prevState
     * @param {Object} snapshot
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { settings, pickup_point } = this.state;

        if (settings.delivery_type_pickup_point.toString() !== prevState.settings.delivery_type_pickup_point.toString()) {
            let meta_datas = {};
            let delete_meta_datas = {};

            if (this.isPacketaPoints(settings.delivery_type_name)) {
                meta_datas = { ...meta_datas, packeta_name: pickup_point !== null ? pickup_point.name : '' };
            } else if (_.has(settings.meta_data, 'packeta_name')) {
                delete_meta_datas = { ...delete_meta_datas, packeta_name: '' };
            }

            if (this.isCustomPoints(settings.delivery_type_name)) {
                meta_datas = { ...meta_datas, ...{
                    inv_custom_picked_shop_id: pickup_point !== null ? pickup_point.id : '',
                    inv_custom_picked_shop_name: pickup_point !== null ? pickup_point.name : '',
                    inv_custom_picked_shop_address: pickup_point !== null ? pickup_point.address : '',
                    inv_custom_parcelshop_id_dopravy: 'inv_custom_parcel_shop',
                    inv_custom_parcelshop_contact: pickup_point !== null ? pickup_point.contact : '',
                }};
            } else if (_.has(settings.meta_data, 'inv_custom_picked_shop_id')) {
                delete_meta_datas = { ...delete_meta_datas, ...{
                    inv_custom_picked_shop_id: '',
                    inv_custom_picked_shop_name: '',
                    inv_custom_picked_shop_address: '',
                    inv_custom_parcelshop_id_dopravy: '',
                    inv_custom_parcelshop_contact: '',
                }};
            }

            if (this.isGlsPoints(settings.delivery_type_name)) {
                meta_datas = { ...meta_datas, ...{
                    inv_gls_picked_shop_id: pickup_point !== null ? pickup_point.id : '',
                    inv_gls_picked_shop_name: pickup_point !== null ? pickup_point.name : '',
                    inv_gls_picked_shop_address: pickup_point !== null ? pickup_point.address : '',
                    inv_gls_parcelshop_id_dopravy: 'inv_gls_parcel_shop',
                    inv_gls_picked_shop_data: '',
                }};
            } else if (_.has(settings.meta_data, 'inv_gls_picked_shop_id')) {
                delete_meta_datas = { ...delete_meta_datas, ...{
                    inv_gls_picked_shop_id: '',
                    inv_gls_picked_shop_name: '',
                    inv_gls_picked_shop_address: '',
                    inv_gls_parcelshop_id_dopravy: '',
                    inv_gls_picked_shop_data: '',
                }};
            }

            this.onChangeMetaDatas(meta_datas, delete_meta_datas);
        }
    }

    getSubType() {
        const { params } = this.props;

        return _.has(params, 'subtype') ? params.subtype : '';
    }

    searchClients(query) {
        if (this.search_clients_timeout !== null) {
            clearTimeout(this.search_clients_timeout);
        }

        if (query.length >= 3) {
            this.search_clients_timeout = setTimeout(() => this.runSearchClients(query), 500);
        }

        this.onChangeClient('name', query);
    }

    searchClientsEmail(query) {
        if (this.search_clients_email_timeout !== null) {
            clearTimeout(this.search_clients_email_timeout);
        }

        if (query.length >= 3) {
            this.search_clients_email_timeout = setTimeout(() => this.runSearchClientsEmail(query), 500);
        }

        this.onChangeClient('email', query);
    }

    runSearchClients(query) {
        const { params } = this.props;

        request(`/mtokna/searchClients/${params.user_eshop_id}/${query}`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_clients: status === 'success' ? data : [] });
        });
    }

    runSearchClientsEmail(query) {
        const { params } = this.props;

        request(`/mtokna/searchClients/${params.user_eshop_id}/${query}/email`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_clients_email: status === 'success' ? data : [] });
        });
    }

    searchProducts(key, query, user_eshop_id) {
        if (this.search_products_timeout !== null) {
            clearTimeout(this.search_products_timeout);
        }

        if (query.length >= 3) {
            this.search_products_timeout = setTimeout(() => this.runSearchProducts(query, user_eshop_id), 500);
        }

        this.onChangeProduct(key, 'name', query);
    }

    searchProductsNumber(key, query, user_eshop_id) {
        if (this.search_products_number_timeout !== null) {
            clearTimeout(this.search_products_number_timeout);
        }

        if (query.length >= 3) {
            this.search_products_number_timeout = setTimeout(() => this.runSearchProductsNumber(query, user_eshop_id), 500);
        }

        this.onChangeProduct(key, 'number', query);
    }

    runSearchProducts(query, user_eshop_id) {
        const { params } = this.props;

        if (params.type === 'vo') {
            user_eshop_id = params.user_eshop_id;
        }

        request(`/mtokna/searchProducts/${user_eshop_id}/${query}`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_products: status === 'success' ? data : [] });
        });
    }

    runSearchProductsNumber(query, user_eshop_id) {
        const { params } = this.props;

        if (params.type === 'vo') {
            user_eshop_id = params.user_eshop_id;
        }

        request(`/mtokna/searchProducts/${user_eshop_id}/${query}/number`).then(response => {
            const { status, data } = response.data;

            this.setState({ searched_products_number: status === 'success' ? data : [] });
        });
    }

    showDiscount(key) {
        this.showLightbox('discount', { key, value: '' });
    }

    closeDiscount() {
        this.closeLightbox('discount');
    }

    saveDiscount() {
        const { lightbox } = this.state;

        this.closeDiscount();

        this.onChangeProduct(lightbox.discount.key, 'discount', lightbox.discount.value);
    }

    onChangeDiscount(value) {
        const { lightbox } = this.state;

        this.setState({ lightbox: { ...lightbox, discount: { ...lightbox.discount, value } } });
    }

    showOrders() {
        const { params } = this.props;
        const { settings, lightbox } = this.state;

        const lightbox_data = {
            loading: true,
            items: [],
            selected: {},
            email: settings.client.email,
        };

        this.showLightbox('orders', lightbox_data);

        request(`/mtokna/sellerOrders/${params.type === 'vo' ? 'vo' : 'all'}/${settings.client.email}`).then(response => {
            const { status, data } = response.data;

            if (status === 'error') {
                this.closeOrders();
                return;
            }

            this.setState({ lightbox: { ...lightbox, orders: { ...lightbox_data, items: data, loading: false } } });
        });
    }

    closeOrders() {
        this.closeLightbox('orders');
    }

    saveOrders() {
        const { params } = this.props;
        const { lightbox, settings } = this.state;

        this.closeOrders();

        let products = [];
        let ids = [];

        _.each(lightbox.orders.items, ({ id, order_id, data, eshop, type, state }) => {
            if (!_.has(lightbox.orders.selected, id.toString())) {
                return;
            }

            if (type === 'customer') {
                const product_data = { ...this.getProduct(), ...{
                    name: `Tovar - zákaznícka objednávka č. ${data.number}`,
                        unit_price: '0',
                        tax: '23',
                        total_price: '0',
                        total_price_sum: '0',
                        autocomplete: false,
                        original_total_price: '0',
                    } };

                products = [ ...products, product_data ];

                _.each(data.products, product => {
                    const product_data = { ...this.getProduct(), ...{
                            number: product.number,
                            quantity: product.quantity.toString(),
                            supplier: _.has(product.meta_data, 'supplier') ? product.meta_data.supplier : '',
                            supplier_name: _.has(product.meta_data, 'supplier_name') ? product.meta_data.supplier_name : '',
                            name: product.name,
                            unit_price: product.unit_price.toString(),
                            tax: product.tax.toString(),
                            total_price: product.total_price.toString(),
                            total_price_sum: formatAmount(toNumber(product.total_price) * toNumber(product.quantity), '').replace(' ', ''),
                            seller_manual_order_id: id,
                            autocomplete: false,
                            original_total_price: product.total_price.toString(),
                        } };

                    products = [ ...products, product_data ];
                });

                return;
            } else if (type === 'window') {
                const product_data = { ...this.getProduct(), ...{
                        name: `Žalúzie a sieťky - objednávka č. ${data.number}`,
                        unit_price: '0',
                        tax: '23',
                        total_price: '0',
                        total_price_sum: '0',
                        autocomplete: false,
                        original_total_price: '0',
                    } };

                products = [ ...products, product_data ];

                _.each(data.products, product => {
                    let name = product.name;

                    if (name === 'Žalúzia celotieniaca') {
                        name = `${name} - design: ${product.meta_data.window_blind_design}, šírka: ${product.meta_data.window_blind_width}mm, výška: ${product.meta_data.window_blind_length}mm`;
                        name = `${name}, ovl: ${product.meta_data.window_blind_control}, kat. lamela: ${product.meta_data.window_blind_category}, far. lamela: ${product.meta_data.window_blind_color1}`;
                        name = `${name}, far. rebrík: ${product.meta_data.window_blind_color2}, far. nosník: ${product.meta_data.window_blind_color3}`;
                    } else if (name === 'ISSO sieť 25 x 10 s falcom') {
                        name = `${name} - šírka: ${product.meta_data.window_net_width}mm, výška: ${product.meta_data.window_net_length}mm`;
                        name = `${name}, hr. falca: ${product.meta_data.window_net_height}, far. rámu: ${product.meta_data.window_net_color}, sieťovina: ${product.meta_data.window_net_type}`;
                    }

                    const product_data = { ...this.getProduct(), ...{
                        quantity: product.quantity.toString(),
                        name,
                        unit_price: product.unit_price.toString(),
                        tax: product.tax.toString(),
                        total_price: product.total_price.toString(),
                        total_price_sum: formatAmount(toNumber(product.total_price) * toNumber(product.quantity), '').replace(' ', ''),
                        window_order_id: id,
                        autocomplete: false,
                        original_total_price: product.total_price.toString(),
                    } };

                    products = [ ...products, product_data ];
                });

                return;
            } else if (type === 'complaint') {
                const tax = 23;
                const total_price = '0';
                const unit_price = '0';

                const product_data = { ...this.getProduct(), ...{
                    name: `Vybavenie reklamácie č. ${data.number}`,
                    unit_price,
                    tax: tax.toString(),
                    total_price,
                    total_price_sum: total_price,
                    autocomplete: false,
                    original_total_price: total_price,
                    complaint_order_id: id
                } };

                products = [ ...products, product_data ];

                return;
            }

            const product_key = params.type === 'vo' ? 'owner_order_id' : 'seller_order_id';
            let koef = params.type === 'vo' ? 0.1 : -0.1;

            if (state === 'Nová') {
                koef = params.type === 'vo' ? 0.05 : -0.05;
            }

            if (eshop === 'VSETKO PRE OKNA') {
                koef = params.type === 'vo' ? 0.2 : -0.2;

                if (state === 'Nová') {
                    koef = params.type === 'vo' ? 0.1 : -0.1;
                }
            }

            const tax = 23;
            const total_price = formatAmount((toNumber(data.amount) - toNumber(data.delivery_type_amount) - toNumber(data.payment_type_amount)) * koef, '').replace(' ', '');
            const unit_price = formatAmount(toNumber(total_price) * (100 / (100 + tax)), '').replace(' ', '');

            const product_data = { ...this.getProduct(), ...{
                    name: `Provízia za vybavenie objednávky číslo ${data.number}, eshop: ${eshop}`,
                    unit_price,
                    tax: tax.toString(),
                    total_price,
                    total_price_sum: total_price,
                    [product_key]: id,
                    autocomplete: false,
                    original_total_price: total_price,
                } };

            products = [ ...products, product_data ];

            ids = [ ...ids, order_id ];
        });

        if (!_.isEmpty(ids)) {
            request(`/mtokna/lockOrders/${ids.join(',')}`).then(response => {});
        }

        if (!_.isEmpty(products)) {
            this.setState({ settings: { ...settings, products: [ ...settings.products, ...products ] } });
        }
    }

    addOrder(id, data) {
        const { lightbox } = this.state;

        const selected = { ...lightbox.orders.selected, [id.toString()]: data };

        this.setState({ lightbox: { ...lightbox, orders: { ...lightbox.orders, selected } } });
    }

    removeOrder(id) {
        const { lightbox } = this.state;

        const selected = _.omit(lightbox.orders.selected, [id.toString()]);

        this.setState({ lightbox: { ...lightbox, orders: { ...lightbox.orders, selected } } });
    }

    /**
     * Event po zmene rovnakej fakturacnej a dodacej adresy.
     *
     * @param {boolean} value
     */
    onChangeSameDelivery(value) {
        const { settings } = this.state;
        let { client } = settings;

        if (value) {
            // Chceme rovnaku, nasetujeme
            client = {
                ...client,
                delivery_address: client.address,
                delivery_city: client.city,
                delivery_zip: client.zip,
                delivery_country: client.country,
                delivery_phone: client.phone,
            };
        } else {
            // Nechceme rovnaku, vycistime
            client = {
                ...client,
                delivery_address: '',
                delivery_city: '',
                delivery_zip: '',
                delivery_phone: '',
            };
        }

        this.setState({ settings: { ...settings, client }, sameDelivery: value });
    }

    isPacketaPoints(name) {
        return name.toLowerCase().indexOf('packet') !== -1
            && name.toLowerCase().indexOf('miest') !== -1;
    }

    isGlsPoints(name) {
        return name.toLowerCase().indexOf('gls') !== -1
            && name.toLowerCase().indexOf('miest') !== -1;
    }

    isCustomPoints(name) {
        return name.toLowerCase().indexOf('odber') !== -1
            && name.toLowerCase().indexOf('predajcov') !== -1;
    }

    getPickupPoints(data, settings, name = '') {
        if (this.isPacketaPoints(name)) {
            let pickup_point = null;

            _.each(data.pickup_points, point => {
                if (point.id.toString() === settings.delivery_type_pickup_point.toString()) {
                    pickup_point = point;
                }
            });

            return {
                pickup_points: data.pickup_points,
                pickup_point,
            };
        } else if (this.isGlsPoints(name)) {
            let pickup_point = null;

            _.each(data.pickup_points_gls, point => {
                if (point.id.toString() === settings.delivery_type_pickup_point.toString()) {
                    pickup_point = point;
                }
            });

            return {
                pickup_points: data.pickup_points_gls,
                pickup_point,
            };
        } else if (this.isCustomPoints(name)) {
            let pickup_point = null;

            _.each(data.pickup_points_custom, point => {
                if (point.id.toString() === settings.delivery_type_pickup_point.toString()) {
                    pickup_point = point;
                }
            });

            return {
                pickup_points: data.pickup_points_custom,
                pickup_point,
            };
        }

        return {
            pickup_points: [],
            pickup_point: null,
        };
    }

    /**
     * Event po zmene klienta.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeComplaint(type, value) {
        const { settings } = this.state;

        let complaint = { ...settings.complaint, [type]: value };

        this.setState({ settings: { ...settings, complaint } });
    }

    /**
     * Event po zmene klienta.
     *
     * @param {string} type
     * @param {string} value
     */
    onChangeClient(type, value) {
        const { settings, sameDelivery } = this.state;

        let client = { ...settings.client, [type]: value };

        if (sameDelivery) {
            // Dodacia je rovnaka
            switch (type) {
                case 'address':
                    // Menime adresu
                    client = { ...client, delivery_address: value };
                    break;

                case 'city':
                    // Menime mesto
                    client = { ...client, delivery_city: value };
                    break;

                case 'zip':
                    // Menime PSC
                    client = { ...client, delivery_zip: value };
                    break;

                case 'country':
                    // Menime krajinu
                    client = { ...client, delivery_country: value };
                    break;

                case 'phone':
                    // Menime telefon
                    client = { ...client, delivery_phone: value };
                    break;

                default:
                    break;
            }
        }

        this.setState({ settings: { ...settings, client } });
    }

    getTotalWindowBlindPrice(product) {
        const { data } = this.state;

        let area = (toNumber(product.window_blind_width) / 1000) * (toNumber(product.window_blind_length) / 1000);
        area = Math.max(area, toNumber(data.window_settings.blinds.data.min_area));

        let koef = toNumber(data.window_settings.blinds.data.isso);

        if (product.window_blind_category === 'SK. *') {
            koef = toNumber(data.window_settings.blinds.data.isso1);
        } else if (product.window_blind_category === 'SK. **') {
            koef = toNumber(data.window_settings.blinds.data.isso2);
        } else if (product.window_blind_category === 'SK. ***') {
            koef = toNumber(data.window_settings.blinds.data.isso3);
        } else if (product.window_blind_category === '16 MM') {
            koef = toNumber(data.window_settings.blinds.data.isso16mm);
        }

        let price = area * koef;

        if (_.includes(['415', '402', '431', '302', '456'], product.window_blind_color2)) {
            price += toNumber(data.window_settings.blinds.data.color5);
        }

        if (_.includes(['402', '456', '8003', '8004', '7016'], product.window_blind_color3)) {
            price += toNumber(data.window_settings.blinds.data.color1);
        } else if (_.includes(['R8001', 'R8200', 'R8300', 'R8400', 'R8500', 'R8600'], product.window_blind_color3)) {
            price += toNumber(data.window_settings.blinds.data.color2);
        } else if (_.includes(['DD6783', 'DD6784', 'DD6785', 'DD6792', 'DD6793', 'DD6795'], product.window_blind_color3)) {
            price += toNumber(data.window_settings.blinds.data.color3);
        }

        return price;
    }

    getTotalWindowNetPrice(product) {
        const { data } = this.state;

        let area = (toNumber(product.window_net_width) / 1000) * (toNumber(product.window_net_length) / 1000);
        area = Math.max(area, toNumber(data.window_settings.blinds.data.min_area));

        let koef = toNumber(data.window_settings.nets.data.base);
        let price = area * koef;

        if (_.includes(['IMIT.DREVA'], product.window_net_color)) {
            price += (area * toNumber(data.window_settings.nets.data.charge1));
        } else if (_.includes(['RAL'], product.window_net_color)) {
            price += toNumber(data.window_settings.nets.data.charge2);
        }

        if (_.includes(['PET'], product.window_net_type)) {
            price += (area * toNumber(data.window_settings.nets.data.charge3));
        } else if (_.includes(['ALU'], product.window_net_type)) {
            price += (area * toNumber(data.window_settings.nets.data.charge4));
        } else if (_.includes(['BLACK'], product.window_net_type)) {
            price += (area * toNumber(data.window_settings.nets.data.charge5));
        }

        return price;
    }

    /**
     * Event po zmene klienta.
     *
     * @param {number} key
     * @param {string} type
     * @param {string} value
     */
    onChangeProduct(key, type, value, blur = false) {
        const { settings, data } = this.state;

        const products = _.map(settings.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                if (type === 'name') {
                    return { ...product, name: value };
                } else if (type === 'centre') {
                    return { ...product, centre: value };
                } else if (type === 'number') {
                    return { ...product, number: value };
                } else if (type === 'supplier') {
                    return { ...product, supplier: value, supplier_name: data.user_eshops[toNumber(value)] };
                } else if (type === 'complaint_type') {
                    return { ...product, complaint_type: value };
                } else if (type === 'complaint_comment') {
                    return { ...product, complaint_comment: value };
                } else if (type === 'window_blind_design') {
                    return { ...product, window_blind_design: value };
                } else if (type === 'window_blind_width') {
                    value = value.replace(/[^0-9]/g, '');

                    if (blur && toNumber(value) < toNumber(data.window_settings.blinds.data.min_width)) {
                        value = data.window_settings.blinds.data.min_width;
                    }

                    if (blur && toNumber(value) > toNumber(data.window_settings.blinds.data.max_width)) {
                        value = data.window_settings.blinds.data.max_width;
                    }

                    let tax = product.tax;
                    let total_price = this.getTotalWindowBlindPrice({ ...product, window_blind_width: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_blind_width: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_blind_length') {
                    value = value.replace(/[^0-9]/g, '');

                    if (blur && toNumber(value) < toNumber(data.window_settings.blinds.data.min_length)) {
                        value = data.window_settings.blinds.data.min_length;
                    }

                    if (blur && toNumber(value) > toNumber(data.window_settings.blinds.data.max_length)) {
                        value = data.window_settings.blinds.data.max_length;
                    }

                    let tax = product.tax;
                    let total_price = this.getTotalWindowBlindPrice({ ...product, window_blind_length: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_blind_length: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_blind_control') {
                    return { ...product, window_blind_control: value };
                } else if (type === 'window_blind_category') {
                    let tax = product.tax;
                    let total_price = this.getTotalWindowBlindPrice({ ...product, window_blind_category: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_blind_category: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_blind_color1') {
                    let tax = product.tax;
                    let total_price = this.getTotalWindowBlindPrice({ ...product, window_blind_color1: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_blind_color1: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_blind_color2') {
                    let tax = product.tax;
                    let total_price = this.getTotalWindowBlindPrice({ ...product, window_blind_color2: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_blind_color2: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_blind_color3') {
                    let tax = product.tax;
                    let total_price = this.getTotalWindowBlindPrice({ ...product, window_blind_color3: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_blind_color3: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_net_width') {
                    value = value.replace(/[^0-9]/g, '');

                    let tax = product.tax;
                    let total_price = this.getTotalWindowNetPrice({ ...product, window_net_width: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_net_width: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_net_length') {
                    value = value.replace(/[^0-9]/g, '');

                    let tax = product.tax;
                    let total_price = this.getTotalWindowNetPrice({ ...product, window_net_length: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_net_length: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_net_height') {
                    return { ...product, window_net_height: value };
                } else if (type === 'window_net_color') {
                    let tax = product.tax;
                    let total_price = this.getTotalWindowNetPrice({ ...product, window_net_color: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_net_color: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_net_type') {
                    let tax = product.tax;
                    let total_price = this.getTotalWindowNetPrice({ ...product, window_net_type: value });
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price) * toNumber(product.quantity);

                    return { ...product, window_net_type: value, unit_price, total_price, total_price_sum, original_total_price };
                } else if (type === 'window_discount') {
                    value = value.replace(/[^0-9]/g, '');

                    let defaultProduct = this.getProduct();
                    let total_price = 0;

                    _.each(settings.products, (product) => {
                        if (product.name !== 'Zľava (registrovaný zákazník)') {
                            const quantity = toNumber(product.quantity);
                            total_price += toNumber(product.total_price) * quantity;
                        }
                    });

                    total_price = total_price * (value / 100) * -1;

                    let tax = defaultProduct.tax;
                    let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                    let original_total_price = total_price;
                    let total_price_sum = toNumber(total_price);

                    return { ...product, window_discount: value, unit_price, total_price, total_price_sum, original_total_price };
                }

                let discount = product.discount;
                let quantity = product.quantity;
                let unit_price = product.unit_price;
                let tax = product.tax;
                let total_price = product.total_price;
                let total_price_sum = product.total_price_sum;
                let original_total_price = product.original_total_price;
                let discounted = 0;

                value = value.replace(/[^0-9.,-]/g, '');

                switch (type) {
                    default:
                    case 'quantity':
                        // Menime pocet
                        quantity = value;
                        total_price_sum = toNumber(total_price) * toNumber(quantity);
                        break;

                    case 'unit_price':
                        // Menime jednotkovu cenu
                        unit_price = value;
                        total_price = formatAmount(toNumber(unit_price) * (toNumber(tax) / 100 + 1), '').replace(' ', '');
                        original_total_price = total_price;

                        if (!blur) {
                            this.changed = true;
                        }

                        if (blur && toNumber(discount) > 0 && this.changed) {
                            this.changed = false;

                            discounted = toNumber(original_total_price) - (toNumber(original_total_price) * (discount / 100));
                            total_price = formatAmount(discounted, '').replace(' ', '');
                            unit_price = formatAmount(toNumber(discounted) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                        }

                        total_price_sum = toNumber(total_price) * toNumber(quantity);

                        break;

                    case 'tax':
                        // Menime DPH
                        tax = value;
                        total_price = formatAmount(toNumber(unit_price) * (toNumber(tax) / 100 + 1), '').replace(' ', '');
                        original_total_price = total_price;
                        total_price_sum = toNumber(total_price) * toNumber(quantity);
                        break;

                    case 'total_price':
                        // Menime celkovu cenu
                        total_price = value;
                        unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                        original_total_price = total_price;

                        if (!blur) {
                            this.changed = true;
                        }

                        if (blur && toNumber(discount) > 0 && this.changed) {
                            this.changed = false;

                            discounted = toNumber(original_total_price) - (toNumber(original_total_price) * (discount / 100));
                            total_price = formatAmount(discounted, '').replace(' ', '');
                            unit_price = formatAmount(toNumber(discounted) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                        }

                        total_price_sum = toNumber(total_price) * toNumber(quantity);
                        break;

                    case 'discount':
                        discount = value;

                        discounted = toNumber(original_total_price) - (toNumber(original_total_price) * (discount / 100));
                        total_price = formatAmount(discounted, '').replace(' ', '');
                        unit_price = formatAmount(toNumber(discounted) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
                        total_price_sum = toNumber(total_price) * toNumber(quantity);
                        break;
                }

                product = { ...product, quantity, unit_price, tax, total_price, total_price_sum, original_total_price, discount };
            }

            return product;
        });

        this.setState({ settings: { ...settings, products }});
    }

    addWindowDiscount(type) {
        const { settings } = this.state;

        const { window_settings } = this.state.data;

        const defaultProduct = this.getProduct();

        let total_price = 0;

        _.each(settings.products, (product) => {
            const quantity = toNumber(product.quantity);
            total_price += toNumber(product.total_price) * quantity;
        });

        total_price = total_price * (window_settings[type].data.discount / 100) * -1;

        let tax = defaultProduct.tax;
        let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
        let original_total_price = total_price;
        let total_price_sum = toNumber(total_price) * toNumber(defaultProduct.quantity);

        const product_data = { ...defaultProduct, ...{
            name: 'Zľava (registrovaný zákazník)',
            window_discount: window_settings[type].data.discount,
            total_price,
            unit_price,
            original_total_price,
            total_price_sum,
        } };

        this.setState({ settings: { ...settings, products: [ ...settings.products, product_data ] } });
    }

    addWindowFee(type, key, name) {
        const { settings } = this.state;

        const { window_settings } = this.state.data;

        const defaultProduct = this.getProduct();

        let tax = defaultProduct.tax;
        let total_price = window_settings[type].data[key];
        let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
        let original_total_price = total_price;
        let total_price_sum = toNumber(total_price) * toNumber(defaultProduct.quantity);

        const product_data = { ...defaultProduct, ...{ name, total_price, unit_price, original_total_price, total_price_sum } };

        this.setState({ settings: { ...settings, products: [ ...settings.products, product_data ] } });
    }

    /**
     * Pridanie produktu.
     *
     * @param {object} data
     */
    addProduct(data = {}) {
        const { settings } = this.state;

        let last = null;

        if (!_.isEmpty(settings.products)) {
            last = _.last(settings.products);

            data = { ...data, centre: last.centre, discount: last.discount };
        }

        const subtype = this.getSubType();
        const defaultProduct = this.getProduct();

        if (subtype === 'window-blind') {
            const { window_settings } = this.state.data;

            data = { ...data, ...{
                name: 'Žalúzia celotieniaca',
                window_blind_design: 'Hranatý',
                window_blind_width: toNumber(window_settings.blinds.data.min_width),
                window_blind_length: toNumber(window_settings.blinds.data.min_length),
                window_blind_control: 'Ľ',
                window_blind_category: 'ŠTAND.',
                window_blind_color1: 'BIELA',
                window_blind_color2: 'BIELA',
                window_blind_color3: 'BIELY',
            } };

            let tax = defaultProduct.tax;
            let total_price = this.getTotalWindowBlindPrice(data);
            let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
            let original_total_price = total_price;
            let total_price_sum = toNumber(total_price) * toNumber(defaultProduct.quantity);

            data = { ...data, unit_price, total_price, total_price_sum, original_total_price };
        } else if (subtype === 'window-net') {
            const { window_settings } = this.state.data;

            data = { ...data, ...{
                name: 'ISSO sieť 25 x 10 s falcom',
                window_net_width: 1000,
                window_net_length: 1000,
                window_net_height: '10',
                window_net_color: 'BIELA',
                window_net_type: 'KLASIK',
            } };

            let tax = defaultProduct.tax;
            let total_price = this.getTotalWindowNetPrice(data);
            let unit_price = formatAmount(toNumber(total_price) * (100 / (100 + toNumber(tax))), '').replace(' ', '');
            let original_total_price = total_price;
            let total_price_sum = toNumber(total_price) * toNumber(defaultProduct.quantity);

            data = { ...data, unit_price, total_price, total_price_sum, original_total_price };
        }

        const product_data = { ...defaultProduct, ...data };

        this.setState({ settings: { ...settings, products: [ ...settings.products, product_data ] } });
    }

    /**
     * Zmazanie produktu.
     *
     * @param {number} key
     */
    deleteProduct(key) {
        const { settings } = this.state;
        let { products } = settings;

        products = _.remove(products, (item, k) => {
            return k !== key;
        });

        this.setState({ settings: { ...settings, products }});
    }

    getDefaultSupplierId(data) {
        const { params } = this.props;

        if (_.has(data.user_eshops, params.user_eshop_id)) {
            return toNumber(params.user_eshop_id);
        }

        return toNumber(_.keys(data.user_eshops)[0]);
    }

    /**
     * Vratime data noveho produktu.
     *
     * @return {Object}
     */
    getProduct() {
        const { data } = this.state;

        const default_supplier_id = this.getDefaultSupplierId(data);

        return {
            id: '',
            name: '',
            number: '',
            quantity: 1,
            unit_price: '',
            tax: '23',
            total_price: '',
            total_price_sum: '0',
            centre: '',
            seller_order_id: '',
            owner_order_id: '',
            complaint_order_id: '',
            seller_manual_order_id: '',
            discount: '',
            autocomplete: true,
            original_total_price: '',
            supplier: default_supplier_id,
            supplier_name: data.user_eshops[default_supplier_id],
            complaint_type: '',
            complaint_comment: '',
            window_blind_design: '',
            window_blind_width: '',
            window_blind_length: '',
            window_blind_control: '',
            window_blind_category: '',
            window_blind_color1: '',
            window_blind_color2: '',
            window_blind_color3: '',
            window_net_width: '',
            window_net_length: '',
            window_net_height: '',
            window_net_color: '',
            window_net_type: '',
            window_discount: '',
        };
    }

    /**
     * Event po vybrani produktu.
     *
     * @param {number} key
     * @param {Object} product_data
     */
    onSelectProduct(key, product_data) {
        const { settings } = this.state;

        let updateDiscount = 0;

        const products = _.map(settings.products, (product, k) => {
            if (k === key) {
                // Editujeme produkt
                if (toNumber(product.discount) > 0) {
                    updateDiscount = product.discount;
                }

                product = {
                    ...product,
                    id: product_data !== null ? product_data.id : '',
                    name: product_data !== null ? product_data.name : '',
                    number: product_data !== null ? product_data.number : '',
                    unit_price: product_data !== null ? formatAmount(toNumber(product_data.unit_price), '', 2).replace(' ', '') : '',
                    total_price: product_data !== null ? formatAmount(toNumber(product_data.total_price), '', 2).replace(' ', '') : '',
                    total_price_sum: product_data !== null ? formatAmount(toNumber(product_data.total_price), '', 2).replace(' ', '') : '0',
                    tax: product_data !== null ? formatAmount(toNumber(product_data.tax), '', 0).replace(' ', ''): '',
                    original_total_price: product_data !== null ? formatAmount(toNumber(product_data.total_price), '', 2).replace(' ', '') : '',
                };
            }

            return product;
        });

        this.setState({ settings: { ...settings, products }});

        if (toNumber(updateDiscount) > 0) {
            setTimeout(() => {
                this.onChangeProduct(key, 'discount', updateDiscount);
            }, 500);
        }
    }

    /**
     * Event po vybrani klienta.
     *
     * @param {Object} client_data
     */
    onSelectClient(client_data) {
        const { settings, sameDelivery, showCompany } = this.state;

        this.setState({ settings: { ...settings, client: {
                    id: client_data !== null ? client_data.id : '',
                    name: client_data !== null ? client_data.name : '',
                    ico: client_data !== null ? client_data.ico : '',
                    dic: client_data !== null ? client_data.dic : '',
                    ic_dph: client_data !== null ? client_data.ic_dph : '',
                    email: client_data !== null ? client_data.email : '',
                    phone: client_data !== null ? client_data.phone : '',
                    address: client_data !== null ? client_data.address : '',
                    city: client_data !== null ? client_data.city : '',
                    zip: client_data !== null ? client_data.zip : '',
                    country: client_data !== null ? client_data.country : '',
                    delivery_address: client_data !== null ? client_data.delivery_address : '',
                    delivery_city: client_data !== null ? client_data.delivery_city : '',
                    delivery_zip: client_data !== null ? client_data.delivery_zip : '',
                    delivery_country: client_data !== null ? client_data.delivery_country : '',
                    delivery_phone: client_data !== null ? client_data.delivery_phone : '',
                    delivery_note: client_data !== null ? client_data.delivery_note : '',
                } },
            sameDelivery: client_data !== null ? false : sameDelivery,
            showCompany: client_data !== null && client_data.ico !== '' ? true : showCompany,
        });
    }

    onChangeSetting(name, value) {
        const { data, settings } = this.state;

        let additional = {};
        let additional_state = {};

        switch (name) {
            case 'delivery_type_id':
                additional_state = {
                    pickup_points: [],
                    pickup_point: null,
                };

                _.map(data.eshop_data.delivers, ({ id, name, amount }) => {
                    if (id.toString() === value.toString()) {
                        additional = {
                            delivery_type_name: name,
                            delivery_type_amount: amount.toString(),
                            delivery_type_pickup_point: '',
                        };

                        additional_state = this.getPickupPoints(data, { ...settings, delivery_type_pickup_point: '' }, name);
                    }
                });
                break;

            case 'payment_type_id':
                _.map(data.eshop_data.payments, ({ id, name, amount }) => {
                    if (id.toString() === value.toString()) {
                        additional = {
                            payment_type_name: name,
                            payment_type_amount: amount.toString(),
                        };
                    }
                });
                break;
        }

        this.setState({ settings: { ...settings, [name]: value, ...additional }, ...additional_state });
    }

    onChangeMetaData(name, value) {
        let { settings, meta_data_deleted } = this.state;

        if (_.has(meta_data_deleted, name)) {
            meta_data_deleted = _.omit(meta_data_deleted, [name]);
        }

        this.setState({ settings: {
                ...settings,
                meta_data: { ...settings.meta_data, [name]: value },
                meta_data_modified: { ...settings.meta_data_modified, [name]: value },
                meta_data_deleted
            }});
    }

    onChangeMetaDatas(datas, deleted_datas = null) {
        let { settings, meta_data_deleted } = this.state;

        if (deleted_datas !== null) {
            meta_data_deleted = deleted_datas;
        }

        _.each(_.keys(datas), key => {
            if (_.has(meta_data_deleted, key)) {
                meta_data_deleted = _.omit(meta_data_deleted, [key]);
            }
        });

        this.setState({ settings: {
                ...settings,
                meta_data: { ...settings.meta_data, ...datas },
                meta_data_modified: { ...settings.meta_data_modified, ...datas },
                meta_data_deleted
            }});
    }

    save(replaceSettings = null) {
        const { params, user } = this.props;
        let { settings, data } = this.state;

        const id = params.id !== 'new' ? params.id : '';

        const subtype = this.getSubType();
        const hasEshopAccess = data.has_eshop_access;

        this.setState({ loading: true });

        settings = { ...settings, id };

        if (replaceSettings !== null) {
            settings = replaceSettings;
        }

        request(`/mtokna/addOrder/${params.user_eshop_id}/${subtype}`, { data: settings }, 'POST').then(response => {
            const { status, data } = response.data;

            this.setState({ loading: false });

            if (status === 'error') {
                if (_.has(data, 'error') && data.error === 'number') {
                    if (_.has(data, 'next_number') && this.tries < 3) {
                        this.save({ ...settings, number: data.next_number });
                        this.tries++;
                        return;
                    }

                    this.showSnackbar('error', __('Objednávka s rovnakým číslom už existuje'));
                    return;
                }

                this.showSnackbar('error', __('Nepodarilo sa uložiť objednávku'));
                return;
            }

            this.showSnackbar('success', __('Objednávka bola uložená'));

            if (subtype === 'complaint') {
                setTimeout(() => { window.location = '/complaints' }, 500);
            } else if (subtype === 'window-blind' || subtype === 'window-net') {
                setTimeout(() => { window.location = '/windows' }, 500);
            } else if (subtype === 'customer') {
                setTimeout(() => { window.location = '/customer-orders' }, 500);
            } else if (user.mtokna_type === 'mtokna_seller' && data.has_eshop_access) {
                setTimeout(() => { window.location = '/seller-orders' }, 500);
            } else if (user.mtokna_type === 'mtokna_seller') {
                if (hasEshopAccess) {
                    setTimeout(() => { window.location = '/seller-orders' }, 500);
                } else {
                    setTimeout(() => { window.location = '/manual-seller' }, 500);
                }
            } else {
                setTimeout(() => { window.location = '/manual' }, 500);
            }
        });
    }

    onChangePickupPoint(pickup_point) {
        const { settings } = this.state;

        if (pickup_point === null) {
            this.setState({
                pickup_point,
                settings: { ...settings, delivery_type_pickup_point: '' },
            });
            return;
        }

        this.setState({
            pickup_point: pickup_point,
            settings: { ...settings, delivery_type_pickup_point: pickup_point.id },
        });
    }

    /**
     * Rendrovanie.
     *
     * @returns {JSX.Element}
     */
    render() {
        const {
            loading,
            data,
            id,
            settings,
            pickup_points,
            pickup_point,
            sameDelivery,
            showCompany,
            lightbox,
            searched_clients,
            searched_products,
            searched_clients_email,
            searched_products_number,
        } = this.state;
        const { user, params, changeMtoknaComplaintData, changeMtoknaOrderData } = this.props;

        if (_.isEmpty(data)) {
            // Data nie su nacitane
            return this.renderLoading();
        }

        const isEdit = id !== '';

        // Vytiahneme zoznam stavov
        const states = _.reduce(data.eshop_data.states, (result, { id, name }) => ({ ...result, [id]: name }), {});

        // Vytiahneme zoznam platieb
        let paymentTypes = _.reduce(data.eshop_data.payments, (result, { id, name }) => ({ ...result, [id]: name }), {});

        if (
            settings.delivery_type_id.toString() === '28'
            && _.has(paymentTypes, 'bacs')
            && (
                settings.payment_type_id.toString() === ''
                || settings.payment_type_id.toString() === 'bacs'
            )
        ) {
            paymentTypes = { bacs: paymentTypes.bacs };
        }

        // Vytiahneme zoznam dodani
        const deliveryTypes = _.reduce(data.eshop_data.delivers, (result, { id, name }) => ({ ...result, [id]: name }), {});

        const tax = 23;
        let unitPrice = toNumber(settings.delivery_type_amount) * (100 / (100 + tax)) + toNumber(settings.payment_type_amount) * (100 / (100 + tax));
        let totalPrice = toNumber(settings.delivery_type_amount) + toNumber(settings.payment_type_amount);

        const isSeller = user.mtokna_type === 'mtokna_seller' && !data.has_eshop_access;
        let isSellerManual = user.mtokna_type === 'mtokna_seller' && data.has_eshop_access;

        let allowEdit = true;

        if (
            isSeller
            && (settings.state_id !== 'pending' || data.locked)
        ) {
            allowEdit = false;
        }

        if (user.mtokna_moderator && settings.state_id !== 'pending') {
            allowEdit = false;
        }

        if (isSeller) {
            paymentTypes = _.omit(paymentTypes, ['offline_gateway']);
        }

        const subtype = this.getSubType();

        if ((subtype === 'window-blind' || subtype === 'window-net' || isSellerManual) && settings.state_id !== 'Prijatá' && user.email !== 'zeleziarstvo@mt-okna.sk') {
            allowEdit = false;
        }

        if (subtype === 'customer' && user.email === 'zeleziarstvo@mt-okna.sk') {
            isSellerManual = false;
        }

        let productNames = [];

        const allowSearchClients = true;

        return (
            <div className="mtokna_create_order">
                <div className="mtokna_create_order__header">
                    <div className="mtokna_create_order__header__left">
                        <div className="mtokna_create_order__header__left__title">
                            {isEdit
                                ? (subtype === 'complaint' ? `${__('Reklamácia č.')} ${settings.number}` : `${__('Objednávka č.')} ${settings.number}`)
                                : (subtype === 'complaint' ? __('Nová reklamácia') : __('Nová objednávka'))}
                        </div>
                    </div>
                </div>
                <div className="mtokna_create_order__content">
                    {!isSeller ? <div className="mtokna_create_order__content__sections">
                        <div className="mtokna_create_order__content__sections__section">
                            {(subtype !== 'window-blind' && subtype !== 'window-net') || !isSeller ? <Input
                                label={__('Číslo objednávky')}
                                value={settings.number}
                                onChange={value => this.onChangeSetting('number', value)}
                                required
                            /> : null}
                            {!isSellerManual && subtype !== 'complaint' ? <Select
                                label={__('Stav')}
                                value={settings.state_id}
                                options={states}
                                onChange={value => this.onChangeSetting('state_id', value)}
                                allowEmpty={false}
                            /> : null}
                            {!isSellerManual ? <Select
                                label={__('Mena')}
                                value={settings.currency}
                                options={CURRENCIES}
                                onChange={value => this.onChangeSetting('currency', value)}
                                allowEmpty={false}
                            /> : null}
                            {!isSellerManual ? <Select
                                label={__('Dodanie')}
                                value={settings.delivery_type_id}
                                options={deliveryTypes}
                                onChange={value => this.onChangeSetting('delivery_type_id', value)}
                                allowEmpty={false}
                            /> : null}
                            {!isSellerManual ? <Input
                                label={__('Dodanie cena')}
                                value={settings.delivery_type_amount}
                                onChange={value => this.onChangeSetting('delivery_type_amount', value)}
                            /> : null}
                            {!_.isEmpty(pickup_points) && !isSellerManual ? <Input
                                label={__('Odberné miesto')}
                                content={<Autocomplete
                                    options={pickup_points}
                                    getOptionLabel={option => option.name}
                                    onChange={(event, value) => this.onChangePickupPoint(value !== null ? value : null)}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať názov...')}
                                        variant="outlined"
                                    />}
                                    noOptionsText={__('Nenašlo sa žiadne odberné miesto')}
                                    clearText={__('Zrušiť')}
                                    value={pickup_point}
                                />}
                            /> : null}
                            {!isSellerManual ? <div className="mtokna_create_order__content__sections__section__delimiter" /> : null}
                            {!isSellerManual ? <Select
                                label={__('Platba')}
                                value={settings.payment_type_id}
                                options={paymentTypes}
                                onChange={value => this.onChangeSetting('payment_type_id', value)}
                                allowEmpty={false}
                            /> : null}
                            {!isSellerManual ? <Input
                                label={__('Platba cena')}
                                value={settings.payment_type_amount}
                                onChange={value => this.onChangeSetting('payment_type_amount', value)}
                            /> : null}
                            {subtype === 'complaint' && !isSeller ? <Input
                                label={__('Číslo faktúry')}
                                value={settings.complaint.invoice_number}
                                onChange={value => this.onChangeComplaint('invoice_number', value)}
                            /> : null}
                            {subtype === 'complaint' ? <Input
                                label={__('Popis')}
                                value={settings.complaint.text}
                                onChange={value => this.onChangeComplaint('text', value)}
                            /> : null}
                        </div>
                        <div className="mtokna_create_order__content__sections__section">
                            {allowSearchClients ? <Input
                                label={__('Meno a priezvisko (názov firmy)')}
                                content={<Autocomplete
                                    options={searched_clients}
                                    getOptionLabel={option => `${option.name} (${option.email})`}
                                    onChange={(event, value) => this.onSelectClient(value)}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať meno...')}
                                        variant="outlined"
                                        onChange={event => this.searchClients(event.target.value)}
                                    />}
                                    defaultValue={!_.isEmpty(settings.client.searched) ? settings.client.searched : null}
                                    noOptionsText={__('Nenašiel sa žiadny zákazník')}
                                    clearText={__('Zrušiť')}
                                    inputValue={settings.client.name}
                                    freeSolo
                                />}
                            /> : <Input
                                label={__('Meno a priezvisko (názov firmy)')}
                                value={settings.client.name}
                                onChange={value => this.onChangeClient('name', value)}
                                required
                            />}
                            {allowSearchClients ? <Input
                                label={__('Email')}
                                content={<Autocomplete
                                    options={searched_clients_email}
                                    getOptionLabel={option => option.email}
                                    onChange={(event, value) => this.onSelectClient(value)}
                                    renderInput={(params) => <TextField
                                        { ...params }
                                        placeholder={__('Začnite písať email...')}
                                        variant="outlined"
                                        onChange={event => this.searchClientsEmail(event.target.value)}
                                    />}
                                    defaultValue={!_.isEmpty(settings.client.searched) ? settings.client.searched : null}
                                    noOptionsText={__('Nenašiel sa žiadny zákazník')}
                                    clearText={__('Zrušiť')}
                                    inputValue={settings.client.email}
                                    freeSolo
                                />}
                            /> : <Input
                                label={__('Email')}
                                value={settings.client.email}
                                onChange={value => this.onChangeClient('email', value)}
                            />}
                            {!showCompany ? <div style={{ marginBottom: '10px' }}><Checkbox
                                label={__('Firma')}
                                value={showCompany}
                                onChange={checked => this.setState({ showCompany: checked })}
                            /></div> : null}
                            {showCompany ? <div>
                                <Input
                                    label={__('IČO')}
                                    value={settings.client.ico}
                                    onChange={value => this.onChangeClient('ico', value)}
                                />
                                <Input
                                    label={__('DIČ')}
                                    value={settings.client.dic}
                                    onChange={value => this.onChangeClient('dic', value)}
                                />
                                <Input
                                    label={__('IČ DPH')}
                                    value={settings.client.ic_dph}
                                    onChange={value => this.onChangeClient('ic_dph', value)}
                                />
                            </div> : null}
                            <Input
                                label={__('Adresa (fakturácia)')}
                                value={settings.client.address}
                                onChange={value => this.onChangeClient('address', value)}
                            />
                            <Input
                                label={__('Mesto (fakturácia)')}
                                value={settings.client.city}
                                onChange={value => this.onChangeClient('city', value)}
                            />
                            <Input
                                label={__('PSČ (fakturácia)')}
                                value={settings.client.zip}
                                onChange={value => this.onChangeClient('zip', value)}
                            />
                            <Select
                                label={__('Krajina (fakturácia)')}
                                value={settings.client.country}
                                options={data.countries}
                                onChange={value => this.onChangeClient('country', value)}
                                allowEmpty={false}
                            />
                            <Input
                                label={__('Telefón (fakturácia)')}
                                value={settings.client.phone}
                                onChange={value => this.onChangeClient('phone', value)}
                            />
                            <Checkbox
                                label={__('Dodacia adresa rovnaká ako fakturačná')}
                                value={sameDelivery}
                                onChange={checked => this.onChangeSameDelivery(checked)}
                            />
                        </div>
                        <div className="mtokna_create_order__content__sections__section">
                            <Input
                                label={__('Adresa (dodanie)')}
                                value={settings.client.delivery_address}
                                onChange={value => this.onChangeClient('delivery_address', value)}
                                disabled={sameDelivery}
                            />
                            <Input
                                label={__('Mesto (dodanie)')}
                                value={settings.client.delivery_city}
                                onChange={value => this.onChangeClient('delivery_city', value)}
                                disabled={sameDelivery}
                            />
                            <Input
                                label={__('PSČ (dodanie)')}
                                value={settings.client.delivery_zip}
                                onChange={value => this.onChangeClient('delivery_zip', value)}
                                disabled={sameDelivery}
                            />
                            <Select
                                label={__('Krajina (dodanie)')}
                                value={settings.client.delivery_country}
                                options={data.countries}
                                onChange={value => this.onChangeClient('delivery_country', value)}
                                disabled={sameDelivery}
                                allowEmpty={false}
                            />
                           <Input
                                label={__('Telefón (dodanie)')}
                                value={settings.client.delivery_phone}
                                onChange={value => this.onChangeClient('delivery_phone', value)}
                                disabled={sameDelivery}
                            />
                            <Input
                                label={ __('Poznámka (dodanie)')}
                                value={settings.client.delivery_note}
                                onChange={value => this.onChangeClient('delivery_note', value)}
                            />
                            {!isSellerManual ? <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>{__('Meta dáta')}</div> : null}
                            {!isSellerManual ? _.map(settings.meta_data, (value, key) => {
                                if (_.has(settings.meta_data_deleted, key)) {
                                    return null;
                                }

                                return <Input
                                    label={key}
                                    value={value}
                                    onChange={value => this.onChangeMetaData(key, value)}
                                    key={key}
                                />;
                            }) : null}
                        </div>
                    </div> : <div className="mtokna_create_order__content__sections">
                        <div className="mtokna_create_order__content__sections__section">
                            {subtype !== 'window-blind' && subtype !== 'window-net' ? <Select
                                label={__('Platba')}
                                value={settings.payment_type_id}
                                options={paymentTypes}
                                onChange={value => this.onChangeSetting('payment_type_id', value)}
                                allowEmpty={false}
                            /> : null}
                        </div>
                    </div>}
                    <div className="mtokna_create_order__content__products">
                        <div className="mtokna_create_order__content__products__title">{__('Produkty')}</div>
                        {subtype === 'window-blind' ? _.map(settings.products, (product, key) => {
                            const quantity = toNumber(product.quantity);
                            unitPrice += toNumber(product.unit_price) * quantity;
                            totalPrice += toNumber(product.total_price) * quantity;

                            if (_.includes(['Zameranie žalúzií', 'Montáž žalúzií', 'Výjazd k zákazníkovi', 'Zľava (registrovaný zákazník)'], product.name)) {
                                productNames = [ ...productNames, product.name ];

                                return (
                                    <div className="mtokna_create_order__content__products__product window-blind" key={key}>
                                        <Input
                                            label={__('Názov')}
                                            value={product.name}
                                            onChange={() => {}}
                                            disabled
                                        />
                                        {product.name !== 'Zľava (registrovaný zákazník)' ? <Input
                                            label={__('Počet')}
                                            value={product.quantity}
                                            onChange={allowEdit ? value => this.onChangeProduct(key, 'quantity', value) : () => {}}
                                        /> : <Input
                                            label={__('Zľava (%)')}
                                            value={product.window_discount}
                                            onChange={allowEdit ? value => this.onChangeProduct(key, 'window_discount', value) : () => {}}
                                        />}
                                        <Input
                                            label={__('Spolu s DPH')}
                                            value={formatAmount(product.total_price_sum, '')}
                                            onChange={() => {}}
                                            onBlur={() => {}}
                                        />
                                        <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="mtokna_create_order__content__products__product__delete"
                                            disabled={(key === 0 && settings.products.length === 1) || !allowEdit}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                        </Tooltip>
                                    </div>
                                );
                            }

                            return (
                                <div className="mtokna_create_order__content__products__product window-blind" key={key}>
                                    <Input
                                        label={__('Názov')}
                                        value={product.name}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'name', value) : () => {}}
                                    />
                                    <Select
                                        label={__('Design')}
                                        value={product.window_blind_design}
                                        options={{
                                            'Hranatý': 'Hranatý',
                                            'Oblý': 'Oblý',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_blind_design', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Input
                                        label={__('Šírka (mm)')}
                                        value={product.window_blind_width}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'window_blind_width', value) : () => {}}
                                        onBlur={allowEdit ? value => this.onChangeProduct(key, 'window_blind_width', value, true) : () => {}}
                                    />
                                    <Input
                                        label={__('Výška (mm)')}
                                        value={product.window_blind_length}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'window_blind_length', value) : () => {}}
                                        onBlur={allowEdit ? value => this.onChangeProduct(key, 'window_blind_length', value, true) : () => {}}
                                    />
                                    <Input
                                        label={__('Počet')}
                                        value={product.quantity}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'quantity', value) : () => {}}
                                    />
                                    <Select
                                        label={__('Ovl. (Ľ,P)')}
                                        value={product.window_blind_control}
                                        options={{
                                            'Ľ': 'Ľ',
                                            'P': 'P',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_blind_control', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Select
                                        label={__('Kateg. lamely')}
                                        value={product.window_blind_category}
                                        options={{
                                            'ŠTAND.': 'ŠTAND.',
                                            'SK. *': 'SK. *',
                                            'SK. **': 'SK. **',
                                            'SK. ***': 'SK. ***',
                                            '16 MM': '16 MM',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_blind_category', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Select
                                        label={__('Farba lamely')}
                                        value={product.window_blind_color1}
                                        options={{
                                            'BIELA': 'BIELA',
                                            '415': '415',
                                            '302': '302',
                                            '431': '431',
                                            '402': '402',
                                            '456': '456',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_blind_color1', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Select
                                        label={__('Farba rebrík')}
                                        value={product.window_blind_color2}
                                        options={{
                                            'BIELA': 'BIELA',
                                            '415': '415',
                                            '302': '302',
                                            '431': '431',
                                            '402': '402',
                                            '456': '456',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_blind_color2', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Select
                                        label={__('Farba nosník')}
                                        value={product.window_blind_color3}
                                        options={{
                                            'BIELY': 'BIELY',
                                            '402': '402',
                                            '456': '456',
                                            '8003': '8003',
                                            '8004': '8004',
                                            '7016': '7016',
                                            'R8001': 'R8001',
                                            'R8200': 'R8200',
                                            'R8300': 'R8300',
                                            'R8400': 'R8400',
                                            'R8500': 'R8500',
                                            'R8600': 'R8600',
                                            'DD6783': 'DD6783',
                                            'DD6784': 'DD6784',
                                            'DD6785': 'DD6785',
                                            'DD6792': 'DD6792',
                                            'DD6793': 'DD6793',
                                            'DD6795': 'DD6795',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_blind_color3', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Input
                                        label={__('Spolu s DPH')}
                                        value={formatAmount(product.total_price_sum, '')}
                                        onChange={() => {}}
                                        onBlur={() => {}}
                                    />
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="mtokna_create_order__content__products__product__delete"
                                            disabled={(key === 0 && settings.products.length === 1) || !allowEdit}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        }) : null}
                        {subtype === 'window-net' ? _.map(settings.products, (product, key) => {
                            const quantity = toNumber(product.quantity);
                            unitPrice += toNumber(product.unit_price) * quantity;
                            totalPrice += toNumber(product.total_price) * quantity;

                            if (_.includes(['Zameranie sietí proti hmyzu', 'Zľava (registrovaný zákazník)'], product.name)) {
                                productNames = [ ...productNames, product.name ];

                                return (
                                    <div className="mtokna_create_order__content__products__product window-net" key={key}>
                                        <Input
                                            label={__('Názov')}
                                            value={product.name}
                                            onChange={() => {}}
                                            disabled
                                        />
                                        <Input
                                            label={__('Počet')}
                                            value={product.quantity}
                                            onChange={allowEdit && product.name !== 'Zľava (registrovaný zákazník)' ? value => this.onChangeProduct(key, 'quantity', value) : () => {}}
                                        />
                                        <Input
                                            label={__('Spolu s DPH')}
                                            value={formatAmount(product.total_price_sum, '')}
                                            onChange={() => {}}
                                            onBlur={() => {}}
                                        />
                                        <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="mtokna_create_order__content__products__product__delete"
                                            disabled={(key === 0 && settings.products.length === 1) || !allowEdit}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                        </Tooltip>
                                    </div>
                                );
                            }

                            return (
                                <div className="mtokna_create_order__content__products__product window-net" key={key}>
                                    <Input
                                        label={__('Názov')}
                                        value={product.name}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'name', value) : () => {}}
                                    />
                                    <Input
                                        label={__('Šírka (mm)')}
                                        value={product.window_net_width}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'window_net_width', value) : () => {}}
                                        onBlur={allowEdit ? value => this.onChangeProduct(key, 'window_net_width', value, true) : () => {}}
                                    />
                                    <Input
                                        label={__('Výška (mm)')}
                                        value={product.window_net_length}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'window_net_length', value) : () => {}}
                                        onBlur={allowEdit ? value => this.onChangeProduct(key, 'window_net_length', value, true) : () => {}}
                                    />
                                    <Input
                                        label={__('Počet')}
                                        value={product.quantity}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'quantity', value) : () => {}}
                                    />
                                    <Select
                                        label={__('Hr. falca (mm)')}
                                        value={product.window_net_height}
                                        options={{
                                            '10': '10',
                                            '12': '12',
                                            '17': '17',
                                            '20': '20',
                                            '23': '23',
                                            '25': '25',
                                            '30': '30',
                                            '32': '32',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_net_height', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Select
                                        label={__('Farba rámu')}
                                        value={product.window_net_color}
                                        options={{
                                            'BIELA': 'BIELA',
                                            'HNEDÁ': 'HNEDÁ',
                                            'IMIT.DREVA': 'IMIT.DREVA',
                                            'RAL': 'RAL',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_net_color', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Select
                                        label={__('Sieťovina')}
                                        value={product.window_net_type}
                                        options={{
                                            'KLASIK': 'KLASIK',
                                            'PET': 'PET',
                                            'ALU': 'ALU',
                                            'BLACK': 'BLACK',
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'window_net_type', value)}
                                        allowEmpty={false}
                                        disabled={!allowEdit}
                                    />
                                    <Input
                                        label={__('Spolu s DPH')}
                                        value={formatAmount(product.total_price_sum, '')}
                                        onChange={() => {}}
                                        onBlur={() => {}}
                                    />
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="mtokna_create_order__content__products__product__delete"
                                            disabled={(key === 0 && settings.products.length === 1) || !allowEdit}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        }) : null}
                        {subtype !== 'window-blind' && subtype !== 'window-net' ? _.map(settings.products, (product, key) => {
                            const quantity = toNumber(product.quantity);
                            unitPrice += toNumber(product.unit_price) * quantity;
                            totalPrice += toNumber(product.total_price) * quantity;

                            let allowEditPrices = allowEdit;

                            if (isSeller) {
                                allowEditPrices = false;
                            }

                            return (
                                <div className="mtokna_create_order__content__products__product" key={key}>
                                    <Select
                                        label={__('Dodávateľ')}
                                        value={product.supplier}
                                        options={data.user_eshops}
                                        onChange={value => this.onChangeProduct(key, 'supplier', value)}
                                        allowEmpty={false}
                                        disabled={product.seller_order_id !== '' || params.type === 'vo' || !allowEdit}
                                    />
                                    {product.autocomplete && allowEdit ? <Input
                                        label={__('SKU')}
                                        content={<Autocomplete
                                            options={searched_products_number}
                                            getOptionLabel={option => option.number}
                                            onChange={(event, value) => this.onSelectProduct(key, value)}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('SKU')}
                                                variant="outlined"
                                                onChange={event => this.searchProductsNumber(key, event.target.value, product.supplier)}
                                                defaultValue={product.number}
                                            />}
                                            defaultValue={!_.isEmpty(product.searched) ? product.searched : null}
                                            noOptionsText={__('Nenašiel sa žiadny produkt')}
                                            clearText={__('Zrušiť')}
                                            inputValue={product.number}
                                            freeSolo
                                        />}
                                    /> : <Input
                                        label={__('SKU')}
                                        value={product.number}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'number', value) : () => {}}
                                    />}
                                    {product.autocomplete && allowEdit ? <Input
                                        label={__('Názov')}
                                        content={<Autocomplete
                                            options={searched_products}
                                            getOptionLabel={option => `${option.name} / ${option.number}`}
                                            onChange={(event, value) => this.onSelectProduct(key, value)}
                                            renderInput={(params) => <TextField
                                                { ...params }
                                                placeholder={__('Začnite písať názov...')}
                                                variant="outlined"
                                                onChange={event => this.searchProducts(key, event.target.value, product.supplier)}
                                                defaultValue={product.name}
                                            />}
                                            defaultValue={!_.isEmpty(product.searched) ? product.searched : null}
                                            noOptionsText={__('Nenašiel sa žiadny produkt')}
                                            clearText={__('Zrušiť')}
                                            inputValue={product.name}
                                            freeSolo
                                        />}
                                    /> : <Input
                                        label={__('Názov')}
                                        value={product.name}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'name', value) : () => {}}
                                    />}
                                    <Input
                                        label={__('Počet')}
                                        value={product.quantity}
                                        onChange={allowEdit ? value => this.onChangeProduct(key, 'quantity', value) : () => {}}
                                    />
                                    {subtype !== 'complaint' ? <Input
                                        label={__('Cena bez DPH')}
                                        value={product.unit_price}
                                        onChange={allowEditPrices ? value => this.onChangeProduct(key, 'unit_price', value) : () => {}}
                                        onBlur={allowEditPrices ? value => this.onChangeProduct(key, 'unit_price', value, true) : () => {}}
                                    /> : null}
                                    {subtype !== 'complaint' ? <Input
                                        label={__('DPH')}
                                        value={product.tax}
                                        onChange={allowEditPrices ? value => this.onChangeProduct(key, 'tax', value) : () => {}}
                                        onBlur={allowEditPrices ? value => this.onChangeProduct(key, 'tax', value, true) : () => {}}
                                    /> : null}
                                    {subtype !== 'complaint' ? <Input
                                        label={__('Cena s DPH')}
                                        value={product.total_price}
                                        onChange={allowEditPrices ? value => this.onChangeProduct(key, 'total_price', value) : () => {}}
                                        onBlur={allowEditPrices ? value => this.onChangeProduct(key, 'total_price', value, true) : () => {}}
                                    /> : null}
                                    {subtype !== 'complaint' ? <Input
                                        label={__('Spolu s DPH')}
                                        value={formatAmount(product.total_price_sum, '')}
                                        onChange={() => {}}
                                        onBlur={() => {}}
                                    /> : null}
                                    {!isSeller && subtype !== 'complaint' ? <Input
                                        label={__('Zľava')}
                                        value={product.discount}
                                        onChange={value => this.onChangeProduct(key, 'discount', value)}
                                    /> : null}
                                    {!isSeller && !isSellerManual && subtype !== 'complaint' ? <Input
                                        label={__('Stredisko')}
                                        value={product.centre}
                                        onChange={value => this.onChangeProduct(key, 'centre', value)}
                                    /> : null}
                                    {subtype === 'complaint' ? <Select
                                        label={__('Typ')}
                                        value={product.complaint_type}
                                        options={{
                                            'Nedodané': __('Nedodané'),
                                            'Navyše dodané': __('Navyše dodané'),
                                            'Poškodené': __('Poškodené'),
                                        }}
                                        onChange={value => this.onChangeProduct(key, 'complaint_type', value)}
                                    /> : null}
                                    {subtype === 'complaint' ? <Input
                                        label={__('Poznámka')}
                                        value={product.complaint_comment}
                                        onChange={value => this.onChangeProduct(key, 'complaint_comment', value)}
                                    /> : null}
                                    <Tooltip title={__('Zmazať')}>
                                        <span><IconButton
                                            onClick={() => this.deleteProduct(key)}
                                            className="mtokna_create_order__content__products__product__delete"
                                            disabled={(key === 0 && settings.products.length === 1) || !allowEdit}
                                        >
                                            <DeleteIcon />
                                        </IconButton></span>
                                    </Tooltip>
                                </div>
                            );
                        }) : null}
                        <Button
                            onClick={() => this.addProduct()}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit}
                        >{__('Pridať produkt')}</Button>
                        {!isSeller && !isSellerManual && subtype !== 'customer' && subtype !== 'complaint' && subtype !== 'window-blind' && subtype !== 'window-net' ? <Button
                            onClick={() => this.showOrders()}
                            className="mtokna_create_order__content__products__button"
                            disabled={settings.client.email === '' || !allowEdit}
                        >{__('Pridať províziu')}</Button> : null}
                        {subtype === 'window-blind' ? <Button
                            onClick={() => this.addWindowFee('blinds', 'fee1', 'Zameranie žalúzií')}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit || _.includes(productNames, 'Zameranie žalúzií')}
                        >{__('Zameranie žalúzií')}</Button> : null}
                        {subtype === 'window-blind' ? <Button
                            onClick={() => this.addWindowFee('blinds', 'fee2', 'Montáž žalúzií')}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit || _.includes(productNames, 'Montáž žalúzií')}
                        >{__('Montáž žalúzií')}</Button> : null}
                        {subtype === 'window-blind' ? <Button
                            onClick={() => this.addWindowFee('blinds', 'fee3', 'Výjazd k zákazníkovi')}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit || _.includes(productNames, 'Výjazd k zákazníkovi')}
                        >{__('Výjazd k zákazníkovi')}</Button> : null}
                        {subtype === 'window-blind' ? <Button
                            onClick={() => this.addWindowDiscount('blinds')}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit || _.includes(productNames, 'Zľava (registrovaný zákazník)')}
                        >{__('Zľava (registrovaný zákazník)')}</Button> : null}
                        {subtype === 'window-net' ? <Button
                            onClick={() => this.addWindowFee('nets', 'fee1', 'Zameranie sietí proti hmyzu')}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit || _.includes(productNames, 'Zameranie sietí proti hmyzu')}
                        >{__('Zameranie sietí proti hmyzu')}</Button> : null}
                        {subtype === 'window-net' ? <Button
                            onClick={() => this.addWindowDiscount('nets')}
                            className="mtokna_create_order__content__products__button"
                            disabled={!allowEdit || _.includes(productNames, 'Zľava (registrovaný zákazník)')}
                        >{__('Zľava (registrovaný zákazník)')}</Button> : null}
                    </div>
                    <div className="mtokna_create_order__content__summary">
                        <div className="mtokna_create_order__content__summary__line">
                            <div className="mtokna_create_order__content__summary__line__name">{__('Bez DPH')}</div>
                            <div className="mtokna_create_order__content__summary__line__value">{formatAmount(unitPrice, settings.currency)}</div>
                        </div>
                        <div className="mtokna_create_order__content__summary__line">
                            <div className="mtokna_create_order__content__summary__line__name">{__('Celkom')}</div>
                            <div className="mtokna_create_order__content__summary__line__value">{formatAmount(totalPrice, settings.currency)}</div>
                        </div>
                    </div>
                    <Button
                        onClick={() => this.save()}
                        loading={loading}
                        className="mtokna_create_order__content__button"
                        color="green"
                        disabled={!allowEdit}
                    >{__('Uložiť')}</Button>
                </div>
                {this.renderLightbox(
                    'orders',
                    !_.isEmpty(lightbox.orders) ? `${__('Predajca')} - ${lightbox.orders.email}` : '',
                    !_.isEmpty(lightbox.orders) ? <div>{lightbox.orders.loading ? this.renderLoading(20) :
                        <div>
                            <Table size="small">
                                <TableBody>
                                    {_.map(lightbox.orders.items, (item, key) => {
                                        const selected = _.has(lightbox.orders.selected, item.id.toString());

                                        let callback = () => {};

                                        if (item.type === 'complaint') {
                                            callback = () => this.showDrawer('complaint-mo', { id: item.order_id });
                                        } else if (item.type === 'mo') {
                                            callback = () => this.showDrawer('order-mo', { id: item.order_id });
                                        } else if (item.type === 'window') {
                                            callback = () => this.showDrawer('order-window', { id: item.order_id });
                                        }

                                        return (
                                            <TableRow key={key}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        value={selected}
                                                        onChange={checked => selected
                                                            ? this.removeOrder(item.id)
                                                            : this.addOrder(item.id, item.data)}
                                                    />
                                                </TableCell>
                                                <TableCell>{formatDate(item.data.date)}</TableCell>
                                                <TableCell>{item.type === 'customer' ? __('Zákazník') : (item.type === 'complaint' ? __('Reklamácia') : (item.type === 'window' ? __('Žalúzie, sieťky') : __('MO')))}</TableCell>
                                                <TableCell>{item.eshop}</TableCell>
                                                <TableCell><ButtonBase onClick={callback}><span style={{ fontWeight: 'bold', color: '#5c70ff' }}>{item.data.number}</span></ButtonBase></TableCell>
                                                <TableCell>{formatAmount(item.data.amount, item.data.currency)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    }</div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveOrders(),
                    false,
                    () => this.closeOrders(),
                    'mtokna-detail-lightbox'
                )}
                {this.renderLightbox(
                    'discount',
                    __('Zľava'),
                    !_.isEmpty(lightbox.discount) ? <div>
                        <Input
                            label={__('Zľava')}
                            value={lightbox.discount.value}
                            onChange={value => this.onChangeDiscount(value)}
                        />
                    </div> : null,
                    __('Uložiť'),
                    __('Zrušiť'),
                    () => this.saveDiscount(),
                    false,
                    () => this.closeDiscount()
                )}
                {this.renderDrawer('complaint-mo', {
                    title: __('Reklamácia'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaComplaintData}
                        manual={true}
                        complaint={true}
                        {...props}
                    />,
                })}
                {this.renderDrawer('order-window', {
                    title: __('Objednávka'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaComplaintData}
                        manual={true}
                        window={true}
                        manualSeller={true}
                        {...props}
                    />,
                })}
                {this.renderDrawer('order-mo', {
                    title: __('Objednávka'),
                    content: props => <MtoknaOrder
                        user={user}
                        showSnackbar={(type, message) => this.showSnackbar(type, message)}
                        changeMtoknaOrderData={changeMtoknaOrderData}
                        showPickup={(id, password) => {}}
                        {...props}
                    />,
                })}
                {this.renderSnackbar()}
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, {
    setUser,
    changeMtoknaComplaintData,
    changeMtoknaOrderData,
})(MtoknaCreateOrderScreen));
