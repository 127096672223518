import React from 'react';
import { connect } from 'react-redux';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { Drawer, Tooltip, IconButton, List, ListItem, ListItemIcon, ListItemText, ButtonBase } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import OrdersIcon from '@mui/icons-material/ShoppingCart';
import ClientsIcon from '@mui/icons-material/People';
import InvoicesIcon from '@mui/icons-material/Description';
import CouriersIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import PaymentsIcon from '@mui/icons-material/Payment';
import AddonsIcon from '@mui/icons-material/Extension';
import StorageIcon from '@mui/icons-material/Store';
import BankIcon from '@mui/icons-material/AccountBalanceWallet';
import ExportsIcon from '@mui/icons-material/GetApp';
import TrackTraceIcon from '@mui/icons-material/Place';
import TemplatesIcon from '@mui/icons-material/Email';
import ProductsIcon from '@mui/icons-material/Storefront';
import MarketplacesIcon from '@mui/icons-material/LocalMall';
import ComplaintsIcon from '@mui/icons-material/AssignmentReturn';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Logo, Navigate } from '../components';
import { __, request, getCustomizationKey, toNumber } from '../functions';
import { changeUserSetting, changeMobileMenu } from '../actions';
import '../assets/styles/menu.css';

/**
 * Menu komponenta.
 */
class Menu extends Navigate {

    /**
     * Otvorime menu.
     */
    open() {
        this.changeMenuSetting('full');
    }

    /**
     * Zatvorime menu.
     */
    close() {
        this.changeMenuSetting('mini');
    }

    /**
     * Zmenime menu setting.
     *
     * @param {string} value
     */
    changeMenuSetting(value) {
        if (this.isMobile()) {
            // Na mobile menime ine props
            const { changeMobileMenu } = this.props;

            changeMobileMenu(value);
            return;
        }

        const { changeUserSetting } = this.props;

        // Zmenime nastavenie
        changeUserSetting('menu', value);

        // Ulozime nastavenie
        request('/user-eshops/changeSetting', { menu: value }).then(response => {});
    }

    /**
     * Presmerovanie.
     *
     * @param {string} path
     */
    redirect(path) {
        if (this.isMobile()) {
            // Na mobile schovame menu
            this.close();
        }

        super.redirect(path);
    }

    /**
     * Rendrujeme button.
     *
     * @param {string} uri
     * @param {JSX.Element} icon
     * @param {string} text
     *
     * @returns {JSX.Element}
     */
    renderButton(uri, icon, text) {
        const { user } = this.props;

        let callback = () => this.redirect(uri);

        if (uri.indexOf('https://') !== -1) {
            callback = () => window.open(uri, '_blank');
        }

        let active = this.getPath().indexOf(uri) !== -1;

        if (this.getPath() === '/manual/mo/38/new/customer') {
            if (uri === '/manual') {
                active = false;
            } else if (uri === '/customer-orders') {
                active = true;
            }
        }

        if (this.getPath() === '/cab/orders/cab') {
            if (uri === '/orders' || uri === '/cab/orders/cedule') {
                active = false;
            } else if (uri === '/cab/orders/cab') {
                active = true;
            }
        }

        if (this.getPath() === '/cab/orders/cedule') {
            if (uri === '/orders' || uri === '/cab/orders/cab') {
                active = false;
            } else if (uri === '/cab/orders/cedule') {
                active = true;
            }
        }

        return (
            <ButtonBase onClick={callback}>
                <ListItem className={`menu__button ${active ? 'active' : ''}`} button>
                    <Tooltip title={user.settings.menu !== 'full' ? text : ''}>
                        <ListItemIcon>{icon}</ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={text} />
                </ListItem>
            </ButtonBase>
        );
    }

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user } = this.props;

        if (_.isEmpty(user)) {
            // Nie sme prihlaseny
            return null;
        }

        let opened = user.settings.menu !== 'mini';

        if (this.isMobile()) {
            // Na mobile pozerame na ine props
            opened = this.props.menu !== 'mini';
        }

        // Zobrazujeme?
        let showOrders = this.hasPermission('orders');
        const showClients = this.hasPermission('clients');
        const showProducts = this.hasPermission('products');
        const showEshop = this.hasPermission('settings-eshop');
        const showInvoices = this.hasPermission('invoices');
        const showPayments = this.hasPermission('payments');
        const showAccounting = this.hasPermission('settings-accounting');
        const showCouriers = this.hasPermission('labels') || this.hasPermission('settings-couriers');
        const showStorage = this.hasPermission('settings-storage');
        const showMarketplaces = this.hasPermission('marketplaces');
        const showBank = this.hasPermission('bmails') || this.hasPermission('settings-bank');
        const showExports = this.hasPermission('exports');
        const showTemplates = this.hasPermission('templates');
        const showAddons = this.hasPermission('addons');
        const showComplaints = this.hasPermission('complaints');
        const showTrackTrace = this.hasPermission('track_trace');

        let showOrderSellers = false;

        if (user.is_seller) {
            showOrders = false;
        }

        if (user.is_seller || user.is_seller_owner) {
            showOrderSellers = true;
        }

        const showAderyn = _.includes([142,143,430], toNumber(user.user_eshop_id));
        const showCab = _.includes([431,437,442,445,453,123,124,130,131,280,281,381,438,439,443,444,446,447,454,455], toNumber(user.user_eshop_id));

        let buttons = (
            <List>
                {this.renderButton('/dashboard', <HomeIcon />, __('Prehľady'))}
                {opened && (showOrders || showClients || showProducts || showEshop || showOrderSellers)
                    ? <div className="menu__divider">{__('ESHOP')}</div>
                    : null}
                {showOrders ? this.renderButton('/orders', <OrdersIcon />, __('Objednávky')) : null}
                {showCab ? this.renderButton('/cab/orders/cab', <OrdersIcon />, __('Sumár - cab')) : null}
                {showCab ? this.renderButton('/cab/orders/cedule', <OrdersIcon />, __('Sumár - cedule')) : null}
                {showClients ? this.renderButton('/clients', <ClientsIcon />, __('Zákaznici')) : null}
                {showProducts ? this.renderButton('/products', <ProductsIcon />, __('Produkty')) : null}
                {showAderyn ? this.renderButton('/aderyn/storage', <StorageIcon />, __('Sklad')) : null}
                {showCab ? this.renderButton('/cab/products', <StorageIcon />, __('Sklad')) : null}
                {showEshop ? this.renderButton('/eshop', <SettingsIcon />, __('Nastavenie')) : null}
                {opened && (showInvoices || showPayments || showAccounting)
                    ? <div className="menu__divider">{__('EKONOMICKÝ SYSTÉM')}</div>
                    : null}
                {showInvoices ? this.renderButton('/invoices', <InvoicesIcon />, __('Faktúry')) : null}
                {showPayments ? this.renderButton('/payments', <PaymentsIcon />, __('Platby')) : null}
                {showAccounting ? this.renderButton('/accounting', <SettingsIcon />, __('Nastavenie')) : null}
                {opened && (showCouriers || showStorage || showBank || showExports || showTemplates || showAddons)
                    ? <div className="menu__divider">{__('SLUŽBY')}</div>
                    : null}
                {showCouriers ? this.renderButton('/couriers', <CouriersIcon />, __('Prepravcovia')) : null}
                {showStorage ? this.renderButton('/storage', <StorageIcon />, __('Sklad')) : null}
                {showBank ? this.renderButton('/bank', <BankIcon />, __('Banka')) : null}
                {showMarketplaces ? this.renderButton('/marketplaces', <MarketplacesIcon />, __('Trhoviská')) : null}
                {showTrackTrace ? this.renderButton('/track-trace', <TrackTraceIcon />, __('Track & Trace')) : null}
                {showComplaints ? this.renderButton('/complaints', <ComplaintsIcon />, __('Reklamácie')) : null}
                {showExports ? this.renderButton('/exports', <ExportsIcon />, __('Exporty')) : null}
                {showTemplates ? this.renderButton('/templates', <TemplatesIcon />, __('Emaily')) : null}
                {showAddons ? this.renderButton('/addons', <AddonsIcon />, __('Doplnky')) : null}
            </List>
        );

        if (this.isMtokna()) {
            buttons = (
                <List>
                    {!user.mtokna_moderator ? <div className="menu__divider">{__('ESHOP')}</div> : null}
                    {!user.mtokna_moderator && user.mtokna_type !== 'mtokna_admin' ? this.renderButton('/dashboard', <OrdersIcon />, __('Objednávky')) : null}
                    {user.mtokna_type === 'mtokna_owner' ? this.renderButton('/products', <NoteAddIcon />, __('Produkty')) : null}
                    {user.mtokna_type === 'mtokna_seller' ? this.renderButton('/manual-seller', <NoteAddIcon />, __('Dodávateľ')) : null}
                    {user.mtokna_type === 'mtokna_seller' ? this.renderButton(user.email === 'zeleziarstvo@mt-okna.sk' ? '/customer-orders' : '/seller-orders', <NoteAddIcon />, __('Zákazník')) : null}
                    {user.mtokna_type === 'mtokna_seller' ? this.renderButton('/complaints', <NoteAddIcon />, __('Reklamácie')) : null}
                    {user.mtokna_type !== 'mtokna_seller' && !user.mtokna_moderator ? this.renderButton('/eshop', <SettingsIcon />, __('Eshopy')) : null}
                    {user.mtokna_type === 'mtokna_seller' ? this.renderButton('/seller-accounting', <SettingsIcon />, __('Fakturácia')) : null}
                    {user.mtokna_type !== 'mtokna_owner' && !user.mtokna_moderator ? this.renderButton('/files', <ExportsIcon />, __('Praktické pomôcky')) : null}
                    {user.mtokna_type !== 'mtokna_owner' && !user.mtokna_moderator ? this.renderButton('/documents', <PdfIcon />, __('Akciové ponuky')) : null}
                    {user.mtokna_type !== 'mtokna_owner' && !user.mtokna_moderator ? this.renderButton('/videos', <VideoLibraryIcon />, __('Videonávody')) : null}
                    {user.mtokna_type === 'mtokna_seller2' ? this.renderButton('https://api.goodeshop.sk/mtokna/products.xml', <ExportsIcon />, __('XML feed')) : null}
                    {user.mtokna_type !== 'mtokna_seller' && !_.isEmpty(user.user_eshops) ? this.renderButton('/owner-smtp', <TemplatesIcon />, __('SMTP')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? <div className="menu__divider">{__('ADMIN')}</div> : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/orders', <OrdersIcon />, __('Objednávky')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/manual', <NoteAddIcon />, __('Vyúčtovanie')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/all-seller-orders', <NoteAddIcon />, __('MO objednávky')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/customer-orders', <NoteAddIcon />, __('Zákazník')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/complaints', <NoteAddIcon />, __('Reklamácie')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/owners', <ClientsIcon />, __('Predajcovia VO')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/categories', <NoteAddIcon />, __('Kategórie')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/products', <NoteAddIcon />, __('Produkty')) : null}
                    {user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator ? this.renderButton('/moderators', <ClientsIcon />, __('Zástupcovia')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/points', <ProductsIcon />, __('Odberné miesta')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/sellers', <ClientsIcon />, __('Predajcovia MO')) : null}
                    {user.mtokna_type !== 'mtokna_seller' ? this.renderButton('/templates', <TemplatesIcon />, user.mtokna_type === 'mtokna_admin' ? __('Emaily VO') : __('Emaily')) : null}
                    {user.mtokna_type !== 'mtokna_owner' ? this.renderButton('/templates/mo', <TemplatesIcon />, user.mtokna_type === 'mtokna_admin' ? __('Emaily MO') : __('Emaily')) : null}
                    {user.mtokna_type === 'mtokna_admin' && !user.mtokna_moderator ? this.renderButton('/banners', <InsertPhotoIcon />, __('Bannery')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/schemes', <EngineeringIcon />, __('XML schémy')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/excel-schemes', <EngineeringIcon />, __('Excel schémy')) : null}
                    {user.mtokna_type === 'mtokna_admin' || user.mtokna_type === 'mtokna_seller' ? <div className="menu__divider">{__('ŽALÚZIE, SIEŤKY')}</div> : null}
                    {user.mtokna_type === 'mtokna_admin' || user.mtokna_type === 'mtokna_seller' ? this.renderButton('/windows', <OrdersIcon />, __('Objednávky')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/window-settings', <NoteAddIcon />, __('Cenník')) : null}
                    {user.mtokna_type === 'mtokna_seller' ? this.renderButton('/window-seller', <SettingsIcon />, __('Moje údaje')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? <div className="menu__divider">{__('STRÁŽNY PES')}</div> : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/dogs', <EngineeringIcon />, __('XML zdroje')) : null}
                    {user.mtokna_type === 'mtokna_admin' ? this.renderButton('/dog-products', <NoteAddIcon />, __('Produkty')) : null}
                </List>
            );
        } else if (this.isRainpro()) {
            buttons = (
                <List>
                    <div className="menu__divider">{__('ESHOP')}</div>
                    {user.rainpro_type === 'rainpro_picker' ? this.renderButton('/orders', <OrdersIcon />, __('Objednávky')) : null}
                    {user.rainpro_type === 'rainpro_admin' ? this.renderButton('/pickers', <ClientsIcon />, __('Skladníci')) : null}
                </List>
            );
        }

        return (
            <Drawer
                variant={this.isMobile() ? 'temporary' : 'permanent'}
                PaperProps={{ elevation: 0 }}
                className={`menu ${!opened ? 'closed' : ''} ${this.isWebareal() ? 'webareal-menu' : ''} ${getCustomizationKey()}`}
                open={opened}
            >
                <div className="menu__header">
                    <Logo onClick={() => window.location.reload()} />
                    {!this.isDemo() && toNumber(user.user_eshop_id) > 0 ? <Tooltip title={opened ? __('Schovať menu') : __('Zobraziť menu')}>
                        <IconButton
                            className="menu__header__button"
                            color="inherit"
                            onClick={opened ? () => this.close() : () => this.open()}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip> : null}
                </div>
                {buttons}
            </Drawer>
        );
    }
}

const stateToProps = ({ user, menu }) => ({ user, menu });

export default withCookies(connect(stateToProps, { changeUserSetting, changeMobileMenu })(Menu));
