import React from 'react';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { withCookies } from 'react-cookie';
import _ from 'lodash';
import { setUser } from '../actions';
import Header from '../components/Header';
import Webareal from '../components/Webareal';
import MtoknaBanners from '../components/MtoknaBanners';
import { Navigate } from './Navigate';
import { getCustomizationKey } from '../functions';
import DashboardScreen from '../screens/DashboardScreen';
import OrdersScreen from '../screens/OrdersScreen';
import ClientsScreen from '../screens/ClientsScreen';
import ProductsScreen from '../screens/ProductsScreen';
import InvoicesScreen from '../screens/InvoicesScreen';
import InvoicePaymentsScreen from '../screens/InvoicePaymentsScreen';
import LabelsScreen from '../screens/LabelsScreen';
import ExportsScreen from '../screens/ExportsScreen';
import TemplatesScreen from '../screens/TemplatesScreen';
import TemplateScreen from '../screens/TemplateScreen';
import ExportScreen from '../screens/ExportScreen';
import BmailsScreen from '../screens/BmailsScreen';
import AddonsScreen from '../screens/AddonsScreen';
import EshopScreen from '../screens/EshopScreen';
import AccountingScreen from '../screens/AccountingScreen';
import StorageScreen from '../screens/StorageScreen';
import LoginScreen from '../screens/LoginScreen';
import RegisterScreen from '../screens/RegisterScreen';
import EmployeesScreen from '../screens/EmployeesScreen';
import EmployeeScreen from '../screens/EmployeeScreen';
import NotificationsScreen from '../screens/NotificationsScreen';
import SettingsScreen from '../screens/SettingsScreen';
import MarketplacesScreen from '../screens/MarketplacesScreen';
import AdminScreen from '../screens/AdminScreen';
import PublicScreen from '../screens/PublicScreen';
import MarketplaceProductsScreen from '../screens/MarketplaceProductsScreen';
import OrderScreen from '../screens/OrderScreen';
import TrackTraceScreen from '../screens/TrackTraceScreen';
import ComplaintsScreen from '../screens/ComplaintsScreen';
import ComplaintScreen from '../screens/ComplaintScreen';
import OrderPublic from '../screens/OrderPublic';
import AffiliateLoginScreen from '../screens/AffiliateLoginScreen';
import AffiliateRegisterScreen from '../screens/AffiliateRegisterScreen';
import AffiliatePortalScreen from '../screens/AffiliatePortalScreen';
import OrderMtOknaScreen from '../screens/OrderMtOknaScreen';
import MtoknaOrdersScreen from "../screens/MtoknaOrdersScreen";
import MtoknaSellersScreen from "../screens/MtoknaSellersScreen";
import MtoknaOwnersScreen from "../screens/MtoknaOwnersScreen";
import MtoknaPointsScreen from "../screens/MtoknaPointsScreen";
import MtoknaCreateOrderScreen from "../screens/MtoknaCreateOrderScreen";
import MtoknaManualOrdersScreen from "../screens/MtoknaManualOrdersScreen";
import MtoknaTemplatesScreen from "../screens/MtoknaTemplatesScreen";
import MtoknaTemplateScreen from "../screens/MtoknaTemplateScreen";
import MtoknaOwnerSmtpScreen from "../screens/MtoknaOwnerSmtpScreen";
import MtoknaEshopScreen from "../screens/MtoknaEshopScreen";
import MtoknaFilesScreen from "../screens/MtoknaFilesScreen";
import MtoknaBannersScreen from "../screens/MtoknaBannersScreen";
import AderynProductsScreen from "../screens/AderynProductsScreen";
import AderynProductLogsScreen from "../screens/AderynProductLogsScreen";
import CabProductsScreen from "../screens/CabProductsScreen";
import MtoknaManualSellerOrdersScreen from "../screens/MtoknaManualSellerOrdersScreen";
import MtoknaModeratorsScreen from "../screens/MtoknaModeratorsScreen";
import MtoknaManualCustomerOrdersScreen from "../screens/MtoknaManualCustomerOrdersScreen";
import MtoknaComplaintsScreen from "../screens/MtoknaComplaintsScreen";
import MtoknaCategoriesScreen from "../screens/MtoknaCategoriesScreen";
import MtoknaProductsScreen from "../screens/MtoknaProductsScreen";
import MtoknaCreateProductScreen from "../screens/MtoknaCreateProductScreen";
import MtoknaSchemesScreen from "../screens/MtoknaSchemesScreen";
import MtoknaCreateSchemeScreen from "../screens/MtoknaCreateSchemeScreen";
import MtoknaDogsScreen from "../screens/MtoknaDogsScreen";
import MtoknaCreateDogScreen from "../screens/MtoknaCreateDogScreen";
import MtoknaDogProductsScreen from "../screens/MtoknaDogProductsScreen";
import MtoknaWindowSettingsScreen from "../screens/MtoknaWindowSettingsScreen";
import MtoknaWindowSellerScreen from "../screens/MtoknaWindowSellerScreen";
import MtoknaWindowsScreen from "../screens/MtoknaWindowsScreen";
import MtoknaDocumentsScreen from "../screens/MtoknaDocumentsScreen";
import MtoknaVideosScreen from "../screens/MtoknaVideosScreen";
import RainproOrdersScreen from "../screens/RainproOrdersScreen";
import RainproPickersScreen from "../screens/RainproPickersScreen";
import MtoknaExcelSchemesScreen from "../screens/MtoknaExcelSchemesScreen";
import MtoknaCreateExcelSchemeScreen from "../screens/MtoknaCreateExcelSchemeScreen";
import CabOrdersScreen from "../screens/CabOrdersScreen";
import '../assets/styles/content.css';

const PublicComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source');

    return <PublicScreen
        location={location}
        navigate={navigate}
        params={params}
        source={source}
        key={`${location.pathname}${source}`}
    />
};

const AffiliateRegisterComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const name = searchParams.get('name');

    return <AffiliateRegisterScreen
        location={location}
        navigate={navigate}
        params={params}
        name={name}
        key={`${location.pathname}${name}`}
    />
};

const OrderPublicComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <OrderPublic
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const OrdersSellersComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <OrdersScreen
        location={location}
        navigate={navigate}
        params={params}
        key="sellers"
    />
};

const OrderMtOknaComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <OrderMtOknaScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const LoginComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <LoginScreen
        location={location}
        navigate={navigate}
        params={params}
        key={`${location.pathname}`}
    />
};

const MtoknaOrdersComponent = ({ type }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaOrdersScreen
        location={location}
        navigate={navigate}
        params={{ ...params, type }}
        key={`${location.pathname}`}
    />
};

const MtoknaCreateOrderComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaCreateOrderScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const AderynProductLogsComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <AderynProductLogsScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const MtoknaCreateProductComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaCreateProductScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const MtoknaCreateSchemeComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaCreateSchemeScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const MtoknaCreateExcelSchemeComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaCreateExcelSchemeScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const MtoknaCreateDogComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaCreateDogScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const MtoknaTemplatesComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaTemplatesScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const MtoknaTemplateComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <MtoknaTemplateScreen
        location={location}
        navigate={navigate}
        params={params}
        key={location.pathname}
    />
};

const RainproOrdersComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <RainproOrdersScreen
        location={location}
        navigate={navigate}
        params={params}
        key={`${location.pathname}`}
    />
};

const CabOrdersComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <CabOrdersScreen
        location={location}
        navigate={navigate}
        params={params}
        key={`${location.pathname}`}
    />
};

/**
 * Content komponenta.
 */
class Content extends Navigate {

    /**
     * Rendrovanie.
     *
     * @return {JSX.Element}
     */
    render() {
        const { user, location, navigate, params } = this.props;

        let openedMenu = true;

        if (!_.isEmpty(user)) {
            // Mame vyplneneho usera, pozrieme v akom stave je menu
            openedMenu = user.settings.menu !== 'mini';
        }

        // Zobrazujeme content ako mobilny
        const showMobileContent = this.isMobile() || _.includes(['/login', '/register'], this.getPath());

        // Zobrazujeme Webreal
        const showWebareal = this.isWebareal();

        if (this.isMtokna()) {
            const showBanners = !_.isEmpty(user) && user.mtokna_type === 'mtokna_seller';

            return (
                <div className={`content ${!openedMenu ? 'closed' : ''} ${showMobileContent ? 'mobile' : ''} ${getCustomizationKey()}`}>
                    <Header location={location} navigate={navigate} params={params} />
                    <div className="content__screen">
                        {showBanners ? <MtoknaBanners location={location} navigate={navigate} params={params} /> : null}
                        <Routes>
                            <Route path="/window-seller" element={<MtoknaWindowSellerScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/window-settings" element={<MtoknaWindowSettingsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/windows" element={<MtoknaWindowsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/dogs/view/:id" element={<MtoknaCreateDogComponent />} />
                            <Route path="/dogs" element={<MtoknaDogsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/dog-products" element={<MtoknaDogProductsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/excel-schemes/view/:id" element={<MtoknaCreateExcelSchemeComponent />} />
                            <Route path="/excel-schemes" element={<MtoknaExcelSchemesScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/schemes/view/:id" element={<MtoknaCreateSchemeComponent />} />
                            <Route path="/schemes" element={<MtoknaSchemesScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/products/view/:id" element={<MtoknaCreateProductComponent />} />
                            <Route path="/products/copy/:id" element={<MtoknaCreateProductComponent />} />
                            <Route path="/products" element={<MtoknaProductsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/categories" element={<MtoknaCategoriesScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/complaints" element={<MtoknaComplaintsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/customer-orders" element={<MtoknaManualCustomerOrdersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/all-seller-orders" element={<MtoknaManualSellerOrdersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/seller-accounting" element={<AccountingScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/seller-orders" element={<MtoknaManualSellerOrdersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/banners" element={<MtoknaBannersScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/files" element={<MtoknaFilesScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/documents" element={<MtoknaDocumentsScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/videos" element={<MtoknaVideosScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/eshop" element={<MtoknaEshopScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/owner-smtp" element={<MtoknaOwnerSmtpScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/template/:id/:type" element={<MtoknaTemplateComponent />} />
                            <Route path="/templates/:type" element={<MtoknaTemplatesComponent />} />
                            <Route path="/templates" element={<MtoknaTemplatesScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/manual/:type/:user_eshop_id/:id/:subtype" element={<MtoknaCreateOrderComponent />} />
                            <Route path="/manual/:type/:user_eshop_id/:id" element={<MtoknaCreateOrderComponent />} />
                            <Route path="/manual" element={<MtoknaManualOrdersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/manual-seller" element={<MtoknaManualOrdersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/orders" element={<MtoknaOrdersComponent type="all" />} />
                            <Route path="/points" element={<MtoknaPointsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/moderators" element={<MtoknaModeratorsScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/owners" element={<MtoknaOwnersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/sellers" element={<MtoknaSellersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/dashboard/:search" element={<MtoknaOrdersComponent type="" />} />
                            <Route path="/dashboard" element={<MtoknaOrdersComponent type="" />} />} />
                            <Route path="/register" element={<RegisterScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/login" element={<LoginScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/" element={<LoginComponent />} />
                            <Route path="*" element={<LoginComponent />} />
                        </Routes>
                    </div>
                </div>
            );
        } else if (this.isRainpro()) {
            return (
                <div className={`content ${!openedMenu ? 'closed' : ''} ${showMobileContent ? 'mobile' : ''} ${getCustomizationKey()}`}>
                    <Header location={location} navigate={navigate} params={params} />
                    <div className="content__screen">
                        <Routes>
                            <Route path="/dashboard" element={<RainproOrdersComponent />} />} />
                            <Route path="/orders" element={<RainproOrdersComponent />} />} />
                            <Route path="/pickers" element={<RainproPickersScreen location={location} navigate={navigate} params={params} />} />} />
                            <Route path="/login" element={<LoginScreen location={location} navigate={navigate} params={params} />} />
                            <Route path="/" element={<LoginComponent />} />
                            <Route path="*" element={<LoginComponent />} />
                        </Routes>
                    </div>
                </div>
            );
        }

        return (
            <div className={`content ${!openedMenu ? 'closed' : ''} ${showMobileContent ? 'mobile' : ''} ${showWebareal ? 'webreal-panel' : ''} ${getCustomizationKey()}`}>
                {showWebareal ? <Webareal navigate={navigate} /> : null}
                <Header location={location} navigate={navigate} params={params} />
                <div className="content__screen">
                    <Routes>
                        <Route path="/admin" element={<AdminScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/orders/mtokna/:id/:edit_id" element={<OrderMtOknaComponent />} />
                        <Route path="/orders/mtokna/:id" element={<OrderMtOknaComponent />} />
                        <Route path="/orders/public/:id/:token" element={<OrderPublicComponent />} />
                        <Route path="/complaint/:id" element={<ComplaintScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/complaint" element={<ComplaintScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/complaints" element={<ComplaintsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/track-trace" element={<TrackTraceScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/marketplaces/products/:id" element={<MarketplaceProductsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/marketplaces" element={<MarketplacesScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/settings" element={<SettingsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/notifications/high" element={<NotificationsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/notifications" element={<NotificationsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/employee/:id" element={<EmployeeScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/employee" element={<EmployeeScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/employees" element={<EmployeesScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/register" element={<RegisterScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/affiliate-portal" element={<AffiliatePortalScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/affiliate-r" element={<AffiliateRegisterComponent />} />
                        <Route path="/affiliate-login" element={<AffiliateLoginScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/login" element={<LoginScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/storage" element={<StorageScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/accounting" element={<AccountingScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/eshop" element={<EshopScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/addons" element={<AddonsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/bank" element={<BmailsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/export/:id" element={<ExportScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/export" element={<ExportScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/template/:id" element={<TemplateScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/template" element={<TemplateScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/templates" element={<TemplatesScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/exports" element={<ExportsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/couriers" element={<LabelsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/payments" element={<InvoicePaymentsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/invoices" element={<InvoicesScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/products" element={<ProductsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/clients" element={<ClientsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/orders/:search" element={<OrdersScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/orders" element={<OrdersScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/sellers-orders" element={<OrdersSellersComponent />} />
                        <Route path="/order" element={<OrderScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/dashboard" element={<DashboardScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/cab/products" element={<CabProductsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/cab/orders/:type" element={<CabOrdersComponent />} />
                        <Route path="/aderyn/storage" element={<AderynProductsScreen location={location} navigate={navigate} params={params} />} />
                        <Route path="/aderyn/storage/:id" element={<AderynProductLogsComponent />} />
                        <Route path="/" element={<PublicComponent />} />
                        <Route path="*" element={<PublicComponent />} />
                    </Routes>
                </div>
            </div>
        );
    }
}

const stateToProps = ({ user }) => ({ user });

export default withCookies(connect(stateToProps, { setUser })(Content));
